import { RETRIEVE_LANDING_FAILED, RETRIEVE_LANDING_START, RETRIEVE_LANDING_SUCCESS, UPDATE_FORM_FOOTER_FAILED, UPDATE_FORM_FOOTER_START, UPDATE_FORM_FOOTER_SUCCESS, UPDATE_LANDING_SECTION_FOUR_FAILED, UPDATE_LANDING_SECTION_FOUR_START, UPDATE_LANDING_SECTION_FOUR_SUCCESS, UPDATE_LANDING_SECTION_TWO_FAILED, UPDATE_LANDING_SECTION_TWO_START, UPDATE_LANDING_SECTION_TWO_SUCCESS } from "store/actions/actions.types";

const initialState = {
  landing: null,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isFourUpdateLoading: false,
  isFormFooterLoading: false,
}


export default function landing(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case RETRIEVE_LANDING_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case UPDATE_LANDING_SECTION_TWO_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case UPDATE_LANDING_SECTION_FOUR_START:
      return {
        ...state,
        isFourUpdateLoading: true,
      };
    case UPDATE_FORM_FOOTER_START:
      return {
        ...state,
        isFormFooterLoading: true,
      };
    case RETRIEVE_LANDING_FAILED:
    case UPDATE_LANDING_SECTION_TWO_FAILED:
    case UPDATE_LANDING_SECTION_FOUR_FAILED:
    case UPDATE_FORM_FOOTER_FAILED:
      return {
        ...state,
        isRetrieveLoading: false,
        isUpdateLoading: false,
        isFourUpdateLoading: false,
        isFormFooterLoading: false,
      }
    case UPDATE_LANDING_SECTION_TWO_SUCCESS:
      return {
        ...state,
        landing: payload,
        isUpdateLoading: false
      };
    case RETRIEVE_LANDING_SUCCESS: {
      return {
        ...state,
        landing: payload,
        isRetrieveLoading: false
      };
    }
    case UPDATE_LANDING_SECTION_FOUR_SUCCESS:
      return {
        ...state,
        landing: payload,
        isFourUpdateLoading: false
      };
    case UPDATE_FORM_FOOTER_SUCCESS:
      return {
        ...state,
        landing: payload,
        isFormFooterLoading: false
      };
    default:
      return state;
  }
}