import React from "react";

// icon
import { Icon } from "@iconify/react";
import bxSearch from '@iconify/icons-bx/bx-search';

// context
// import { ThemeContext } from "context/ThemeContext";

const SearchInput = ({ handleSearchChange, icon, ...props }) => {
  // const { theme } = React.useContext(ThemeContext);
  return (
    <div className="my-4">
      <div className="relative flex">
        <div className="absolute top-0 left-0 w-10 -z-1">
          <div className="flex items-center justify-center h-full w-full rounded-bl rounded-tl pt-4">
            <Icon icon={bxSearch} />
          </div>
        </div>
        <input
          {...props}
          onChange={handleSearchChange}
          className={`transition duration-500 ease-in-out text-sm appearance-none shadow-sm rounded-full w-full py-2 px-3 text-black leading-tight focus:outline-none focus:border-default-primary focus:border-default-primary hover:border border-2 border-gray-200 relative pb-3 pt-4 pr-4 pl-10`}
        />
      </div>
      {/* <p className="text-red-500 text-xs italic pt-2 pl-10">
        Please choose a password.
      </p> */}
    </div>
  );
};

export default SearchInput;
