import React from "react";

import { Editor } from "react-draft-wysiwyg";

// options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']

const TextEditor = ({editorState, setEditorState, options}) => {
  return (
    <>
      <Editor
        defaultEditorState={editorState}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="border p-4 rounded-md my-2"
        toolbarClassName="toolbar-class"
        placeholder="Type here..."
        toolbar={{
          // inline: { inDropdown: true },
          options: options,
          blockType: { visible: false },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
        }}
      />
    </>
  );
};


export default TextEditor;
