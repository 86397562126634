import {
  ADD_REVIEW_FAILED,
  ADD_REVIEW_START,
  ADD_REVIEW_SUCCESS,
  CLEAR_REVIEWS,
  DELETE_REVIEW_FAILED,
  DELETE_REVIEW_START,
  DELETE_REVIEW_SUCCESS,
  GET_REVIEWS_FAILED,
  GET_REVIEWS_START,
  GET_REVIEWS_SUCCESS,
  PUBLISH_REVIEW_FAILED,
  PUBLISH_REVIEW_START,
  PUBLISH_REVIEW_SUCCESS,
  RETRIEVE_REVIEW_FAILED,
  RETRIEVE_REVIEW_START,
  RETRIEVE_REVIEW_SUCCESS,
} from "store/actions/actions.types";

const initialState = {
  reviews: [],
  review: null,
  isGetLoading: false,
  isFormLoading: false,
  isRetrieveLoading: false,
  isDeleteLoading: false,
  hasMore: false,
  isPublishLoading: false,
};

export default function review(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_REVIEW_START:
      return {
        ...state,
        isFormLoading: true,
      };
    case GET_REVIEWS_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case RETRIEVE_REVIEW_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case PUBLISH_REVIEW_START:
      return {
        ...state,
        isPublishLoading: true,
      };
    case DELETE_REVIEW_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case ADD_REVIEW_FAILED:
    case GET_REVIEWS_FAILED:
    case RETRIEVE_REVIEW_FAILED:
    case PUBLISH_REVIEW_FAILED:
    case DELETE_REVIEW_FAILED:
      return {
        ...state,
        isFormLoading: false,
        isGetLoading: false,
        isDeleteLoading: false,
        isPublishLoading: false,
      };
    case ADD_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [payload, ...state.reviews],
        isFormLoading: false,
      };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: [...state.reviews, ...payload],
        isGetLoading: false,
        hasMore: action.hasMore,
      };
    case RETRIEVE_REVIEW_SUCCESS: {
      return {
        ...state,
        review: payload,
        isRetrieveLoading: false,
      };
    }
    case PUBLISH_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: state.reviews.map((review) =>
          review.id === payload.id ? { ...payload } : review
        ),
        isPublishLoading: false,
      };
    case DELETE_REVIEW_SUCCESS: 
      return {
        ...state,
        reviews: state.reviews.filter((review) => review.id !== payload),
        isDeleteLoading: false,
      };
    case CLEAR_REVIEWS:
      return {
        ...state,
        reviews: [],
      };
    default:
      return state;
  }
}
