import { createSelector } from "reselect";

const selectWishlist = (state) => state.wishlist;

export const selectWishlistInWishlist = createSelector(
  [selectWishlist],
  (wishlist) => wishlist && wishlist.wishlist
);
export const selectWishlistItems = createSelector(
  [selectWishlist],
  (wishlist) => wishlist && wishlist.wishlistItems
);

// export const selectWishlistIsAddLoading = createSelector(
//   [selectWishlist],
//   (cart) => cart && cart.isAddLoading
// );

export const selectWishlistIsGetLoading = createSelector(
  [selectWishlist],
  (wishlist) => wishlist && wishlist.isGetLoading
);

// export const selectWishlistItems = createSelector(
//   [selectWishlistInCart],
//   (cart) => cart && cart.cart_items
// );

// export const selectWishlistItemsCount = createSelector(
//   [selectWishlistInCart],
//   (cart) =>
//     cart &&
//     cart.cart_items.reduce(
//       (previousScore, currentScore) => previousScore + currentScore.quantity,
//       0
//     )
// );
