import axios from 'axios';

const PORT = 8000;

const url = process.env.REACT_APP_PRODUCTION === "false"
  ? window.location.protocol + "//" + window.location.hostname + ":" + PORT + "/"
  : "https://bubbles-and-cream-backend.herokuapp.com/"
  
export default axios.create({
  baseURL: url,
});