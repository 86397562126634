import React from "react";

const SmallHeading = ({title}) => {
  return (
    <>
      <div className="text-base uppercase font-medium">{title}</div>
      <div className="h-1 bg-gray-400 w-8 mt-2 mb-4"></div>
    </>
  );
};

export default SmallHeading;
