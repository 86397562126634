import React, { useState } from "react";

// data
import { DASHBOARD_ROUTES } from "data/admin-dashboard-routes";

// routes
import { NavLink } from "react-router-dom";

// icon
import Icon from "@iconify/react";
import bxsHome from '@iconify/icons-bx/bxs-home';

// context
// import { ThemeContext } from "context/ThemeContext";

const AdminSidebar = (props) => {
  // const { theme } = React.useContext(ThemeContext);
  const [sidebarLinks] = useState(DASHBOARD_ROUTES);

  return (
    <div className="flex md:flex-col justify-evenly md:justify-start py-3 xl:py-6 fixed bottom-0 md:static w-full md:w-4/12 lg:w-3/12 xl:w-2/12 md:h-full bg-gray-100 shadow-upper ">
      <NavLink
        to='/'
        className="hidden md:flex items-center space-x-3 sm:rounded-sm
        hover:text-default-primaryShade transition duration-300 ease-in-out sm:m-2 sm:px-2 text-sm xl:mx-6 xl:px-4 xl:py-2`}
        activeClassName={`text-default-primary"
      >
        <Icon icon={bxsHome} className="w-5 sm:w-4 h-5 sm:h-4" />
        <div className="hidden md:block">Back to Landing Page</div>
      </NavLink>
      {sidebarLinks.map((links) => {
        const { id, routeName, name, icon } = links;
        return (
          <NavLink
            to={routeName}
            key={id}
            className={`flex space-x-3 sm:rounded-sm
            transform hover:-translate-y-1 focus:-translate-y-1
            hover:text-white hover:bg-default-primary transition duration-300 ease-in-out sm:m-2 rounded-sm px-4 py-2 sm:px-2 text-sm items-center xl:mx-6 xl:px-4 xl:py-2`}
            activeClassName={`bg-default-primary text-white`}
          >
            <Icon icon={icon} className="w-5 sm:w-4 h-5 sm:h-4" />
            <div className="hidden md:block">{name}</div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default AdminSidebar;
