// id generator
import { nanoid } from "nanoid";

// icons
import bxsHome from "@iconify/icons-bx/bxs-home";
import bxsShoppingBags from "@iconify/icons-bx/bxs-shopping-bags";
import bxsInfoCircle from "@iconify/icons-bx/bxs-info-circle";
import bxsUser from "@iconify/icons-bx/bxs-user";

export const SIDEBAR_ROUTES = [
  {
    id: nanoid(),
    name: "Home",
    routeName: "/",
    icon: bxsHome,
  },
  {
    id: nanoid(),
    name: "Account Info",
    routeName: "/dashboard",
    icon: bxsUser,
  },
  {
    id: nanoid(),
    name: "Shop",
    routeName: "/shop",
    icon: bxsShoppingBags,
  },
  {
    id: nanoid(),
    name: "About Us",
    routeName: "/about",
    icon: bxsInfoCircle,
  },
  {
    id: nanoid(),
    name: "Contact Us",
    routeName: "/contact",
    icon: bxsInfoCircle,
  },

];
