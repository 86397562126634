import React from "react";

const OrderItem = ({ imageSrc, name, price, quantity, total_price }) => {
  return (
    <div className="flex  md:justify-between justify-start items-start flex-col">
      <div className="flex items-center space-x-3">
        <div className="">
          <img
            src={imageSrc}
            alt="prod"
            className="object-cover h-12 w-12"
          />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold text-sm">
            {name}
          </div>
          <div className="text-sm font-semibold">£{price} <span className="text-gray-600">x {quantity}</span></div>
          {/* <div className="flex text-gray-700 text-sm items-center space-x-4 font-light">
                <div className="">Size: L</div>
                <div className="">Qty: 4</div>
              </div> */}
        </div>
      </div>
      {/* <div className="text-gray-600 ml-12 pl-3">
      {total_price ? `£${total_price}` : null}
      </div> */}
    </div>
  );
};


export default OrderItem;
