
export const changeIsRated = (orders, orderID, orderItems, isRatedItem) => {

  const existingOrderItem = orderItems.find(orderItem => orderItem.id === isRatedItem.id)
  
  if(existingOrderItem) {

    const order_items = orderItems.map(item => 
    item.id === isRatedItem.id
    ? {...item, is_rated: isRatedItem.is_rated}
    : item
    )
    
    return orders.map(order => 
      order.id === orderID
      ? {...order, order_items}
      : order
      )
    }
}


export const changeToDeliveredStatus = (orders, orderID, orderItems, isRatedItem) => {

  let checker = order => order.every(v => v === true);
  let orderItemRatedStatuses = [];
  
  
  
  const existingOrderItem = orderItems.find(orderItem => orderItem.id === isRatedItem.id)
  
  if(existingOrderItem) {
  
    orderItems.map(item => 
      item.id === isRatedItem.id
      ? orderItemRatedStatuses.push(true)
      : item.is_rated === true ? orderItemRatedStatuses.push(true) : orderItemRatedStatuses.push(false) 
      )
    
    const isAllRated = checker(orderItemRatedStatuses);
    console.log("isAllRated", isAllRated);
    console.log("orderItemRatedStatuses", orderItemRatedStatuses);
    
    const newStatus = isAllRated ? "delivered" : "to_review"

    return orders.map(order => 
      order.id === orderID
      ? {...order, status: newStatus, is_all_rated: isAllRated}
      : order
      )
    }
}