// types
import { SET_SIDEBAR_CART, SET_SIDEBAR_CLIENT, SET_SIDEBAR_FILTER } from "store/actions/actions.types";

const INITIAL_STATE = {
  isSidebarFilterVisible: false,
  isSidebarClientVisible: false,
  isSidebarCartVisible: false,
};

export default function sidebar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SIDEBAR_FILTER:
      return {
        ...state,
        isSidebarFilterVisible: !state.isSidebarFilterVisible,
      };
    case SET_SIDEBAR_CLIENT:
      return {
        ...state,
        isSidebarClientVisible: !state.isSidebarClientVisible,
      };
    case SET_SIDEBAR_CART:
      return {
        ...state,
        isSidebarCartVisible: !state.isSidebarCartVisible,
      };
    default:
      return state;
  }
};