import React from "react";

// icons
import { Icon } from "@iconify/react";
import bxlVisa from "@iconify/icons-bx/bxl-visa";
import bxlPaypal from "@iconify/icons-bx/bxl-paypal";
import bxlMastercard from "@iconify/icons-bx/bxl-mastercard";
// import bxlAmazon from "@iconify/icons-bx/bxl-amazon";

export const PaymentCards = ({ backgroundColor, color }) => {
  return (
    <div className="flex flex-wrap space-x-2 my-2">
      <div
        className={`flex items-center justify-center px-2 mb-2 cursor-pointer rounded duration-300 ${backgroundColor} ${color}`}
        >
        <Icon icon={bxlVisa} className="w-8 h-8" />
      </div>
      <div
        className={`flex items-center justify-center px-2 mb-2 cursor-pointer rounded duration-300 ${backgroundColor} ${color}`}
        >
        <Icon icon={bxlPaypal} className="w-6 h-6" />
      </div>
      <div
        className={`flex items-center justify-center px-2 mb-2 cursor-pointer rounded duration-300 ${backgroundColor} ${color}`}
        >
        <Icon icon={bxlMastercard} className="w-8 h-8" />
      </div>
      {/* <div
        className={`flex items-center justify-center px-2 mb-2 cursor-pointer rounded duration-300 ${backgroundColor} ${color}`}
      >
        <Icon icon={bxlAmazon} className="w-6 h-6" />
      </div> */}
    </div>
  );
};
