import { ADD_FAQ_FAILED, ADD_FAQ_START, ADD_FAQ_SUCCESS, DELETE_FAQ_FAILED, DELETE_FAQ_START, DELETE_FAQ_SUCCESS, GET_FAQS_FAILED, GET_FAQS_START, GET_FAQS_SUCCESS, RETRIEVE_FAQ_FAILED, RETRIEVE_FAQ_START, RETRIEVE_FAQ_SUCCESS, SET_FAQ_UPDATE_FORM, UPDATE_FAQ_FAILED, UPDATE_FAQ_START, UPDATE_FAQ_SUCCESS } from "store/actions/actions.types";

const initialState = {
  faqs: [],
  faq: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
}


export default function faq(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case SET_FAQ_UPDATE_FORM:
      return {
        ...state,
        isUpdate: true,
      }
    case ADD_FAQ_START:
      return {
        ...state,
        isFormLoading: true,
        isSubmitSuccess: false,
      }
    case GET_FAQS_START:
      return {
        ...state,
        isGetLoading: true,
      }
    case DELETE_FAQ_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case UPDATE_FAQ_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case RETRIEVE_FAQ_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case ADD_FAQ_FAILED:
    case GET_FAQS_FAILED:
    case DELETE_FAQ_FAILED:
    case RETRIEVE_FAQ_FAILED:
    case UPDATE_FAQ_FAILED:
      return {
        ...state,
        isFormLoading: false,
        isGetLoading: false,
        isDeleteLoading: false,
        isRetrieveLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
        isSubmitSuccess: false,
      }
    case ADD_FAQ_SUCCESS: 
      return {
        ...state,
        faqs: [payload, ...state.faqs],
        isFormLoading: false,
      }
    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: [...payload],
        isGetLoading: false,
      }
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.filter(
          (faq) => faq.id !== payload
        ),
        isDeleteLoading: false,
      };
    case UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        faqs: state.faqs.map((faq) =>
          faq.id === payload.id ? { ...payload } : faq
        ),
        isUpdate: false,
        isUpdateLoading: false
      };
    case RETRIEVE_FAQ_SUCCESS: {
      return {
        ...state,
        faq: payload,
        isRetrieveLoading: false
      };
    }
    default:
      return state;
  }
}