import React, { useState, useEffect } from "react";

// redux
import { connect } from "react-redux";
import { addFAQ, updateFAQ } from "store/actions/faqs.action";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";
import ButtonSpinner, { ModalLoader } from "components/common/spinner/Spinner";

// layout
import AdminFormContainer from "layouts/form-container/AdminFormContainer";

// editor
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

// text editor
import TextEditor from "components/common/text-editor/TextEditor";
import {
  selectIsFAQFormLoading,
  selectIsFAQUpdateLoading,
  selectFAQInFAQ,
  selectIsFAQIsUpdate,
  selectIsInquiryRetrieveLoading,
  selectFAQIsSubmitSuccess,
} from "store/selectors/faq.selector";
import { createStructuredSelector } from "reselect";

const FAQForm = ({
  title,
  isOnModal,
  isFormLoading,
  isUpdateLoading,
  isRetrieveLoading,
  isUpdate,
  isSubmitSuccess,
  faq,

  addFAQ,
  updateFAQ,
}) => {
  const [question, setQuestion] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [updateEditorState, setUpdateEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (faq) {
      setQuestion(faq.question);
      const blocksFromHTML = convertFromHTML(faq.answer);

      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setUpdateEditorState(() => EditorState.createWithContent(content));

      if (!isUpdate) {
        setQuestion("");
      }
    }
  }, [faq, isUpdate, isSubmitSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isUpdate) {
      const editorValue = draftToHtml(
        convertToRaw(updateEditorState.getCurrentContent())
      );
      await updateFAQ(question, editorValue, faq.id);
    } else {
      const editorValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      await addFAQ(question, editorValue);
    }

    setQuestion("");
    setEditorState(() => EditorState.createEmpty());
  };

  const form = (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="m-0 p-0">
        <FloatingFormInput
          label="Question"
          type="text"
          name="question"
          value={question}
          formType="MyAddresses"
          handleChange={(e) => setQuestion(e.target.value)}
        />

        <div className="text-gray-500 font-medium ml-1 ">Answer</div>
        <TextEditor
          setEditorState={isUpdate ? setUpdateEditorState : setEditorState}
          editorState={isUpdate ? updateEditorState : editorState}
          options={[
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "link",
            "colorPicker",
            "emoji",
            "remove",
            "history",
          ]}
        />
        <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
          <div className="uppercase text-white font-medium px-3 py-2 text-sm flex items-center">
            <span>{isUpdate ? "Update FAQ" : "Create FAQ"}</span>
            <span>
              {(isUpdateLoading || isFormLoading) && (
                <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
              )}
            </span>
          </div>{" "}
        </Button>
      </form>
    </>
  );
  return (
    <>
      {isOnModal ? (
        <> {isRetrieveLoading ? <ModalLoader /> : form} </>
      ) : (
        <AdminFormContainer title={title}>
          <div className="sticky top-1/5">{form}</div>
        </AdminFormContainer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isFormLoading: selectIsFAQFormLoading,
  isUpdateLoading: selectIsFAQUpdateLoading,
  faq: selectFAQInFAQ,
  isUpdate: selectIsFAQIsUpdate,
  isSubmitSuccess: selectFAQIsSubmitSuccess,
  isRetrieveLoading: selectIsInquiryRetrieveLoading,
});

export default connect(mapStateToProps, {
  addFAQ,
  updateFAQ,
})(FAQForm);
