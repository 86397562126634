import React from "react";

// context
// import { ThemeContext } from "context/ThemeContext";

const Checkbox = ({ handleChange, formType, textLabel, ...props }) => {
  // const { theme } = React.useContext(ThemeContext);
  return (
    <label
      htmlFor={props.id}
      className="inline-flex items-center"
    >
      <input
        type="checkbox"
        className={`form-checkbox text-default-primary`}
        onChange={handleChange}
        {...props}
      />
      <span className="ml-2 text-sm text-gray-600">{textLabel}</span>
    </label>
  );
};

export default Checkbox;
