import React, { useEffect } from "react";
import { useCallback } from "react";

export const ThemeContext = React.createContext();

const Themecontext = ({ children }) => {
  const localStorageTheme = localStorage.getItem("theme");
  const [theme, setTheme] = React.useState(localStorageTheme);

  // window.onload = checkTheme();
  
  const checkTheme = useCallback(() => {
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
    } else if (theme === "default") {
      localStorage.setItem("theme", "default");
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
    } else if (theme === "pinkParadise") {
      localStorage.setItem("theme", "pinkParadise");
    } else {
      localStorage.setItem("theme", "default");
    }
  }, [theme]);

  const defaultContext = {
    theme,
    setTheme,
  };

  

  // console.log(localStorageTheme);

  useEffect(() => {
    checkTheme();
  }, [checkTheme]);

  return (
    <ThemeContext.Provider value={defaultContext}>
      {children}
    </ThemeContext.Provider>
  );
};

export default Themecontext;