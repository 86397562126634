import React from "react";

// icons
import { Icon } from "@iconify/react";
import xIcon from "@iconify/icons-bi/x";

// backdrop
import Backdrop from "./Backdrop";

const Modal = ({
  title,
  isVisible,
  minHeight,
  onClose,
  children,
  widthStyles,
  loading = false,
}) => {
  return (
    <>
      <Backdrop show={isVisible} clicked={() => onClose()} />
      <div
        className={`p-4 transform bg-white fixed ease-in-out transition-all duration-700 z-50
      flex-col items-center
      top-0 sm:top-1/10
      left-1/2
      -translate-x-1/2 
      overflow-auto
      medium-scroll
      ${minHeight}
      ${widthStyles}
      ${isVisible ? "-translate-y-0" : "-translate-y-300"}
      `}
        style={{ maxHeight: "80vh" }}
      >
        <div className="pb-2 flex items-center w-full uppercase font-medium text-xl border-b ">
          <div className="flex-1">{title}</div>

          <Icon
            icon={xIcon}
            className="hover:text-red-400 duration-300 cursor-pointer"
            onClick={() => onClose()}
          />
        </div>
        <div className=""> {loading ? <div>Loading</div> : children}</div>
      </div>
    </>
  );
};

export default Modal;
