import { GET_ABOUTS_FAILED, GET_ABOUTS_START, GET_ABOUTS_SUCCESS, RETRIEVE_ABOUT_FAILED, RETRIEVE_ABOUT_START, RETRIEVE_ABOUT_SUCCESS, UPDATE_ABOUT_FAILED, UPDATE_ABOUT_START, UPDATE_ABOUT_SUCCESS } from "store/actions/actions.types";

const initialState = {
  abouts: [],
  about: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
}

export default function about(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case GET_ABOUTS_START:
      return {
        ...state,
        isGetLoading: true,
      }
    case UPDATE_ABOUT_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case RETRIEVE_ABOUT_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case GET_ABOUTS_FAILED:
    case RETRIEVE_ABOUT_FAILED:
    case UPDATE_ABOUT_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isRetrieveLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
      }
    case GET_ABOUTS_SUCCESS:
      return {
        ...state,
        abouts: [...payload],
        isGetLoading: false,
      }
    case UPDATE_ABOUT_SUCCESS:
      return {
        ...state,
        about: payload,
        isUpdate: false,
        isUpdateLoading: false
      };
    case RETRIEVE_ABOUT_SUCCESS: {
      return {
        ...state,
        about: payload,
        isRetrieveLoading: false
      };
    }
    default:
      return state;
  }
}