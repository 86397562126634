import React from "react";

// icons
import QuantityInput from "components/common/form-input/QuantityInput";
import { connect } from "react-redux";
import { deleteItemInCart } from "store/actions/cart.action";

// reselect
import { selectCartIsAddLoading } from "store/selectors/cart.selectors";
import { createStructuredSelector } from "reselect";
import defaultImage from "assets/common/empty-image.jpg";

const CartItem = ({
  cartItem: { id, product, quantity, total_price },
  cartAddLoading,
  deleteItemInCart,
  decreaseCartItem,
}) => {
  return (
    <div className="flex m-4">
      <div className="flex flex-col justify-center items-center">
        <img
          src={
            product && product.image && product.image.image
              ? product.image.image
              : defaultImage
          }
          alt={product ? product.name : null}
          className="w-16 h-16 object-cover rounded-sm border"
        />
        <div
          onClick={() => deleteItemInCart(id)}
          className="text-gray-500 hover:text-red-400 duration-300 cursor-pointer font-light text-semiSm"
        >
          Remove
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="pl-4">
          <div className="text-semiBase text-gray-800 font-light hover:text-default-primary cursor-pointer duration-300 mb-1">
            {product ? product.name : null}
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <div className="pl-4">
            <QuantityInput
              cartItemId={id}
              productId={product.id ? product.id : null}
              quantity={quantity}
              loading={cartAddLoading}
            />
          </div>
          <div className="flex items-end justify-end text-sm font-medium">
            {quantity ? quantity : null} × £{product ? product.price : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cartAddLoading: selectCartIsAddLoading,
});

export default connect(mapStateToProps, { deleteItemInCart })(CartItem);
