import {
  ADD_HEADER_HERO_FAILED,
  ADD_HEADER_HERO_START,
  ADD_HEADER_HERO_SUCCESS,
  DELETE_HEADER_HERO_FAILED,
  DELETE_HEADER_HERO_START,
  DELETE_HEADER_HERO_SUCCESS,
  GET_HEADER_HEROES_FAILED,
  GET_HEADER_HEROES_START,
  GET_HEADER_HEROES_SUCCESS,
  RETRIEVE_HEADER_HERO_FAILED,
  RETRIEVE_HEADER_HERO_START,
  RETRIEVE_HEADER_HERO_SUCCESS,
  SET_HEADER_HERO_UPDATE_FORM,
  UPDATE_HEADER_HERO_FAILED,
  UPDATE_HEADER_HERO_START,
  UPDATE_HEADER_HERO_SUCCESS,
} from "store/actions/actions.types";

const initialState = {
  headerHeroes: [],
  headerHero: null,
  isFormLoading: false,
  isDeleteLoading: false,
  isGetLoading: false,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
};

export default function headerHero(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_HEADER_HERO_UPDATE_FORM:
      return {
        ...state,
        isUpdate: true,
      }
    case ADD_HEADER_HERO_START:
      return {
        ...state,
        isFormLoading: true,
        isSubmitSuccess: false,
      };
    case GET_HEADER_HEROES_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case DELETE_HEADER_HERO_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case RETRIEVE_HEADER_HERO_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case UPDATE_HEADER_HERO_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case ADD_HEADER_HERO_FAILED:
    case GET_HEADER_HEROES_FAILED:
    case DELETE_HEADER_HERO_FAILED:
    case RETRIEVE_HEADER_HERO_FAILED:
    case UPDATE_HEADER_HERO_FAILED:
      return {
        ...state,
        isFormLoading: false, 
        isGetLoading: false,
        isDeleteLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
        isSubmitSuccess: false,
        isRetrieveLoading: false,
      };
    case ADD_HEADER_HERO_SUCCESS:
      return {
        ...state,
        headerHeroes: [...state.headerHeroes, payload],
        isFormLoading: false,
        isSubmitSuccess: true,
      };
    case GET_HEADER_HEROES_SUCCESS:
      return {
        ...state,
        headerHeroes: [...payload],
        isGetLoading: false,
      };
    case DELETE_HEADER_HERO_SUCCESS:
      return {
        ...state,
        headerHeroes: state.headerHeroes.filter(
          (headerHero) => headerHero.id !== payload
        ),
        isDeleteLoading: false,
      };
    case UPDATE_HEADER_HERO_SUCCESS:
      return {
        ...state,
        headerHeroes: state.headerHeroes.map((headerHero) =>
          headerHero.id === payload.id ? { ...payload } : headerHero
        ),
        isUpdate: false,
        isUpdateLoading: false
      };
    case RETRIEVE_HEADER_HERO_SUCCESS: {
      return {
        ...state,
        headerHero: payload,
        isRetrieveLoading: false
      };
    }
    default:
      return state;
  }
}
