import { toast } from "react-toastify";
import API from "store/api";
import {
  ADD_TO_CART_FAILED,
  ADD_TO_CART_START,
  ADD_TO_CART_SUCCESS,
  DECREASE_CART_ITEM_FAILED,
  DECREASE_CART_ITEM_START,
  DECREASE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAILED,
  DELETE_CART_ITEM_START,
  DELETE_CART_ITEM_SUCCESS,
  GET_CART_START,
  GET_CART_SUCCESS,
  CLEAR_CART_ITEMS,
  CLEAR_CART,
} from "./actions.types";
import { tokenConfig } from "./auth.action";
import { setAuthModal } from "./modal.action";

export const getCart = (accountCart) => (dispatch, getState) => {
  dispatch({ type: GET_CART_START });

  API.get(`/products/cart/${accountCart}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_CART_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addToCart = (product) => async (dispatch, getState) => {
  dispatch({ type: ADD_TO_CART_START });
  const body = JSON.stringify({
    item: product,
  });

  await API.post(`/products/cart-item/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_TO_CART_SUCCESS, payload: res.data });
      // toast.success("Product Added To Cart", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADD_TO_CART_FAILED });
      const errorMsg = "You need to register/login an account first!";

      toast.info(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch(setAuthModal(true));
    });
};

export const deleteItemInCart = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_CART_ITEM_START });

  API.delete(`/products/cart-item/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_CART_ITEM_SUCCESS,
        payload: { id: id, total_price: res.data.total_price },
      });
      console.log(res.data.total_price);
    })
    .then((res) => {
      // dispatch(setAlert('Deleting category successful!'));
      toast.success("Deleting Cart Item Successful!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: DELETE_CART_ITEM_FAILED });
      const errorMsg = "".concat(
        "Deleting Cart Item Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const decreaseCartItem = (id) => (dispatch, getState) => {
  dispatch({ type: DECREASE_CART_ITEM_START });

  const body = JSON.stringify({
    id: id,
  });

  API.patch(`/products/cart-item/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DECREASE_CART_ITEM_SUCCESS, payload: res.data });
      console.log(res.data);

      if (res.data.quantity === 0) {
        dispatch(deleteItemInCart(res.data.id));
      }
    })
    .catch((err) => {
      dispatch({ type: DECREASE_CART_ITEM_FAILED });
      console.log(err.response.data);
    });
};

export const clearCart = () => (dispatch) => {
  dispatch({ type: CLEAR_CART });
};

export const clearCartItems = () => (dispatch) => {
  dispatch({ type: CLEAR_CART_ITEMS });
};
