import React from "react";
import { NavLink } from "react-router-dom";

// icons
import Icon from "@iconify/react";
import bxChevronDown from "@iconify/icons-bx/bx-chevron-down";

// context
// import { ThemeContext } from "context/ThemeContext";

const NavItem = ({
  name,
  linkPath,
  hasDropdown,
  dropdownName,
  icon,
  children,
}) => {
  // const { theme } = React.useContext(ThemeContext);
  return (
    <li
      className={`list-none nav-item relative ${
        hasDropdown ? dropdownName : ""
      }`}
    >
      <NavLink
        exact
        to={linkPath}
        activeClassName={`text-default-primary`}
        // onClick={() => setNavbarDropdownOpen(!navbarDropdownOpen)}
        className={` text-sm mr-6 uppercase font-medium hover:text-default-primary duration-300 text-gray-800 outline-none focus:text-default-primaryShade 
      ${name === "Wishlist" ? "hidden" : ""} flex items-center`}
      >
        <div className="flex items-center">
          {icon ? <Icon className="w-8 h-8 mr-1" icon={icon} /> : null}
          <span>{name}</span>
          <span className={`${hasDropdown ? "block" : "hidden"}`}>
            <Icon icon={bxChevronDown} className="w-6 h-6" />
          </span>
        </div>

        {children}
      </NavLink>
    </li>
  );
};

export default NavItem;
