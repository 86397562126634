import React, { useEffect } from "react";
import { SectionTitle } from "components/common/titles/SectionTitle";
import Ratings from "../product/Ratings";
import Slider from "react-slick";
import SmallHeading from "components/common/titles/SmallHeading";
import defaultImage from "assets/common/empty-image.jpg";

// context
// import { ThemeContext } from "context/ThemeContext";

// redux
import { connect } from "react-redux";
import { getInstgramFeed } from "store/actions/instagram.action";
import { AdvertisementLoader } from "components/common/spinner/Spinner";
import { useHistory } from "react-router-dom";

const settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  centerMode: true,
  speed: 1000,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};
const nameMaxLimit = 25;
const Advertisement = ({
  getInstgramFeed,
  instagram_feed,
  bestSellers,
  newRelease,
  topRated,
  recommendations,
}) => {
  // const { theme } = React.useContext(ThemeContext);
  const history = useHistory();

  useEffect(() => {
    getInstgramFeed();
  }, [getInstgramFeed]);

  return instagram_feed.isGetLoading ? (
    <AdvertisementLoader />
  ) : (
    <div>
      <div className="my-16">
        <SectionTitle title="Check out our latest creations on Instagram" />
        <div className="mx-auto mt-16 w-10/12 lg:w-8/12 xl:w-8/12 sm:w-9/12">
          <div className="flex space-x-8">
            <Slider className="w-full" {...settings}>
              {instagram_feed.feed
                ? instagram_feed.feed.slice(0, 10).map((media, i) => {
                    return (
                      <div key={i} className="h-64 w-full">
                        <img
                          src={media.node.thumbnail_src}
                          className="w-full h-full object-cover"
                          alt="bubblesandcream instagram photos"
                        />
                      </div>
                    );
                  })
                : null}
            </Slider>
          </div>
        </div>
      </div>
      <div className="mx-auto w-full md:w-9/12 xl:w-8/12">
        <div className="flex flex-wrap px-12 pb-12 lg:px-0">
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
            <SmallHeading title="Our Recommendations" />

            {recommendations.map((bestseller) => {
              const {
                id,
                name,
                image,
                price,
                average_rating,
                slug,
              } = bestseller;
              return (
                <div key={id} className="flex items-center space-x-3 my-2">
                  <img
                    src={image && image.image ? image.image : defaultImage}
                    alt={name}
                    className="object-cover w-16 h-16"
                  />
                  <div className="flex-col">
                    <div
                      onClick={() => history.push(`/product/${slug}`)}
                      className={`font-light hover:text-default-primary cursor-pointer duration-300 text-semiBase`}
                    >
                      {" "}
                      {name.length > nameMaxLimit
                        ? name.substring(0, nameMaxLimit - 3) + "..."
                        : name}
                    </div>
                    <Ratings
                      isSelectable={false}
                      starDimension="1rem"
                      ratings={average_rating}
                    />
                    <div className="text-gray-800 font-medium ">
                      £ {price.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
            <SmallHeading title="Best Selling " />

            {bestSellers.map((bestseller) => {
              const {
                id,
                name,
                image,
                price,
                average_rating,
                slug,
              } = bestseller;
              return (
                <div key={id} className="flex items-center space-x-3 my-2">
                  <img
                    src={image && image.image ? image.image : defaultImage}
                    alt={name}
                    className="object-cover w-16 h-16"
                  />
                  <div className="flex-col">
                    <div
                      onClick={() => history.push(`/product/${slug}`)}
                      className={`font-light hover:text-default-primary cursor-pointer duration-300 text-semiBase`}
                    >
                      {" "}
                      {name.length > nameMaxLimit
                        ? name.substring(0, nameMaxLimit - 3) + "..."
                        : name}
                    </div>
                    <Ratings
                      isSelectable={false}
                      starDimension="1rem"
                      ratings={average_rating}
                    />
                    <div className="text-gray-800 font-medium ">
                      £ {price.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
            <SmallHeading title="Latest" />

            {newRelease.map((bestseller) => {
              const {
                id,
                name,
                image,
                price,
                average_rating,
                slug,
              } = bestseller;
              return (
                <div key={id} className="flex items-center space-x-3 my-2">
                  <img
                    src={image && image.image ? image.image : defaultImage}
                    alt={name}
                    className="object-cover w-16 h-16"
                  />
                  <div className="flex-col">
                    <div
                      onClick={() => history.push(`/product/${slug}`)}
                      className={`font-light hover:text-default-primary cursor-pointer duration-300 text-semiBase`}
                    >
                      {" "}
                      {name.length > nameMaxLimit
                        ? name.substring(0, nameMaxLimit - 3) + "..."
                        : name}
                    </div>
                    <Ratings
                      isSelectable={false}
                      starDimension="1rem"
                      ratings={average_rating}
                    />
                    <div className="text-gray-800 font-medium ">
                      £ {price.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-4">
            <SmallHeading title="Top Rated" />

            {topRated.map((bestseller) => {
              const {
                id,
                name,
                image,
                price,
                average_rating,
                slug,
              } = bestseller;
              return (
                <div key={id} className="flex items-center space-x-3 my-2">
                  <img
                    src={image && image.image ? image.image : defaultImage}
                    alt={name}
                    className="object-cover w-16 h-16"
                  />
                  <div className="flex-col">
                    <div
                      onClick={() => history.push(`/product/${slug}`)}
                      className={`font-light hover:text-default-primary cursor-pointer duration-300 text-semiBase`}
                    >
                      {" "}
                      {name.length > nameMaxLimit
                        ? name.substring(0, nameMaxLimit - 3) + "..."
                        : name}
                    </div>
                    <Ratings
                      isSelectable={false}
                      starDimension="1rem"
                      ratings={average_rating}
                    />
                    <div className="text-gray-800 font-medium ">
                      £ {price.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  instagram_feed: state.instagram,
  newRelease: state.product.newRelease,
  bestSellers: state.product.bestSellers,
  topRated: state.product.topRated,
  recommendations: state.product.recommendations,
});

export default connect(mapStateToProps, { getInstgramFeed })(Advertisement);
