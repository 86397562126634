import React from "react";


import StarRatings from 'react-star-ratings';

const Ratings = ({ handleRatingChange, ratings, starDimension, ...props }) => {
  return (
    <StarRatings
      rating={ratings}
      changeRating={handleRatingChange}
      isSelectable={false}
      starRatedColor="#6a6a6d"
      starEmptyColor="#e2e8f0"
      starHoverColor="#93c8b4"
      starDimension={starDimension}
      starSpacing="0.1rem"
      {...props}
    />
  );
};

export const RatingsColored = ({ handleRatingChange, ratings, starDimension, ...props }) => {
  return (
    <StarRatings
    rating={ratings}
    changeRating={handleRatingChange}
    isSelectable={false}
    starRatedColor="#ed64a6"
    starEmptyColor="#e2e8f0"
    starHoverColor="#93c8b4"
    starDimension={starDimension}
    starSpacing="0.1rem"
    {...props}
  />
  );
};

export default Ratings;
