import { nanoid } from 'nanoid';

// icons
import bxsDashboard from '@iconify/icons-bx/bxs-dashboard';
import bxCabinet from '@iconify/icons-bx/bx-cabinet';
// import bxBarChartSquare from '@iconify/icons-bx/bx-bar-chart-square';
import bxsCog from '@iconify/icons-bx/bxs-cog';
import bxsBasket from '@iconify/icons-bx/bxs-basket';
import bxsBox from '@iconify/icons-bx/bxs-box';
import usersSolid from '@iconify/icons-clarity/users-solid';

export const DASHBOARD_ROUTES = [
  {
    id: nanoid(),
    routeName: "/admin/dashboard",
    name: "Dashboard",
    icon: bxsDashboard,
  },
  {
    id: nanoid(),
    routeName: "/admin/categories",
    name: "Categories",
    icon: bxCabinet,
  },
  {
    id: nanoid(),
    routeName: "/admin/products",
    name: "Products",
    icon: bxsBasket,
  },
  {
    id: nanoid(),
    routeName: "/admin/orders",
    name: "Orders",
    icon: bxsBox,
  },
  {
    id: nanoid(),
    routeName: "/admin/customers",
    name: "Customers",
    icon: usersSolid,
  },
  // {
  //   id: nanoid(),
  //   routeName: "/admin/reports",
  //   name: "Reports",
  //   icon: bxBarChartSquare,
  // },
  {
    id: nanoid(),
    routeName: "/admin/settings",
    name: "Settings",
    icon: bxsCog,
  },
];