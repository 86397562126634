import React from "react";

// components
import AdminSidebar from "components/common/sidebar/AdminSidebar";
import Backdrop from "components/common/modal/Backdrop";
import Modal from "components/common/modal/Modal";
import AdminNavbar from "components/common/navbar/AdminNavbar";
// forms
import CategoryForm from "components/forms/admin/CategoryForm";
import ProductForm from "components/forms/admin/ProductForm";
import FAQForm from "components/forms/admin/FAQForm";
import TestimonialForm from "components/forms/admin/TestimonialForm";
// views
import ViewInquiry from "components/admin/dashboard/ViewInquiry";
import QuickView from "components/landing/product/QuickView";

// reducer
import { connect } from "react-redux";
import {
  setAddFAQModal,
  setAddTestimonialModal,
  setAuthModal,
  setCategoryModal,
  setCreateNewHeaderHeroModal,
  setProductModal,
  setQuickViewModal,
  setUpdateCategoryModal,
  setUpdateFAQModal,
  setUpdateHeaderHeroModal,
  setUpdateProductModal,
  setUpdateTestimonialModal,
  viewInquiryModal,
  viewOrderDetailsModal,
} from "store/actions/modal.action";
import { setSidebarClient } from "store/actions/sidebar.actions";

// toast
import { ToastContainer } from "react-toastify";
import { selectAuthLoading } from "store/selectors/auth.selector";
import ViewOrders from "components/landing/order/ViewOrders";
import Sidebar from "components/common/sidebar/Sidebar";
import ClientSidebar from "components/common/sidebar/ClientSidebar";
import HeaderHeroForm from "components/forms/admin/HeaderHeroForm";
import { clearProduct } from "store/actions/product.action";
// import { Redirect } from "react-router-dom";

const DashboardLayout = ({
  isCategoryModalVisible,
  isProductModalVisible,
  setCategoryModal,
  setProductModal,
  isUpdateCategoryModalVisible,
  setUpdateCategoryModal,
  setUpdateProductModal,
  isUpdateProductModalVisible,
  isAuthenticated,
  isSidebarClientVisible,
  setSidebarClient,
  isUpdateFAQModalVisible,
  isFAQModalVisible,
  setAddFAQModal,
  setUpdateFAQModal,
  isTestimonialModalVisible,
  setAddTestimonialModal,
  setUpdateTestimonialModal,
  isUpdateTestimonialModalVisible,
  viewInquiryModal,
  isViewInquiryModalVisible,
  setQuickViewModal,
  isQuickViewModalVisible,
  viewOrderDetailsModal,
  isViewOrderDetailsModalVisible,
  isAuthModalVisible,
  setAuthModal,
  isCreateNewHeaderModalVisible,
  setCreateNewHeaderHeroModal,
  isUpdateHeaderHeroModalVisible,
  setUpdateHeaderHeroModal,
  children,
  clearProduct,
}) => {
  // if (!isAuthenticated) {
  //   return <Redirect to="/" />;
  // }

  return (
    <div className="relative">
      <ToastContainer />
      {isCategoryModalVisible || isProductModalVisible ? <Backdrop /> : null}
      <div className="flex flex-col md:flex-row w-full h-screen">
        <AdminSidebar />

        <div className="w-full h-full mb-12 sm:my-0 overflow-y-auto ">
          {/* <AdminNavbar open={open} setOpen={setOpen} /> */}
          <AdminNavbar
            isSidebarClientVisible={isSidebarClientVisible}
            setSidebarClient={() => setSidebarClient(!isSidebarClientVisible)}
          />

          <div className="w-10/12 mx-auto mb-12">{children}</div>
        </div>
      </div>

      {/* STUB CLIENT SIDEBAR - the hamburger menu */}
      <Sidebar
        position="left"
        isVisible={isSidebarClientVisible}
        onClose={() => setSidebarClient(false)}
        visibilityStyles="md:hidden"
      >
        <ClientSidebar handleClick={() => setAuthModal(!isAuthModalVisible)} />
      </Sidebar>

      {/* SECTION MODALS */}

      {/* STUB ADD CATEGORY MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isCategoryModalVisible}
        title="Add New Category"
        onClose={() => setCategoryModal(false)}
      >
        <div className="flex flex-col mt-3">
          <CategoryForm isOnModal />
        </div>
      </Modal>

      {/* STUB ADD PRODUCT MODAL */}
      <Modal
        widthStyles="w-full sm:w-10/12 lg:w-8/12"
        maxWidth="30%"
        isVisible={isProductModalVisible}
        title="Add New Product"
        onClose={() => {
          setProductModal(false);
          clearProduct();
        }}
      >
        <div className="flex flex-col mt-3">
          <ProductForm isOnModal />
        </div>
      </Modal>

      {/* STUB UPDATE CATEGORY MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isUpdateCategoryModalVisible}
        title="Update Category"
        onClose={() => setUpdateCategoryModal(false)}
      >
        <div className="flex flex-col mt-3">
          <CategoryForm isOnModal />
        </div>
      </Modal>

      {/* STUB UPDATE PRODUCT MODAL */}
      <Modal
        widthStyles="w-full sm:w-10/12 lg:w-8/12"
        maxWidth="30%"
        isVisible={isUpdateProductModalVisible}
        title="Update Product"
        onClose={() => {
          setUpdateProductModal(false);
          clearProduct();
        }}
      >
        <div className="flex flex-col mt-3">
          <ProductForm isOnModal />
        </div>
      </Modal>

      {/* STUB ADD FAQ MODAL */}
      <Modal
        widthStyles="w-full sm:w-3/4"
        maxWidth="30%"
        isVisible={isFAQModalVisible}
        title="Add FAQ"
        onClose={() => setAddFAQModal(false)}
      >
        <div className="flex flex-col mt-3">
          <FAQForm isOnModal />
        </div>
      </Modal>

      {/* STUB UPDATE FAQ MODAL */}
      <Modal
        widthStyles="w-full sm:w-3/4"
        maxWidth="30%"
        isVisible={isUpdateFAQModalVisible}
        title="Update FAQ"
        onClose={() => setUpdateFAQModal(false)}
      >
        <div className="flex flex-col mt-3">
          <FAQForm isOnModal />
        </div>
      </Modal>

      {/* STUB ADD TESTIMONIAL MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isTestimonialModalVisible}
        title="Add Testimonial"
        onClose={() => setAddTestimonialModal(false)}
      >
        <div className="flex flex-col mt-3">
          <TestimonialForm isOnModal />
        </div>
      </Modal>

      {/* STUB UPDATE TESTIMONIAL MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isUpdateTestimonialModalVisible}
        title="Update Testimonial"
        onClose={() => setUpdateTestimonialModal(false)}
      >
        <div className="flex flex-col mt-3">
          <TestimonialForm isOnModal />
        </div>
      </Modal>

      {/* STUB INQUIRY VIEW MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isViewInquiryModalVisible}
        title="Viewing Client Inquiry"
        onClose={() => viewInquiryModal(false)}
      >
        <div className="flex flex-col mt-3">
          <ViewInquiry />
        </div>
      </Modal>

      {/* STUB QUICK VIEW MODAL */}
      <Modal
        widthStyles="w-full sm:w-10/12 xl:w-7/12"
        isVisible={isQuickViewModalVisible}
        title="Quick View"
        minHeight="min-h-semi"
        onClose={() => setQuickViewModal(false)}
      >
        <QuickView isAdminView />
      </Modal>

      {/* STUB VIEW ORDER DETAILS MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        isVisible={isViewOrderDetailsModalVisible}
        title="ORDER DETAILS"
        onClose={() => viewOrderDetailsModal(false)}
      >
        <ViewOrders isAdminView />
      </Modal>

      {/* STUB ADD NEW HEADER MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        isVisible={isCreateNewHeaderModalVisible}
        title="Add New Header Hero"
        onClose={() => setCreateNewHeaderHeroModal(false)}
      >
        <HeaderHeroForm isOnModal />
      </Modal>

      {/* STUB UPDATE HEADER HERO MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isUpdateHeaderHeroModalVisible}
        title="Update Header Hero"
        onClose={() => setUpdateHeaderHeroModal(false)}
      >
        <div className="flex flex-col mt-3">
          <HeaderHeroForm isOnModal />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCategoryModalVisible: state.formModal.isCategoryModalVisible,
  isProductModalVisible: state.formModal.isProductModalVisible,
  isUpdateCategoryModalVisible: state.formModal.isUpdateCategoryModalVisible,
  isUpdateProductModalVisible: state.formModal.isUpdateProductModalVisible,
  isAuthenticated: selectAuthLoading(state),

  isSidebarClientVisible: state.sidebar.isSidebarClientVisible,

  isAuthModalVisible: state.formModal.isAuthModalVisible,
  isUpdateFAQModalVisible: state.formModal.isUpdateFAQModalVisible,
  isFAQModalVisible: state.formModal.isFAQModalVisible,

  isTestimonialModalVisible: state.formModal.isTestimonialModalVisible,
  isUpdateTestimonialModalVisible:
    state.formModal.isUpdateTestimonialModalVisible,

  isViewInquiryModalVisible: state.formModal.isViewInquiryModalVisible,

  isQuickViewModalVisible: state.formModal.isQuickViewModalVisible,
  isViewOrderDetailsModalVisible:
    state.formModal.isViewOrderDetailsModalVisible,

  isCreateNewHeaderModalVisible: state.formModal.isCreateNewHeaderModalVisible,

  isUpdateHeaderHeroModalVisible:
    state.formModal.isUpdateHeaderHeroModalVisible,
});

export default connect(mapStateToProps, {
  setUpdateProductModal,
  setUpdateCategoryModal,
  setCategoryModal,
  setProductModal,
  setSidebarClient,
  setAuthModal,
  setAddFAQModal,
  setUpdateFAQModal,
  setAddTestimonialModal,
  setUpdateTestimonialModal,
  viewInquiryModal,
  setQuickViewModal,
  viewOrderDetailsModal,
  setCreateNewHeaderHeroModal,
  setUpdateHeaderHeroModal,
  clearProduct,
})(DashboardLayout);
