import {
  ADD_TO_WISHLIST_FAILED,
  ADD_TO_WISHLIST_START,
  ADD_TO_WISHLIST_SUCCESS,
  CLEAR_WISHLIST,
  CLEAR_WISHLIST_ITEMS,
  GET_WISHLIST_FAILED,
  GET_WISHLIST_ITEMS_FAILED,
  GET_WISHLIST_ITEMS_START,
  GET_WISHLIST_ITEMS_SUCCESS,
  GET_WISHLIST_START,
  GET_WISHLIST_SUCCESS,
  REMOVE_TO_WISHLIST_FAILED,
  REMOVE_TO_WISHLIST_START,
  REMOVE_TO_WISHLIST_SUCCESS,
} from "store/actions/actions.types";

const INITIAL_STATE = {
  wishlist: null,
  wishlistItems: [],
  isGetLoading: false,
  isGetWishlistItemsLoading: false,
  isAddLoading: false,
  isDeleteLoading: false,
  isRemoveLoading: false,
  isDecreaseLoading: false,
};

export default function cart(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_WISHLIST_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case GET_WISHLIST_ITEMS_START:
      return {
        ...state,
        isGetWishlistItemsLoading: true,
      };
    case ADD_TO_WISHLIST_START:
      return {
        ...state,
        isAddLoading: true,
      };
    case REMOVE_TO_WISHLIST_START:
      return {
        ...state,
        isRemoveLoading: true,
      };
    case GET_WISHLIST_FAILED:
    case GET_WISHLIST_ITEMS_FAILED:
    case ADD_TO_WISHLIST_FAILED:
    case REMOVE_TO_WISHLIST_FAILED:
      return {
        ...state,
        isAddLoading: false,
        isGetLoading: false,
        isGetWishlistItemsLoading: false,
        isDeleteLoading: false,
        isRemoveLoading: false,
      };
    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        wishlist: payload,
      };
    case GET_WISHLIST_ITEMS_SUCCESS:
      return {
        ...state,
        isGetWishlistItemsLoading: false,
        wishlistItems: [...payload, ...state.wishlistItems],
      };
    case ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        isAddLoading: false,
        wishlistItems: [payload, ...state.wishlistItems],
        wishlist: {
          ...state.wishlist,
          products: [...state.wishlist.products, payload.product.id],
        },
      };
    case REMOVE_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        isRemoveLoading: false,
        wishlistItems: state.wishlistItems.filter(
          (item) => item.product.id !== payload
        ),
        // wishlist: state.wishlist.products.filter(item => item.id !== payload)
        wishlist: {
          ...state.wishlist,
          products: state.wishlist.products.filter((item) => item !== payload),
        },
      };
    case CLEAR_WISHLIST:
      return {
        ...state,
        wishlist: null,
      };
    case CLEAR_WISHLIST_ITEMS:
      return {
        ...state,
        wishlistItems: [],
      };
    default:
      return state;
  }
}
