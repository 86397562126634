import React, { useState, useEffect } from "react";

// redux
import { connect } from "react-redux";

// components
import Button from "components/common/buttons/Button";
import ButtonSpinner, { ModalLoader } from "components/common/spinner/Spinner";

// layout
import AdminFormContainer from "layouts/form-container/AdminFormContainer";

import { createStructuredSelector } from "reselect";

// reselect
import {
  selectHeaderHeroInHeaderHero,
  selectHeaderHeroIsFormLoading,
  selectHeaderHeroIsUpdateLoading,
  selectIsHeaderHeroRetrieveLoading,
  selectIsHeaderHeroSubmitSuccess,
  selectIsHeaderHeroUpdate,
} from "store/selectors/header-hero.selector";

import { useForm } from "react-hook-form";
import { CustomTextareaField } from "components/common/form-input/FloatingTextarea";
import { CustomInputField } from "components/common/form-input/FloatingFormInput";
import { ErrorMessageInField } from "components/common/form-input/FloatingFormInput";
import ImageField from "components/common/form-input/ImageField";
import { v4 as uuidv4 } from "uuid";

import {
  addHeaderHero,
  updateHeaderHero,
} from "store/actions/header-hero.action";

const HeaderHeroForm = ({
  title,
  isOnModal,
  isFormLoading,
  isUpdateLoading,
  isRetrieveLoading,
  isUpdate,
  addHeaderHero,
  updateHeaderHero,
  headerHero,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [formData, setFormData] = useState({
    image: null,
    other: ''
  });

  const headerHeroID = uuidv4();
  const onSubmit = async (data, e) => {
    if (isUpdate) {
      await updateHeaderHero(
        data.heading,
        data.description,
        formData.image,
        data.buttonName,
        data.buttonLink,
        headerHero.id,
        formData.image === headerHero.image ? false : true
      );
    } else {
      const { image } = formData;
      await addHeaderHero(
        data.heading,
        data.description,
        image,
        data.buttonName,
        data.buttonLink
      );
    }
    setFormData({
      image: null,
    });
    e.target.reset();
  };

  useEffect(() => {
    if (headerHero) {

      setFormData({
        image: headerHero.image ? headerHero.image : "",
      });

      setValue("heading", headerHero.heading, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("description", headerHero.description, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("buttonName", headerHero.button_name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("buttonLink", headerHero.button_link, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [headerHero, setValue]);

  const form = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0">
        {/* {(isUpdateLoading || isFormLoading) && (
          <div className="my-2 text-sm text-red-400">
            Please wait while the category is being{" "}
            {isUpdateLoading ? "updated in" : "added to"} our database.
          </div>
        )} */}
        {/* STUB IMAGE */}
        <ImageField
          imageID={`headerHeroImageField-${headerHeroID}`}
          setImgData={setFormData}
          imgData={formData}
        />

        {/* STUB HEADING */}
        <CustomInputField
          type="text"
          name="heading"
          inputRef={register({ required: true, minLength: 3, maxLength: 100 })}
          label="Heading"
          aria-invalid={errors.heading ? true : false}
          isError={errors.heading}
        />
        <ErrorMessageInField>
          {errors.heading && errors.heading.type === "required" && (
            <p>Heading is required.</p>
          )}
          {errors.heading && errors.heading.type === "minLength" && (
            <p>Heading should be at-least 2 characters.</p>
          )}
          {errors.heading && errors.heading.type === "maxLength" && (
            <p>Heading should be not more than 100 characters.</p>
          )}
        </ErrorMessageInField>
        {/* STUB DESCRIPTION */}
        <CustomTextareaField
          type="text"
          name="description"
          inputRef={register({
            required: true,
          })}
          label="Description"
          aria-invalid={errors.description ? true : false}
          isError={errors.description}
        />
        <ErrorMessageInField>
          {errors.description && errors.description.type === "required" && (
            <p>Description is required.</p>
          )}
        </ErrorMessageInField>

        {/* STUB BUTTON NAME */}
        <CustomInputField
          type="text"
          name="buttonName"
          inputRef={register({ required: true, minLength: 3, maxLength: 50 })}
          label="Button Name"
          aria-invalid={errors.buttonName ? true : false}
          isError={errors.buttonName}
        />
        <ErrorMessageInField>
          {errors.buttonName && errors.buttonName.type === "required" && (
            <p>Button Name is required.</p>
          )}
          {errors.buttonName && errors.buttonName.type === "minLength" && (
            <p>Button Name should be at-least 2 characters.</p>
          )}
          {errors.buttonName && errors.buttonName.type === "maxLength" && (
            <p>Button Name should be not more than 50 characters.</p>
          )}
        </ErrorMessageInField>

        {/* STUB BUTTON LINK */}
        <CustomInputField
          type="text"
          name="buttonLink"
          inputRef={register({ required: true, minLength: 3, maxLength: 50 })}
          label="Button Link"
          aria-invalid={errors.buttonLink ? true : false}
          isError={errors.buttonLink}
        />
        <ErrorMessageInField>
          {errors.buttonLink && errors.buttonLink.type === "required" && (
            <p>Button Link is required.</p>
          )}
          {errors.buttonLink && errors.buttonLink.type === "minLength" && (
            <p>Button Link should be at-least 2 characters.</p>
          )}
          {errors.buttonLink && errors.buttonLink.type === "maxLength" && (
            <p>Button Link should be not more than 50 characters.</p>
          )}
        </ErrorMessageInField>

        <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
          <div className="uppercase text-white font-medium px-3 py-2 text-sm flex items-center">
            <span>{isUpdate ? "Update Header" : "Create Header"}</span>
            <span>
              {(isUpdateLoading || isFormLoading) && (
                <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
              )}
            </span>
          </div>{" "}
        </Button>
      </form>
    </>
  );
  return (
    <>
      {isOnModal ? (
        <> {isRetrieveLoading ? <ModalLoader /> : form} </>
      ) : (
        <AdminFormContainer title={title}>
          <div className="sticky top-1/5">{form}</div>
        </AdminFormContainer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isFormLoading: selectHeaderHeroIsFormLoading,
  isUpdateLoading: selectHeaderHeroIsUpdateLoading,
  isUpdate: selectIsHeaderHeroUpdate,
  headerHero: selectHeaderHeroInHeaderHero,
  isSubmitSuccess: selectIsHeaderHeroSubmitSuccess,
  isRetrieveLoading: selectIsHeaderHeroRetrieveLoading,
});

export default connect(mapStateToProps, {
  addHeaderHero,
  updateHeaderHero,
})(HeaderHeroForm);
