import { ADD_INQUIRY_FAILED, ADD_INQUIRY_START, ADD_INQUIRY_SUCCESS, DELETE_INQUIRY_FAILED, DELETE_INQUIRY_START, DELETE_INQUIRY_SUCCESS, GET_INQUIRIES_FAILED, GET_INQUIRIES_START, GET_INQUIRIES_SUCCESS, RETRIEVE_INQUIRY_FAILED, RETRIEVE_INQUIRY_START, RETRIEVE_INQUIRY_SUCCESS } from "store/actions/actions.types";

const initialState = {
  inquiries: [],
  inquiry: null,
  isGetLoading: false,
  isFormLoading: false,
  isRetrieveLoading: false,
  isDeleteLoading: false,
}


export default function inquiry(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case ADD_INQUIRY_START:
      return {
        ...state,
        isFormLoading: true,
      }
    case GET_INQUIRIES_START:
      return {
        ...state,
        isGetLoading: true,
      }
    case RETRIEVE_INQUIRY_START:
      return {
        ...state,
        isRetrieveLoading: true,
      }
    case DELETE_INQUIRY_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case ADD_INQUIRY_FAILED:
    case GET_INQUIRIES_FAILED:
    case RETRIEVE_INQUIRY_FAILED:
    case DELETE_INQUIRY_FAILED:
      return {
        ...state,
        isFormLoading: false,
        isGetLoading: false,
        isDeleteLoading: false,
      }
    case ADD_INQUIRY_SUCCESS:
      return {
        ...state,
        inquiries: [payload, ...state.inquiries],
        isFormLoading: false,
      }
    case GET_INQUIRIES_SUCCESS:
      return {
        ...state,
        inquiries: [...payload],
        isGetLoading: false,
      }
    case RETRIEVE_INQUIRY_SUCCESS: {
      return {
        ...state,
        inquiry: payload,
        isRetrieveLoading: false
      };
    }
    case DELETE_INQUIRY_SUCCESS:
      return {
        ...state,
        inquiries: state.inquiries.filter(
          (inquiry) => inquiry.id !== payload
        ),
        isDeleteLoading: false,
      };
    default:
      return state;
  }
}