import {
  ADD_ORDER_FAILED,
  ADD_ORDER_START,
  ADD_ORDER_SUCCESS,
  GET_ORDERS_FAILED,
  GET_ORDERS_START,
  GET_ORDERS_SUCCESS,
  RETRIEVE_ORDER_FAILED,
  RETRIEVE_ORDER_START,
  RETRIEVE_ORDER_SUCCESS,
  SET_TO_SHIPPING_START,
  SET_TO_SHIPPING_FAILED,
  SET_TO_SHIPPING_SUCCESS,
  ORDER_RECEIVE_START,
  ORDER_RECEIVE_FAILED,
  ORDER_RECEIVE_SUCCESS,
  SET_ORDER_IS_RATED,
  RETRIEVE_ORDER_ITEM_START,
  RETRIEVE_ORDER_ITEM_FAILED,
  RETRIEVE_ORDER_ITEM_SUCCESS,
  ORDER_DELIVERED_START,
  ORDER_DELIVERED_FAILED,
  ORDER_DELIVERED_SUCCESS,
} from "store/actions/actions.types";
import { changeIsRated, changeToDeliveredStatus } from "store/utilities/order.utils";

const INITIAL_STATE = {
  orders: [],
  order: null,
  orderItem: null,
  isAddLoading: false,
  isGetLoading: false,
  isRetrieveLoading: false,
  isSetToShippingLoading: false,
  isSetToReviewLoading: false,
  isOrderItemRetrieveLoading: false,
  isSetToDeliveredLoading: false,
};

export default function cart(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case ADD_ORDER_START:
      return {
        ...state,
        isAddLoading: true,
      };
    case GET_ORDERS_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case RETRIEVE_ORDER_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case SET_TO_SHIPPING_START:
      return {
        ...state,
        isSetToShippingLoading: true,
      };
    case ORDER_DELIVERED_START:
      return {
        ...state,
        isSetToDeliveredLoading: true,
      };
    case ORDER_RECEIVE_START:
      return {
        ...state,
        isSetToReviewLoading: true,
      };
    case RETRIEVE_ORDER_ITEM_START:
      return {
        ...state,
        isOrderItemRetrieveLoading: true,
      };
    case ADD_ORDER_FAILED:
    case GET_ORDERS_FAILED:
    case RETRIEVE_ORDER_FAILED:
    case SET_TO_SHIPPING_FAILED:
    case ORDER_RECEIVE_FAILED:
    case RETRIEVE_ORDER_ITEM_FAILED:
    case ORDER_DELIVERED_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isRetrieveLoading: false,
        isSetToShippingLoading: false,
        isSetToReviewLoading: false,
        isOrderItemRetrieveLoading: false,
        isSetToDeliveredLoading: false,
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        isAddLoading: false,
        order: payload,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        orders: [...payload],
      };
    case RETRIEVE_ORDER_SUCCESS:
      return {
        ...state,
        order: payload,
        isRetrieveLoading: false,
      };
    case SET_TO_SHIPPING_SUCCESS:
    case ORDER_RECEIVE_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.id === payload.id ? { ...payload } : order
        ),
        isSetToShippingLoading: false,
        isSetToReviewLoading: false,
      };
    case ORDER_DELIVERED_SUCCESS:
      return {
        ...state,
        orders: changeToDeliveredStatus(state.orders, state.order.id, state.order.order_items, payload),
        isSetToDeliveredLoading: false,
      };
    case SET_ORDER_IS_RATED:
        return {
          ...state,
          orders: changeIsRated(state.orders, state.order.id, state.order.order_items, payload)
        }
    case RETRIEVE_ORDER_ITEM_SUCCESS:
        return {
          ...state,
          orderItem: payload,
          isOrderItemRetrieveLoading: false,
        }
    default:
      return state;
  }
}
