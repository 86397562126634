import React, { useState } from "react";

// icons
import { Icon } from "@iconify/react";
import journalArrowDown from "@iconify/icons-bi/journal-arrow-down";
import journalArrowUp from "@iconify/icons-bi/journal-arrow-up";
import bxTrashAlt from "@iconify/icons-bx/bx-trash-alt";

// redux
import { connect } from "react-redux";
import { deleteReview, publishReview } from "store/actions/review.action";

// components
import ButtonSpinner from "components/common/spinner/Spinner";

// confirm alert
import { confirmAlert } from "react-confirm-alert";


const ReviewActions = ({ isPublished, publishReview, deleteReview, id }) => {
  const [publishLoading, setPublishLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const publishHandler = async (isPublished, id) => {
    setPublishLoading(true);
    await publishReview(isPublished, id);
    setPublishLoading(false);
  };
  const deleteHandler = async (id) => {
    setDeleteLoading(true);
    await deleteReview(id);
    setDeleteLoading(false);
  };

  const deleteConfirm = () => {
    confirmAlert({
      title: "Delete a Review",
      message: "Are you sure you want to delete this review?",
      buttons: [
        {
          label: "Yes, Delete",
          onClick: () => {
            deleteHandler(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <div className="flex items-center justify-center space-x-2">
        <div
          onClick={() => publishHandler(!isPublished, id)}
          className={`${
            isPublished
              ? "bg-red-400 hover:bg-red-500 text-xs"
              : "bg-blue-400 hover:bg-blue-500 text-sm"
          } text-white rounded-sm px-3 cursor-pointer uppercase  font-medium text-center py-1 flex items-center space-x-2 duration-300`}
        >
          <span>{isPublished ? "Unpublish" : "Publish"}</span>
          {publishLoading ? (
            <ButtonSpinner size="w-6 h-6" color="text-white" />
          ) : (
            <Icon
              icon={isPublished ? journalArrowDown : journalArrowUp}
              className="w-4 h-4"
            />
          )}
        </div>
        <div
          onClick={deleteConfirm}
          className={`bg-red-400 hover:bg-red-500
          } text-white rounded-sm px-3 cursor-pointer uppercase  font-medium text-center py-1 flex items-center space-x-2 duration-300`}
        >
          {deleteLoading ? (
            <ButtonSpinner size="w-6 h-6" color="text-white" />
          ) : (
            <Icon icon={bxTrashAlt} className="w-5 h-5" />
          )}

        </div>
      </div>
    </>
  );
};

export default connect(null, { publishReview, deleteReview })(ReviewActions);
