import {
  REGISTRATION_FAILED,
  REGISTRATION_SUCCESSFUL,
  AUTH_ERROR,
  LOGIN_START,
  LOGIN_SUCCESSFUL,
  LOGIN_FAILED,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_FAILED,
  LOGOUT_ACCOUNT,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
} from "store/actions/actions.types";

const INITIAL_STATE = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: false,
  isUpdateLoading: false,
  account: null,
  errors: [],
};

export default function auth(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PROFILE_START:
      return {
        ...state,
        isUpdateLoading: true,
      }
    case LOGIN_SUCCESSFUL:
    case REGISTRATION_SUCCESSFUL:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: {...payload},
        isAuthenticated: true,
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        account: payload,
        isUpdateLoading: false
      };
    case LOGIN_FAILED:
      return {
        ...state,
        token: null,
        account: null,
        isAuthenticated: false,
        isLoading: false,
        errors: payload,
      }
    case REGISTRATION_FAILED:
    case AUTH_ERROR:
    case LOAD_ACCOUNT_FAILED:
    case LOGOUT_ACCOUNT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        account: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case UPDATE_PROFILE_FAILED:
        return {
          ...state,
          isUpdateLoading: false,
      }
    default:
      return state;
  }
}
