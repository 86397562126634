import React from "react";

// data
import { SIDEBAR_ROUTES } from "data/sidebar-routes";

// route
import { Link, withRouter } from "react-router-dom";

// components
import ProfileSidebar from "components/common/sidebar/ProfileSidebar";

// icons
import { Icon } from "@iconify/react";
import bxLogOutCircle from "@iconify/icons-bx/bx-log-out-circle";
import bxsDashboard from '@iconify/icons-bx/bxs-dashboard';
import { connect } from "react-redux";
import bxsHeart from "@iconify/icons-bx/bxs-heart";

// selector
import { createStructuredSelector } from "reselect";
import { selectAccount, selectIsAuthenticated } from "store/selectors/auth.selector";
import { logoutAccount } from "store/actions/auth.action";

const ClientSidebar = ({
  isAuthenticated, account,
  logoutAccount,
  handleClick,
  history,
}) => {

  const logoutClickedHandler = () => {
    logoutAccount();
    history.replace("/");
  };

  return (
    // <aside
    //   ref={reference}setOpen
    //   className={`md:hidden transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50
    //   ${open ? "translate-x-0" : "-translate-x-full"}
    //   `}
    // >
    <div className="flex flex-col">
      <ProfileSidebar
      handleClick={handleClick}
      />

      <div className="flex-1">
        {SIDEBAR_ROUTES.map((route, i) => {
          const { id, name, routeName, icon } = route;
          return (
            <Link
              key={id}
              to={routeName}
              className={`p-3 pl-5 flex items-center text-gray-600
                ${i === SIDEBAR_ROUTES.length - 1 ? "border-none" : "border-b"}
                `}
            >
              <Icon icon={icon} className="w-6 h-6" />
              <div className="ml-2">{name}</div>
            </Link>
          );
        })}
      </div>
      {isAuthenticated && account.account_type === "Admin" ? null : (
        <Link
          to="/wishlist"
          className="flex items-center text-gray-600 p-3 pl-5 border-t cursor-pointer"
        >
          <Icon icon={bxsHeart} className="w-6 h-6" />
          <div className="ml-2">Wishlist</div>
        </Link>
      )}
      {isAuthenticated && account.account_type === "Admin" ? (
        <Link
          to="/admin/dashboard"
          className="flex items-center text-gray-600 p-3 pl-5 border-t cursor-pointer"
        >
          <Icon icon={bxsDashboard} className="w-6 h-6" />
          <div className="ml-2">Dashboard</div>
        </Link>
      ) : null}
      {isAuthenticated ? (
        <div
          onClick={() => logoutClickedHandler()}
          className="flex items-center text-gray-600 p-3 pl-5 border-t cursor-pointer"
        >
          <Icon icon={bxLogOutCircle} className="w-6 h-6" />
          <div className="ml-2">Logout</div>
        </div>
      ) : null}
    </div>
    // </aside>
  );
};

const mapStateToProps = createStructuredSelector({
  account: selectAccount,
  isAuthenticated: selectIsAuthenticated,
});

export default connect(mapStateToProps, { logoutAccount })(withRouter(ClientSidebar));
