import { createSelector } from 'reselect';

const selectProduct = state => state.product;

export const selectProductInProduct = createSelector(
  [selectProduct],
  product => product && product.product
);

export const selectProductID = createSelector(
  [selectProductInProduct],
  product => product && product.id
);

export const selectProducts = createSelector(
  [selectProduct],
  product => product && product.products
);

export const selectIsProductGetLoading = createSelector(
  [selectProduct],
  product => product && product.isGetLoading
)
export const selectIsProductUpdateLoading = createSelector(
  [selectProduct],
  product => product && product.isUpdateLoading
)

export const selectIsProductRetrieveLoading = createSelector(
  [selectProduct],
  product => product && product.isRetrieveLoading
)

export const selectIsProductFormLoading = createSelector(
  [selectProduct],
  product => product && product.isFormLoading
)

export const selectProductHasMore = createSelector(
  [selectProduct],
  product => product && product.hasMore
)

export const selectProductUpdate = createSelector(
  [selectProduct],
  product => product && product.isUpdate
)

export const selectProductIsSubmitSuccess = createSelector(
  [selectProduct],
  product => product && product.isSubmitSuccess
)
