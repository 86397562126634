import React, { useState, useEffect } from "react";
import Slider from "react-slick";

// components
import QuantityInput from "components/common/form-input/QuantityInput";
import Button from "components/common/buttons/Button";
import { RatingsColored } from "./Ratings";

// icons
import Icon from "@iconify/react";
import bxHeart from "@iconify/icons-bx/bx-heart";
import { connect } from "react-redux";
import { ModalLoader } from "components/common/spinner/Spinner";
import { addToCart } from "store/actions/cart.action";
import {
  selectCartIsAddLoading,
  selectCartItems,
} from "store/selectors/cart.selectors";
import {
  selectIsProductRetrieveLoading,
  selectProductInProduct,
} from "store/selectors/product.selector";
import { createStructuredSelector } from "reselect";
import {
  selectIsAuthenticated,
  selectAccountType,
} from "store/selectors/auth.selector";
import Tags from "../shop/Tags";
import { Link } from "react-router-dom";
import { addToWishlist, removeToWishlist } from "store/actions/wishlist.action";
import { selectWishlistInWishlist } from "store/selectors/wishlist.selector";

const QuickView = ({
  isProductView,
  product,
  isRetrieveLoading,
  addToCart,
  isAdminView,
  cartAddLoading,
  cartItems,
  isAuthenticated,
  addToWishlist,
  removeToWishlist,
  wishlist,
  accountType,
}) => {
  const mainSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: product ? product.images.length > 2 : true,
    arrows: false,
    adaptiveHeight: true,
  };

  const secondarySettings = {
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    // slidesToScroll: 1,
    swipeToSlide: true,
    infinite: product ? product.images.length > 2 : true,
    focusOnSelect: true,
    arrows: false,
  };

  const [isWishlisted, setIsWishlisted] = useState(false);

  const toggleWishlist = () => {
    if (isWishlisted) {
      removeToWishlist(product.id);
    } else {
      addToWishlist(product.id);
    }
  };

  useEffect(() => {
    if (wishlist && product) {
      setIsWishlisted(wishlist.products.includes(product.id));
    }

    // eslint-disable-next-line
  }, [wishlist]);

  const [mainSlider, setMainSlider] = useState(null);
  const [secondarySlider, setSecondarySlider] = useState(null);

  const description = product
    ? product.description
    : "<p>Failed to load description. Please refresh or contact us.</p>";

  // if(product && product.id) {
  //   console.log("product from cart", productFromCart);
  // }

  const productFromCart =
    product &&
    product.id &&
    accountType !== "Admin" &&
    cartItems &&
    cartItems.filter((cartItem) => cartItem.product.id === product.id);

  return isRetrieveLoading ? (
    <ModalLoader />
  ) : (
    <>
      {product && product ? (
        <div className="flex flex-col lg:flex-row space-2 w-full">
          <div
            className={`flex justify-center w-full md:w-full ${
              product.images.length > 1 ? "lg:w-1/2" : "lg:w-5/12"
            }`}
          >
            {product.images.length > 1 ? (
              <div className="w-4/12 flex flex-col pr-3 h-64">
                <div className="flex flex-col w-full h-full mt-4">
                  <Slider
                    asNavFor={mainSlider}
                    ref={(slider) => setSecondarySlider(slider)}
                    {...secondarySettings}
                  >
                    {product.images.map((img) => (
                      <div key={img.id} className="block">
                        <img
                          alt="product"
                          src={img.image}
                          className="w-full block h-32 object-cover"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            ) : null}
            <div
              className={`${
                product.images.length > 1 ? "w-8/12" : " w-full"
              } h-full xl:h-64`}
            >
              <Slider
                className="xl:h-68  mb-10 mt-4"
                asNavFor={secondarySlider}
                ref={(slider) => setMainSlider(slider)}
                {...mainSettings}
              >
                {product.images.map((img) => (
                  <div key={img.id} className="md:w-1/2 block overflow-hidden">
                    <img
                      alt="product"
                      src={img.image}
                      className="w-full block object-cover mx-auto"
                      style={{ height: "25rem" }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div
            className={`py-3 md:p-4 md:px-8 w-full md:w-full ${
              product.images.length > 1 ? "lg:w-1/2" : "lg:w-7/12"
            }`}
          >
            <h2 className="text-2xl lg:text-3xl font-semibold capitalize">
              {product.name}
              {isProductView || isAdminView ? null : (
                <Link
                  to={`product/${product.slug}`}
                  className="text-blue-400 hover:text-blue-500 cursor-pointer text-sm ml-2 duration-300"
                >
                  View Details
                </Link>
              )}
            </h2>
            <div className="flex items-center space-x-3 md:mb-3">
              <RatingsColored
                isSelectable={false}
                starDimension="1.2rem"
                name="rating"
                ratings={product.average_rating}
                // ratings={4}
              />
              {/* <div className="text-sm text-gray-600">( 41 Reviews )</div> */}
            </div>
            <div className="font-bold text-2xl">
              £{product.price.toFixed(2)}
              {/* <span className="text-semiBase line-through text-gray-600 ml-2">
              25.00
            </span> */}
            </div>
            <div className="text-gray-700 font-light text-sm my-2 border-b border-t py-4 border-dashed">
              {/* The plastic free basket goodies contains
              <ol className="list-none">
                <li>1 bathbomb</li>
                <li>1 christmas design body soap</li>
                <li>1 solid shampoo</li>
                <li>1 conditioner</li>
                <li>1 pouch bag</li>
              </ol>
              The basket is a perfect soap caddy! */}
              {/* STUB VARIANTS */}
              <div className="flex flex-wrap">
                {product &&
                  product.variants &&
                  product.variants.map((variant) => (
                    <Tags name={variant.name} />
                  ))}
              </div>
              <div className="dangerously-converted-html text-gray-700 my-8 font-light">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
            {isAdminView ? null : (
              <div className="flex items-center space-x-2 pb-2 border-b border-dashed">
                {product && isAuthenticated && accountType !== "Admin" ? (
                  <>
                    {productFromCart.map((cartItem) => {
                      return (
                        <QuantityInput
                          cartItemId={cartItem.id}
                          productId={product.id ? product.id : null}
                          quantity={cartItem.quantity}
                          loading={cartAddLoading}
                        />
                      );
                    })}
                  </>
                ) : null}
                <Button
                  handleClick={() => addToCart(product.id)}
                  color="bg-gray-800 hover:bg-gray-900"
                  inline
                >
                  <div className="text-white font-medium py-1 px-2 uppercase text-xs">
                    Add to Cart
                  </div>
                </Button>
                <div
                  onClick={() => toggleWishlist()}
                  className="flex items-center text-xs font-medium hover:text-red-500 duration-300 ease-in-out cursor-pointer"
                >
                  <Icon icon={bxHeart} className="w-4 h-4" />{" "}
                  {isWishlisted ? "Remove in" : "Add to"} Wishlist
                </div>
              </div>
            )}

            <div className="flex-col mt-8">
              <div className=""></div>
              <div className="flex my-2 text-sm">
                Category:
                <div className="ml-2 text-default-primary hover:text-green-500 transition duration-300">
                  {product.category.map((cat, i) => (
                    <span key={cat.id} className="">
                      {(i ? ", " : "") + cat.name}
                    </span>
                  ))}
                </div>
              </div>
              {/* <div className="border-b border-dashed" /> */}
              <div className="flex my-2 text-sm">
                Tags:{" "}
                <div className="flex flex-wrap ml-2">
                  {product.product_tag.map((tag, i) => (
                    <div
                      key={tag.id}
                      className="text-sm text-default-primary
                  "
                    >
                      {(i ? ", " : "") + tag.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-sm text-red-400">
          Product failed to load. Please refresh the page.
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  product: selectProductInProduct,
  cartAddLoading: selectCartIsAddLoading,
  cartItems: selectCartItems,
  isRetrieveLoading: selectIsProductRetrieveLoading,
  isAuthenticated: selectIsAuthenticated,
  wishlist: selectWishlistInWishlist,
  accountType: selectAccountType,
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  removeToWishlist,
})(QuickView);
