import React, { useState } from "react";

// icons
import { Icon } from "@iconify/react";
import bxLineChart from "@iconify/icons-bx/bx-line-chart";
import bxMinus from "@iconify/icons-bx/bx-minus";
// import bxsCog from '@iconify/icons-bx/bxs-cog';

const QuickStatus = ({ name, totalItem, outOfStock, todayItems, pendingItems, thisMonthItems, lastMonthItems, newTodayItems, newThisMonthItems }) => {
  const [hidden, setHidden] = useState(false);

  return (
    <div className="border w-full bg-white">
      <div
        className={`flex items-center justify-between p-2 ${
          hidden ? "" : "border-b"
        }`}
      >
        <div className="font-medium flex items-center">
          <Icon icon={bxLineChart} className="w-6 h-6 mr-1" />
          Quick Status
        </div>
        {hidden ? (
          <svg
            className="w-6 h-6 hover:text-default-primary transition duration-300 ease-in-out cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setHidden(!hidden)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        ) : (
          <Icon
            icon={bxMinus}
            onClick={() => setHidden(!hidden)}
            className="w-6 h-6 mr-1 hover:text-default-primary transition duration-300 ease-in-out cursor-pointer"
          />
        )}
      </div>
      {hidden ? null : (
        <>
          <div className="py-2 px-6 flex items-center justify-between">
            <div className="text-gray-600">All {name}</div>
            <div className="">{totalItem}</div>
          </div>
          {newTodayItems === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">New {name} Today</div>
              <div className="">{newTodayItems}</div>
            </div>
          )}
          {newThisMonthItems === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">New {name} This Month</div>
              <div className="">{newThisMonthItems}</div>
            </div>
          )}
          {todayItems === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">{name} Today</div>
              <div className="">{todayItems}</div>
            </div>
          )}
          {pendingItems === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">Pending {name}</div>
              <div className="">{pendingItems}</div>
            </div>
          )}
          {thisMonthItems === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">{name} This Month</div>
              <div className="">{thisMonthItems}</div>
            </div>
          )}
          {lastMonthItems === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">{name} Last Month</div>
              <div className="">{lastMonthItems}</div>
            </div>
          )}
          {outOfStock === undefined ? null : (
            <div className="py-2 px-6 flex items-center justify-between">
              <div className="text-gray-600">Out of Stock</div>
              <div className="">{outOfStock}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};


export default QuickStatus;
