import React, { useState } from "react";

// components
import Button from "components/common/buttons/Button";
import Ratings from "components/landing/product/Ratings";
import ButtonSpinner from "components/common/spinner/Spinner";
import { CustomTextareaField } from "components/common/form-input/FloatingTextarea";
import { ErrorMessageInField } from "components/common/form-input/FloatingFormInput";

// redux
import { connect } from "react-redux";
import { addReview } from "store/actions/review.action";

// form
import { useForm } from "react-hook-form";

// selectors
import { createStructuredSelector } from "reselect";
import { selectIsReviewFormLoading } from "store/selectors/review.selector";
import { selectProductInProduct } from "store/selectors/product.selector";
import { selectOrderID, selectOrderItemID } from "store/selectors/order.selector";
import { ModalLoader } from "components/common/spinner/Spinner";

const ReviewForm = ({ isFormLoading, addReview, productSlug, product, orderID, orderItemID }) => {
  const { register, handleSubmit, errors } = useForm();

  const [rating, setRating] = useState();

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    console.log(newRating);
  };

  const onSubmit = async (data, e) => {
    await addReview(product && product.id, orderID && orderID, orderItemID, rating, data.comment);
    setRating(0.0);
    e.target.reset();
  };
  // thank you very much for wonderful product! I really love how it looks on my skin. Will definitely order again. Super worth it!
  if (!orderID || !orderItemID || !product) {
    <ModalLoader />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0 w-full ">
        <div className="text-gray-600">
          How would you rate your purchase?
          <span className="text-red-400" title="Required">
            *
          </span>
        </div>
        <Ratings
          handleRatingChange={handleRatingChange}
          starDimension="2rem"
          name="rating"
          ratings={rating}
        />
        {/* STUB Comment */}
        <CustomTextareaField
          type="text"
          name="comment"
          inputRef={register({
            required: true,
          })}
          label="Please help us improve by sharing your experience or feedback of our product."
          aria-invalid={errors.comment ? true : false}
          isError={errors.comment}
        />
        <ErrorMessageInField>
          {errors.comment && errors.comment.type === "required" && (
            <p>Comment is required.</p>
          )}
        </ErrorMessageInField>

        <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
          <div className="uppercase text-white font-medium px-3 py-2 text-sm flex items-center">
            <span>Submit review</span>
            <span>
              {isFormLoading && (
                <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
              )}
            </span>
          </div>{" "}
        </Button>
      </form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isFormLoading: selectIsReviewFormLoading,
  product: selectProductInProduct,
  orderItemID: selectOrderItemID,
  orderID: selectOrderID,
});

export default connect(mapStateToProps, { addReview })(ReviewForm);
