import { createSelector } from 'reselect';

const selectCategory = state => state.categories;

export const selectCategoryInCategory = createSelector(
  [selectCategory],
  categories => categories.category
);

export const selectCategories = createSelector(
  [selectCategory],
  categories => categories.categories
);

export const selectIsCategoryRetrieveLoading = createSelector(
  [selectCategory],
  categories => categories.isRetrieveLoading
)

export const selectIsCategoryGetLoading = createSelector(
  [selectCategory],
  categories => categories.isGetLoading
)

export const selectIsCategoryFormLoading = createSelector(
  [selectCategory],
  categories => categories.isFormLoading
)

export const selectIsCategoryUpdateLoading = createSelector(
  [selectCategory],
  categories => categories.isUpdateLoading
)

export const selectIsCategoryUpdate = createSelector(
  [selectCategory],
  categories => categories.isUpdate
)

export const selectIsCategorySubmitSuccess = createSelector(
  [selectCategory],
  categories => categories.isSubmitSuccess
)
