import React from "react";

const OrderTotal = ({ total_price, shippingFee, isOnModal }) => {
  return (
    <div className="flex flex-col justify-center">
      {isOnModal ? (
        <>
          <div className="flex items-center justify-between">
            <span className="text-gray-600">Merchandise Subtotal</span>
            <span className="text-gray-600">£{total_price}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-gray-600">Shipping Fee</span>
            <span className="text-gray-600">£{shippingFee}</span>
          </div>
          <div className="flex items-center justify-between border-t border-dashed mt-2">
            <span className="text-gray-800 font-medium">Order Total</span>
            <span className="text-default-primary text-2xl font-medium">
              £{(parseFloat(total_price) + parseFloat(shippingFee)).toFixed(2)}
            </span>
          </div>
        </>
      ) : (
        <div className="text-sm mb-2 text-right">
          Order Total:{" "}
          <span className="text-default-primary font-medium text-2xl">
            £{(parseFloat(total_price) + parseFloat(shippingFee)).toFixed(2)}
          </span>
        </div>
      )}
    </div>
  );
};

export default OrderTotal;
