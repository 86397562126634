// types
import { SET_SIDEBAR_CART, SET_SIDEBAR_CLIENT, SET_SIDEBAR_FILTER } from "store/actions/actions.types";

export const setSidebarFilter = () => (dispatch) => {
  dispatch({
    type: SET_SIDEBAR_FILTER,
  });
};

export const setSidebarClient = () => (dispatch) => {
  dispatch({
    type: SET_SIDEBAR_CLIENT,
  });
};

export const setSidebarCart = () => (dispatch) => {
  dispatch({
    type: SET_SIDEBAR_CART,
  });
};
