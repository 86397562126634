import React, { useState } from "react";

// icons
import { Icon } from "@iconify/react";
import bxMinus from "@iconify/icons-bx/bx-minus";
// import bxsCog from '@iconify/icons-bx/bxs-cog';

const AdminFormContainer = ({ icon, title, children }) => {
  const [hidden, setHidden] = useState(false);
  return (
    <div className="border w-full mt-6">
      <div
        className={`flex items-center justify-between p-2 ${
          hidden ? "" : "border-b"
        }`}
      >
        <div className="font-medium flex items-center">
          <Icon icon={icon} className="w-6 h-6 mr-1" />
          {title}
        </div>
        {hidden ? (
          <svg
            className="w-6 h-6 hover:text-default-primary transition duration-300 ease-in-out cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setHidden(!hidden)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        ) : (
          <Icon
            icon={bxMinus}
            onClick={() => setHidden(!hidden)}
            className="w-6 h-6 mr-1 hover:text-default-primary transition duration-300 ease-in-out cursor-pointer"
          />
        )}
      </div>

      {hidden ? null : <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

export default AdminFormContainer;
