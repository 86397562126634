import React from "react";

// router
import { Redirect, Route } from "react-router-dom";

// layout
import DashboardLayout from "layouts/DashboardLayout";
import { connect } from "react-redux";

// reselect
import { createStructuredSelector } from "reselect";
import { selectAccountType, selectIsAuthenticated } from "store/selectors/auth.selector";

// import NotFoundPage from "pages/NotFoundPage";

const DashboardRoute = ({ component: Component, accountType, isAuthenticated, ...rest }) => {
  if(isAuthenticated && accountType === "Admin") {
    return (
      <Route
        {...rest}
        render={(props) => (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        )}
      />
    );
  } else {
    return <Redirect to="/" />
  }
};

const mapStateToProps = createStructuredSelector({
  accountType: selectAccountType,
  isAuthenticated: selectIsAuthenticated,
});

export default connect(mapStateToProps)(DashboardRoute);
