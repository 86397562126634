import React from "react";

// route
import { Link } from "react-router-dom";

const Button = ({
  color,
  inline,
  outlineBorder,
  outlineColor,
  uppercase,
  children,
  handleClick,
  size,
  ...props
}) => {
  const outlineStyles = `border-2 ${outlineColor}`;
  return (
    <div
    className={` ${outlineBorder ? outlineStyles : ""} 
    ${inline ? "inline-block" : "w-full"}
    `}
    // ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}
    
    >
      <button
        className={`transition duration-300 ease-in-out outline-none w-full ${size} ${color}
        ${props.disabled ? "cursor-not-allowed opacity-50 z-40" : "cursor-pointer"}
        ${
          uppercase ? "uppercase" : ""
        }  py-1 `}
        onClick={handleClick}
        {...props}
      >
        {children}
      </button>
    </div>
  );
};

export const LinkButton = ({
  color,
  inline,
  uppercase,
  children,
  outlineBorder,
  outlineColor,
  linkPath,
  size,
  myRef,
  ...props
}) => {
  const outlineStyles = `border-2 ${outlineColor}`;
  return (
    <div
      className={` ${outlineBorder ? outlineStyles : ""} 
      ${inline ? "inline-block" : "block w-full"}`}
    >
      <Link
        to={linkPath}
        ref={myRef}
        className={`transition duration-300 ease-in-out outline-none text-center block w-full font-semibold  ${
          uppercase ? "uppercase" : ""
        } ${size} ${color}`}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
};

export const TextEditorButton = ({ children, specialClass, handleClick, active, ...props}) => {
  return (
    <button className={`w-8 h-8 outline-none rounded-sm border duration-300 ${specialClass} ${active ? 'bg-gray-200 hover:bg-gray-300' : 'bg-transparent hover:bg-gray-200'}`} onClick={handleClick} {...props}>{children}</button>
  )
}


export default Button;
