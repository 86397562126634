import { toast } from "react-toastify";
import API from "store/api";
import {
  ADD_ORDER_START,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILED,
  GET_ORDERS_START,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILED,
  RETRIEVE_ORDER_START,
  RETRIEVE_ORDER_SUCCESS,
  RETRIEVE_ORDER_FAILED,
  SET_TO_SHIPPING_START,
  SET_TO_SHIPPING_SUCCESS,
  SET_TO_SHIPPING_FAILED,
  ORDER_RECEIVE_START,
  ORDER_RECEIVE_SUCCESS,
  ORDER_RECEIVE_FAILED,
  SET_ORDER_IS_RATED,
  RETRIEVE_ORDER_ITEM_START,
  RETRIEVE_ORDER_ITEM_FAILED,
  RETRIEVE_ORDER_ITEM_SUCCESS,
  ORDER_DELIVERED_START,
  ORDER_DELIVERED_SUCCESS,
  ORDER_DELIVERED_FAILED,
} from "./actions.types";
import { tokenConfig } from "./auth.action";

export const getOrders = (filter = null) => async (dispatch, getState) => {
  dispatch({ type: GET_ORDERS_START });

  let url = `/products/order/`;

  if (filter) {
    url = `/products/order/?filter=${filter}`;
  }

  await API.get(url, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_ORDERS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: GET_ORDERS_FAILED });
      const errorMsg = "".concat(
        "Getting Orders Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const addToOrder = (
  reference_code,
  total_price,
  status,
  cartItems,
  account,
  address
) => async (dispatch, getState) => {
  let isSuccess = false;
  dispatch({ type: ADD_ORDER_START });
  const body = JSON.stringify({
    reference_code: reference_code,
    total_price: total_price,
    status: status,
    order_items: cartItems.map((cartItem) => ({
      id: cartItem.id,
      product_input: cartItem.product.id,
      quantity: cartItem.quantity,
      total_price: cartItem.total_price,
    })),
    address,
  });

  await API.post(`/products/order/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_ORDER_SUCCESS, payload: res.data });
      toast.success("Order Sent", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(res.data);
      isSuccess = true;
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADD_ORDER_FAILED });
      const errorMsg = "".concat("Order Failed.\n", err.response.data.detail);
      toast.error(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      isSuccess = false;
    });

  return isSuccess;
};

export const retrieveOrder = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_ORDER_START });
  await API.get(`products/order/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_ORDER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: RETRIEVE_ORDER_FAILED });
      const errorMsg = "".concat(
        "Retrieving Order Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const retrieveOrderItem = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_ORDER_ITEM_START });
  await API.get(`products/order-item/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_ORDER_ITEM_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: RETRIEVE_ORDER_ITEM_FAILED });
      const errorMsg = "".concat(
        "Retrieving Order Item Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const setToShipping = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_TO_SHIPPING_START });
  const body = JSON.stringify({
    status: "shipping",
  });

  await API.patch(`products/order/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SET_TO_SHIPPING_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Order Set To Shipping!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: SET_TO_SHIPPING_FAILED });
      console.log(err.response.data);
      const errorMsg = "".concat(
        "Updating Order Status Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const setToReview = (id) => async (dispatch, getState) => {
  dispatch({ type: ORDER_RECEIVE_START });
  const body = JSON.stringify({
    status: "to_review",
    received_date: new Date(),
  });

  await API.patch(`products/order/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ORDER_RECEIVE_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Order Confirmed! Thank you for trusting us!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(id);
      // setTimeout(() => {
      //   dispatch(setCreateReviewModal(id));
      // }, 2200);
    })
    .catch((err) => {
      dispatch({ type: ORDER_RECEIVE_FAILED });
      console.log(err.response.data);
      const errorMsg = "".concat(
        "Receiving Order Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const setToDelivered = (id) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DELIVERED_START });
  const body = JSON.stringify({
    status: "delivered",
    is_all_rated: true,
  });

  await API.patch(`products/order/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ORDER_DELIVERED_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: ORDER_DELIVERED_FAILED });
      console.log(err);
    });
};

export const setOrderItemToIsRated = (orderID, id) => async (
  dispatch,
  getState
) => {
  const body = JSON.stringify({
    is_rated: true,
  });

  await API.patch(`products/order-item/${id}/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SET_ORDER_IS_RATED, payload: res.data });
      dispatch(setToDelivered(orderID));
    })
    .catch((err) => {
      console.log(err);
    });
};
