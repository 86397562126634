import { createSelector } from "reselect";

const selectReview = (state) => state.review;

export const selectReviewInReview = createSelector(
  [selectReview],
  (review) => review && review.review
);

export const selectReviews = createSelector(
  [selectReview],
  (review) => review && review.reviews
);

export const selectIsReviewFormLoading = createSelector(
  [selectReview],
  (review) => review && review.isFormLoading
);

export const selectIsReviewGetLoading = createSelector(
  [selectReview],
  (review) => review && review.isGetLoading
);

export const selectIsReviewDeleteLoading = createSelector(
  [selectReview],
  (review) => review && review.isDeleteLoading
);

export const selectIsReviewPublishingLoading = createSelector(
  [selectReview],
  (review) => review && review.isPublishLoading
);

export const selectHasMoreReviews = createSelector(
  [selectReview],
  (review) => review && review.hasMore
);

// export const selectIsInquiryRetrieveLoading = createSelector(
//   [selectInquiry],
//   inquiry => inquiry.isRetrieveLoading
// )

// export const selectIsInquiryGetLoading = createSelector(
//   [selectInquiry],
//   inquiry => inquiry.isGetLoading
// )

// export const selectIsInquiryFormLoading = createSelector(
//   [selectInquiry],
//   inquiry => inquiry.isFormLoading
// )
