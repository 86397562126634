import React from "react";

// component
import Ratings from "components/landing/product/Ratings";
import ReviewActions from "components/admin/dashboard/ReviewActions";
import defaultImage from "assets/common/empty-image.jpg";

// icons
import { Icon } from "@iconify/react";
import checkSquareFill from "@iconify/icons-bi/check-square-fill";
import xSquareFill from "@iconify/icons-bi/x-square-fill";

export const REVIEW_HEADERS = [
  {
    Header: "Name",
    accessor: "author.first_name",
    Cell: ({ row: { original } }) => {
      const full_name = "".concat(
        original.author.first_name,
        " ",
        original.author.last_name
      );

      return full_name;
    },
  },
  {
    Header: "Product",
    accessor: "product",
    Cell: ({ row: { original } }) => {
      const image =
        original.product.image && original.product.image.image
          ? original.product.image.image
          : defaultImage;

      return (
        <div className="flex flex-col">
          {" "}
          <img src={image} alt="preview" className="w-12 h-12 object-cover" />
          <div>{original.product.name}</div>
        </div>
      );
    },
  },
  {
    Header: "Ratings",
    accessor: "rating",
    Cell: ({ cell: { value } }) => (
      <div className="flex items-center space-x-2">
        <div className="w-24">
          <Ratings ratings={value} starDimension="1rem" isSelectable={false} />
        </div>
        <span className="w-12">( {value.toFixed(1)} )</span>
      </div>
    ),
  },
  {
    Header: "Comment",
    accessor: "comment",
  },
  {
    Header: "Published",
    accessor: "is_published",
    Cell: ({ cell: { value } }) => (
      <div className="text-center flex justify-center w-full">
        <Icon
          icon={value ? checkSquareFill : xSquareFill}
          className={`w-5 h-5 ${value ? "text-green-600" : "text-red-600"}`}
        />
      </div>
    ),
  },
  {
    Header: "Actions",
    accessor: "id",
    Cell: ({ row: { original } }) => (
      <ReviewActions id={original.id} isPublished={original.is_published} />
    ),
  },
];
