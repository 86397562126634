import { toast } from "react-toastify";
import API from "store/api";
import {
  ADD_CATEGORY_FAILED,
  ADD_CATEGORY_START,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILED,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  RETRIEVE_CATEGORY_FAILED,
  RETRIEVE_CATEGORY_START,
  RETRIEVE_CATEGORY_SUCCESS,
  SET_CATEGORY_UPDATE_FORM,
  UPDATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
} from "./actions.types";
import { tokenConfig } from "./auth.action";
import { setUpdateCategoryModal } from "./modal.action";

export const getCategories = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORIES_START });

  await API.get("/products/categories/")
    .then((res) => {
      dispatch({ type: GET_CATEGORIES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: GET_CATEGORIES_FAILED });
    //   const errorMsg = "".concat(
    //     "Getting Categories Failed.\n",
    //     err.response.data.detail
    //   );
    //   toast.error(errorMsg, {
    //     position: "top-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    });
};

export const addCategory = (name, description, categoryImage) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_CATEGORY_START });

  let categoryData = new FormData();
  categoryData.set("name", name);
  categoryData.set("description", description);

  if (categoryImage) {
    categoryData.append(
      "category_image",
      categoryImage[0],
      categoryImage[0].name
    );
  }

  API.post("/products/categories/", categoryData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_CATEGORY_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Adding Category Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: ADD_CATEGORY_FAILED });
      const errorMsg = "".concat(
        "Adding Category Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const deleteCategory = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_CATEGORY_START });

  API.delete(`/products/categories/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: id });
    })
    .then((res) => {
      // dispatch(setAlert('Deleting category successful!'));
      toast.success("Deleting Category Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: DELETE_CATEGORY_FAILED });
      const errorMsg = "".concat(
        "Deleting Category Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const retrieveCategory = (id) => async (dispatch) => {
  dispatch({ type: RETRIEVE_CATEGORY_START });

  await API.get(`products/categories/${id}`)
    .then((res) => {
      dispatch({ type: RETRIEVE_CATEGORY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: RETRIEVE_CATEGORY_FAILED });
      const errorMsg = "".concat(
        "Retrieving Category Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const updateCategory = (name, description, categoryImage, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_CATEGORY_START });

  let categoryData = new FormData();
  categoryData.set("name", name);
  categoryData.set("description", description);
  if (categoryImage[0].name) {
    categoryData.append(
      "category_image",
      categoryImage[0],
      categoryImage[0].name
    );
  }

  await API.patch(
    `products/categories/${id}/`,
    categoryData,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: res.data });
    })
    .then((res) => {
      dispatch(setUpdateCategoryModal(false));
    })
    .then((res) => {
      toast.success("Updating Category Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_CATEGORY_FAILED });
      const errorMsg = "".concat(
        "Updating Category Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const setCategoryUpdateForm = () => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_UPDATE_FORM,
  });
};
