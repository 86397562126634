import React from "react";

import { Icon } from "@iconify/react";
import spinner2 from "@iconify/icons-ei/spinner-2";

export const Spinner = ({ size, color }) => {
  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center">
        {/* <Backdrop show /> */}
        <div className={`w-20 h-20 animate-spin duration-1000 z-50`}>
          <Icon
            icon={spinner2}
            className={`text-default-primary w-full h-full`}
          />
        </div>
      </div>
    </>
  );
};
const ButtonSpinner = ({ size, color }) => {
  return (
    <div className={`${size} animate-spin duration-1000`}>
      <Icon icon={spinner2} className={`${color} w-full h-full`} />
    </div>
  );
};

export const BoxLoader = () => {
  return (
    <div className="flex flex-wrap items-center px-12 mb-12 justify-center animate-pulse">
      <div className="h-64 w-full md:w-1/3 lg:w-1/4 hidden xl:block ">
        <div className="my-2 mx-4 rounded-md h-full text-gray-300 bg-gray-300">
          loading{" "}
        </div>
      </div>
      <div className="h-64 w-full md:w-1/3 lg:w-1/4 hidden md:block  ">
        <div className="my-2 mx-4 rounded-md h-full text-gray-300 bg-gray-300">
          loading{" "}
        </div>
      </div>
      <div className="h-64 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 hidden sm:block ">
        <div className="my-2 mx-4 rounded-md h-full text-gray-300 bg-gray-300">
          loading
        </div>
      </div>
      <div className="h-64 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 ">
        <div className="my-2 mx-4 rounded-md h-full text-gray-300 bg-gray-300">
          loading{" "}
        </div>
      </div>
    </div>
  );
};

export const TableLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="h-10 w-64 rounded-md bg-gray-300 mb-4"></div>
      <div className="h-16 w-full rounded-md bg-gray-300 mb-3"></div>
      <div className="flex space-x-4">
        <div className="h-12 w-12 rounded-md bg-gray-300"></div>
        <div className="h-12 w-full rounded-md bg-gray-300 mb-3"></div>
      </div>
      <div className="flex space-x-4">
        <div className="h-12 w-12 rounded-md bg-gray-300"></div>
        <div className="h-12 w-full rounded-md bg-gray-300 mb-3"></div>
      </div>
      <div className="flex space-x-4">
        <div className="h-12 w-12 rounded-md bg-gray-300"></div>
        <div className="h-12 w-full rounded-md bg-gray-300 mb-3"></div>
      </div>
      <div className="flex space-x-4">
        <div className="h-12 w-12 rounded-md bg-gray-300"></div>
        <div className="h-12 w-full rounded-md bg-gray-300 mb-3"></div>
      </div>
      <div className="flex space-x-4">
        <div className="h-12 w-12 rounded-md bg-gray-300"></div>
        <div className="h-12 w-full rounded-md bg-gray-300 mb-3"></div>
      </div>
    </div>
  );
};

export const AdminMiniSidebarLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="w-full h-24 bg-gray-300 rounded-md mb-4"></div>
      <div className="w-full h-64 bg-gray-300 rounded-md mb-4"></div>
    </div>
  );
};

export const SmallHeadingAndBoxLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="w-full h-12 bg-gray-300 rounded-md mb-4"></div>
      <div className="w-full h-16 bg-gray-300 rounded-md mb-4"></div>
    </div>
  );
};

export const ShopLoader = ({ items }) => {
  return (
    <div className="animate-pulse w-full">
      <div className="flex flex-wrap">
        {[...Array(items)].map((i) => {
          return (
            <div
              key={i}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4 px-2"
            >
              <div className="w-full h-48 bg-gray-300 rounded-md mb-3"></div>
              <div className="w-full h-6 bg-gray-300 rounded-md mb-3"></div>
              <div className="w-3/4 h-6 bg-gray-300 rounded-md mb-3"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DropdownLoader = () => {
  return (
    <div className="animate-pulse">
      {[...Array(6)].map((i) => {
        return (
          <div
            key={i}
            className="w-full h-10 bg-gray-300 rounded-md mb-3"
          ></div>
        );
      })}
    </div>
  );
};

export const SmallHeadingAndMoreBoxesLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="w-2/4 h-6 bg-gray-300 rounded-md mb-4"></div>
      <div className="w-3/12 h-6 bg-gray-300 rounded-md mb-4"></div>
      <div className="w-full h-16 bg-gray-300 rounded-md mb-3"></div>
      <div className="w-full h-20 bg-gray-300 rounded-md mb-3"></div>
      <div className="flex justify-between mb-3">
        <div className="w-3/12 h-6 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-5/12 h-6 bg-gray-300 rounded-md mb-2"></div>
      </div>
      <div className="w-3/12 h-6 bg-gray-300 rounded-md mb-4"></div>
      <div className="w-full h-64 bg-gray-300 rounded-md mb-3"></div>
    </div>
  );
};

export const ShopTitleLoader = () => {
  return (
    <div className="animate-pulse">
      <div className="flex justify-between mb-3">
        <div className="w-4/12 h-10 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-2/12 h-10 bg-gray-300 rounded-md mb-2"></div>
      </div>
      <div className="flex space-x-2 mb-3">
        <div className="w-1/12 h-6 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-1/12 h-6 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-1/12 h-6 bg-gray-300 rounded-md mb-2"></div>
      </div>
    </div>
  );
};

export const ModalLoader = () => {
  return (
    <div className="animate-pulse my-3">
      <div className="w-4/12 h-6 bg-gray-300 rounded-md mb-2"></div>
      <div className="w-full h-12 bg-gray-300 rounded-md mb-2"></div>
      <div className="w-full h-16 bg-gray-300 rounded-md mb-2"></div>
      <div className="w-full h-16 bg-gray-300 rounded-md mb-2"></div>
      <div className="w-full h-12 bg-gray-300 rounded-md mb-2"></div>
      <div className="w-3/12 h-10 bg-gray-300 rounded-md mb-2"></div>
    </div>
  );
};

export const BoxRadioLoader = () => {
  return (
    <div className="animate-pulse">
      <div
        style={{ minWidth: "200px" }}
        className="w-full h-32 bg-gray-300 rounded-md mb-2"
      ></div>
    </div>
  );
};

export const SectionTwoLoader = () => {
  return (
    <div className="flex flex-col lg:flex-row lg:items-center my-20 px-3">
      <div className="flex flex-col lg:w-4/12 xl:w-3/12 lg:px-4 ">
        <div className="w-5/12 h-8 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-3/12 h-8 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-6/12 h-8 bg-gray-300 rounded-md mb-2"></div>
      </div>
      <div className="italic text-lg leading-10 lg:w-8/12 xl:w-9/12">
        <div className="w-full h-8 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-10/12 h-8 bg-gray-300 rounded-md mb-2"></div>
        <div className="w-8/12 h-8 bg-gray-300 rounded-md mb-2"></div>
      </div>
    </div>
  );
};

export const AdvertisementLoader = () => {
  return (
    <div>
      <div className="my-16">
        <div className="mx-auto mt-16 w-10/12 lg:w-8/12 xl:w-8/12 sm:w-9/12">
          <div className="w-8/12 mx-auto h-10 bg-gray-300 rounded-md mb-12"></div>
          <div className="flex flex-wrap mx-auto">
            <div className="w-full h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 px-3 mb-4">
              <div className="bg-gray-300 rounded-md w-full h-full"></div>
            </div>
            <div className="w-full h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 px-3 mb-4">
              <div className="bg-gray-300 rounded-md w-full h-full"></div>
            </div>
            <div className="w-full h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 px-3 mb-4">
              <div className="bg-gray-300 rounded-md w-full h-full"></div>
            </div>
            <div className="w-full h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 px-3 mb-4">
              <div className="bg-gray-300 rounded-md w-full h-full"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto w-full md:w-9/12 xl:w-8/12">
        <div class="flex flex-wrap mx-auto">
          {[...Array(4)].map((f) => {
            return (
              <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-3 mb-4">
                <div className="bg-gray-300 rounded-md w-4/12 h-6 mb-3"></div>
                <div className="flex flex-col">
                  {[...Array(4)].map((f) => {
                    return (
                      <div className="flex space-x-2 mb-4">
                        <div className="w-3/12 h-16 rounded-md bg-gray-300"></div>
                        <div className=" w-9/12 flex flex-col">
                          <div className="bg-gray-300 rounded-md w-full h-4 mb-2"></div>
                          <div className="bg-gray-300 rounded-md w-7/12 h-4 mb-2"></div>
                          <div className="bg-gray-300 rounded-md w-5/12 h-4 mb-2"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ButtonSpinner;
