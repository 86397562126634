import React, { useEffect } from "react";

// components
import { LinkButton } from "components/common/buttons/Button";
import CartItem from "components/landing/cart/CartItem";

// icons
import { InlineIcon } from "@iconify/react";
import bxsShoppingBag from "@iconify/icons-bx/bxs-shopping-bag";

import { connect } from "react-redux";
import { getCart } from "store/actions/cart.action";

// svg
import { ReactComponent as EmptyBag } from "assets/landing/empty_bag.svg";

// reselect
import { selectCartInCart, selectCartItemsCount } from "store/selectors/cart.selectors";
import { selectAccount } from "store/selectors/auth.selector";
import { createStructuredSelector } from "reselect";
import { getWishlist } from "store/actions/wishlist.action";

const CartSidebar = ({ onClose, cart, getCart, getWishlist, account, cartItemCount }) => {
  useEffect(() => {
    if (account) {
      getCart(account.cart);
      getWishlist(account.wishlist);
    }
  }, [getCart, getWishlist, account]);

  return (
    <>
      <div className="flex items-center justify-between border-b p-4">
        <div className="text-gray-800 font-medium flex items-center space-x-2">
          <InlineIcon icon={bxsShoppingBag} className="w-6 h-6 mr-2" />
          {cartItemCount} item
          {cart && cart.cart_items.length === 0 ? "" : "s"}
        </div>
        <div
          title="Close Cart"
          className="flex justify-end text-gray-400 cursor-pointer hover:text-red-400 duration-300 transition"
          onClick={onClose}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-col h-full overflow-y-auto">
        <div className="h-full">
          {cart && cart.cart_items.length > 0 ? (
            cart.cart_items.map((cartItem) => (
              <CartItem key={cartItem.id} cartItem={cartItem} />
            ))
          ) : (
            <div className="flex flex-col items-center justify-center px-4 mt-8 mb-4">
              <div className="w-64 h-64">
                <EmptyBag />
              </div>
              <div className="font-medium text-2xl text-gray-800 mt-4 mb-3">
                Your cart is empty.
              </div>
              <div className="text-light text-base text-gray-700 text-center px-12 mb-4">
                Looks like you haven't made your choice yet...
              </div>
              <LinkButton
                linkPath="/shop"
                color="bg-gray-800 hover:bg-gray-900 text-white"
              >
                <div className="py-2">Shop Now</div>
              </LinkButton>
              <div className="my-4 text-sm text-default-primary font-medium text-center">
                Check what we've got for you <br />{" "}
                <span className="text-gray-600 font-normal">
                  and get it swished!
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {cart && cart.cart_items.length > 0 ? (
        <div className="border-t">
          <div className="flex items-center justify-between p-4 font-medium text-lg">
            <div className="">Subtotal:</div>
            <div className="text-2xl">£ {cart && cart.total_price}</div>
          </div>
          <div className="flex flex-col items-center space-y-2 px-4 w-full mb-4">
              <LinkButton
                linkPath="/cart"
                color="text-gray-400 hover:text-gray-600"
                outlineBorder
                outlineColor="border-gray-400 hover:border-gray-600 duration-300"
              >
                <div className="py-2">View Cart</div>
              </LinkButton>
            <LinkButton
              linkPath="/checkout"
              color="bg-gray-800 hover:bg-gray-900 text-white"
            >
              <div className="py-2">Checkout</div>
            </LinkButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  cart: selectCartInCart,
  cartItemCount: selectCartItemsCount,
  account: selectAccount,
});

export default connect(mapStateToProps, { getCart, getWishlist })(CartSidebar);
