import React, { useState } from "react";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";

const PhoneNumberForm = ({ isOnModal }) => {
  const [formData, setFormData] = useState({
    phnoe_number: "",
    name: "Primary",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { name, phone_number } = formData;

  const form = (
    <form className="m-0 p-0 w-full ">
      <FloatingFormInput
        label="Type"
        type="text"
        name="name"
        value={name}
        formType="customerPhoneNumber"
        handleChange={handleChange}
      />
      <FloatingFormInput
        label="Phone Number"
        type="text"
        name="phone_number"
        value={phone_number}
        formType="customerPhoneNumber"
        handleChange={handleChange}
      />

      <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
        <div className="uppercase text-white font-medium px-3 py-2 text-sm">Save</div>{" "}
      </Button>
    </form>
  );

  return (
    <>
      {isOnModal ? (
        <> {form} </>
        ) : (
          
          <> {form} </>
      )}
    </>
  )
};

export default PhoneNumberForm;
