import React from 'react'

const OrderId = ({ reference_code, ordered_date }) => {
  return (
    <div className="flex flex-col ">
    <div className="rounded-md bg-gray-200 py-1 px-2 text-semiBase font-medium">
      Order{" "}
      <span 
        className="text-blue-400 hover:text-blue-500 duration-300 inline-block"
      >
        #{reference_code && reference_code}
      </span>{" "}
    </div>
    <div className="text-gray-700 text-xs mt-2 ml-2">
      Order Placed: {ordered_date && ordered_date}
    </div>
  </div>
  )
}

export default OrderId
