import { SET_ALERT, REMOVE_ALERT } from "store/actions/actions.types";

export const removeAlert = () => dispatch => {
  dispatch({ type: REMOVE_ALERT })
}

export const returnMessages = (msg, status, timeout = 5000) => (dispatch) => {
  let messageType;

  if (status >= 100 && status < 200) {
    messageType = "info";
  } else if (status >= 200 && status < 300) {
    messageType = "success";
  } else if (status >= 400 && status < 600) {
    messageType = "danger";
  } else {
    messageType = "info";
  }

  for (let [key, value] of Object.entries(msg)) {

    if (Array.isArray(value)) {
      value = value[0];
    }

    if(typeof value === 'object' && value !== null) {
      dispatch(returnMessages(value, status));
      return;
    }

    let fieldName;

    if (key === "non_field_errors") {
      fieldName = "";
    } else if (key.includes('_')) {
      fieldName = key.split('_').join(" ");
    } else {
      fieldName = `${key.charAt(0).toUpperCase() + key.slice(1)}:`;
    }
    console.log("ERROR MESSAGE:",`${fieldName} ${value} :::::: ${messageType}`);

    const errorMsg = {
      message:  `${fieldName} ${value}`,
      status: messageType
    }
    dispatch({
      type: SET_ALERT,
      payload: errorMsg
    })

    setTimeout(() => dispatch({ type: REMOVE_ALERT }), timeout);
  }
};
