import React from "react";

// icons
import { Icon } from "@iconify/react";
import bxsUserCircle from "@iconify/icons-bx/bxs-user-circle";
import bxShoppingBag from "@iconify/icons-bx/bx-shopping-bag";
import bxHeart from "@iconify/icons-bx/bx-heart";

// route
import { Link, NavLink, withRouter } from "react-router-dom";

// context
// import { ThemeContext } from "context/ThemeContext";

// redux
import { connect } from "react-redux";
import { logoutAccount } from "store/actions/auth.action";

// components
import NavItem from "./NavItem";
import DropdownItem from "components/common/navbar/DropdownItem";
import { DropdownItemTwo } from "./DropdownItem";
import ButtonSpinner from "../spinner/Spinner";

// images 
import Logo from 'assets/common/logo/logo-arc-no-domain.webp';

// reselect
import { createStructuredSelector } from "reselect";
import {
  selectCartIsLoading,
  selectCartItemsCount,
} from "store/selectors/cart.selectors";
import {
  selectAccount,
  selectAccountType,
  selectIsAuthenticated,
} from "store/selectors/auth.selector";
const Navbar = ({
  isAuthModalVisible,
  setAuthModal,
  isAuthenticated,
  account,
  isSidebarClientVisible,
  setSidebarClient,
  isSidebarCartVisible,
  setSidebarCart,
  children,
  logoutAccount,
  itemCount,
  isCartLoading,
  accountType,
  history,
}) => {
  // const { theme } = React.useContext(ThemeContext);

  const logoutClickedHandler = () => {
    logoutAccount();
    history.replace("/");
  };

  return (
    <nav className="nav h-16 z-30 bg-white shadow-new sticky top-0 transition duration-300 flex items-center">
      {/* SECTION HIDDEN ON SM AND DOWN */}
      <div
        className={`hidden md:flex w-10/12 lg:w-8/12 mx-auto items-center justify-between 
        `}
        // ${  openModal ? "" : "z-50"}
      >
        <ul className="navbar-nav max-w-full h-full flex flex-1 justify-between items-center">
          <div className="flex flex-1 items-center">
            {children}
            {isAuthenticated && account.account_type === "Admin" ? (
              <NavLink
                to="admin/dashboard"
                className={`bg-default-primary px-2 py-1 rounded-md text-sm mr-6 uppercase font-medium hover:text-white hover:bg-default-primaryShade transition duration-300 focus:text-default-primary text-white outline-none`}
                activeClassName={`text-default-primary`}
              >
                Dashboard
              </NavLink>
            ) : null}
          </div>
          {isAuthenticated ? (
            <>
              <NavItem
                name={
                  isAuthenticated && account
                    ? account.first_name || account.username
                    : null
                }
                linkPath="#"
                dropdownName="profile-link"
                hasDropdown
                icon={bxsUserCircle}
              >
                <div style={{ zIndex: "9999" }}>
                  <div
                    style={{ top: "2rem", left: "-1rem" }}
                    className="profile-dropdown absolute w-64 bg-white shadow-new p-2 rounded-md"
                  >
                    <DropdownItem linkPath="/dashboard">
                      Account Info
                    </DropdownItem>
                    <DropdownItem linkPath="/profile">My Account</DropdownItem>
                    <DropdownItem linkPath="/orders">My Orders</DropdownItem>
                    <DropdownItemTwo click={() => logoutClickedHandler()}>
                      Logout
                    </DropdownItemTwo>
                  </div>
                </div>
              </NavItem>
            </>
          ) : (
            <div
              className={`flex items-center border-r mr-3 cursor-pointer  transition duration-300 ease-in-out hover:text-default-primary`}
              onClick={() => setAuthModal(!isAuthModalVisible)}
            >
              <Icon className="w-8 h-8 mr-1" icon={bxsUserCircle} />
              <div className="hidden md:inline-block uppercase font-medium mr-3 text-sm">
                Login
              </div>
            </div>
          )}
        </ul>
        {accountType === "Admin" ? null : (
          <div
            className={`text-gray-800 border-r mr-3 pr-3  cursor-pointer hover:text-default-primary transform transition duration-300 hover:-translate-y-1`}
          >
            <Icon
              className="w-7 h-7"
              icon={bxHeart}
              onClick={() => history.push("/wishlist")}
            />
          </div>
        )}
        {accountType === "Admin" ? null : (
          <div
            className={`flex items-center text-gray-800 cursor-pointer hover:text-default-primary transform transition duration-300 hover:-translate-y-1`}
          >
            <Icon
              className="w-7 h-7"
              icon={bxShoppingBag}
              onClick={() => setSidebarCart(!isSidebarCartVisible)}
            />
            {accountType === "Admin" || !isAuthenticated ? null : (
              <div className="ml-2 font-bold">
                {isCartLoading ? (
                  <ButtonSpinner size="w-6 h-6" color="text-black" />
                ) : (
                  itemCount
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* SECTION hidden on md and up */}
      <div className="md:hidden md:w-10/12 w-full md:mx-auto flex items-center justify-between px-3 ">
        <div onClick={() => setSidebarClient(!isSidebarClientVisible)}>
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </div>
        <div className="flex justify-between items-center h-16 w-16">
          <Link to="/" className={`text-default-primary font-medium text-xl h-12 w-12 ml-2`}>
            <img src={Logo} alt="Bubbles and Cream" className="object-cover w-full h-full" />
          </Link>
        </div>
        {accountType === "Admin" ? (
          <div></div>
        ) : (
          <Icon
            className={`text-default-primary w-8 h-8`}
            icon={bxShoppingBag}
            onClick={() => {
              setSidebarCart(!isSidebarCartVisible);
            }}
          />
        )}
      </div>
    </nav>
  );
};

const mapStateToProps = createStructuredSelector({
  account: selectAccount,
  isAuthenticated: selectIsAuthenticated,
  itemCount: selectCartItemsCount,
  isCartLoading: selectCartIsLoading,
  accountType: selectAccountType,
});

export default connect(mapStateToProps, { logoutAccount })(withRouter(Navbar));
