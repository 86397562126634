import React from "react";

// icons
import { Icon } from "@iconify/react";
import xIcon from "@iconify/icons-bi/x";

const Error = ({ message, status, onClose }) => {
  return (
    <div
      className={`mb-2 flex items-center justify-between w-full text-white p-2 rounded-sm duration-300 ${
        status === "danger" ? "bg-red-400 hover:bg-red-500" : "bg-blue-400 hover:bg-blue-500"
      }`}
    >
      <div className="">
        {message}
      </div>
        <Icon
          icon={xIcon}
          className="hover:text-black duration-300 cursor-pointer"
          onClick={() => onClose()}
        />
    </div>
  );
};

export default Error;
