import API from "store/api";
import { toast } from "react-toastify";
import {
  GET_ACCOUNT_ADDRESSES_START,
  GET_ACCOUNT_ADDRESSES_SUCCESS,
  GET_ACCOUNT_ADDRESSES_FAILED,
  ADD_ACCOUNT_ADDRESS_START,
  ADD_ACCOUNT_ADDRESS_SUCCESS,
  ADD_ACCOUNT_ADDRESS_FAILED,
  DELETE_ACCOUNT_ADDRESS_START,
  DELETE_ACCOUNT_ADDRESS_FAILED,
  DELETE_ACCOUNT_ADDRESS_SUCCESS,
  RETRIEVE_ACCOUNT_ADDRESS_START,
  RETRIEVE_ACCOUNT_ADDRESS_SUCCESS,
  RETRIEVE_ACCOUNT_ADDRESS_FAILED,
  SET_ACCOUNT_ADDRESS_UPDATE_FORM,
  UPDATE_ACCOUNT_ADDRESS_START,
  UPDATE_ACCOUNT_ADDRESS_SUCCESS,
  UPDATE_ACCOUNT_ADDRESS_FAILED,
} from "store/actions/actions.types";

import { tokenConfig } from "./auth.action";
import { setUpdateAccountAddressModal } from "./modal.action";

export const getAddresses = () => async (dispatch, getState) => {
  dispatch({ type: GET_ACCOUNT_ADDRESSES_START });

  await API.get("/accounts/addresses", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_ACCOUNT_ADDRESSES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: GET_ACCOUNT_ADDRESSES_FAILED });
    });
};

export const addAddress = (
  accountId,
  fullName,
  phoneNumber,
  detailedAddress,
  shippingFee,
  isPrimary
) => async (dispatch, getState) => {
  dispatch({ type: ADD_ACCOUNT_ADDRESS_START });

  let addressData = new FormData();
  addressData.set("full_name", fullName);
  addressData.set("phone_number", phoneNumber);
  addressData.set("detailed_address", detailedAddress);
  addressData.set("shipping_fee", shippingFee);
  addressData.set("is_primary", isPrimary);
  addressData.set("account", accountId);

  console.log("shippingFee", shippingFee);
  console.log("detailedAddress", detailedAddress);

  API.post("/accounts/addresses/", addressData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_ACCOUNT_ADDRESS_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Adding Address Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: ADD_ACCOUNT_ADDRESS_FAILED });
      console.log(err.response.data);
    });
};

export const deleteAddress = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_ACCOUNT_ADDRESS_START });

  API.delete(`/accounts/addresses/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_ACCOUNT_ADDRESS_SUCCESS, payload: id });
    })
    .then((res) => {
      toast.success("Deleting Address Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_ACCOUNT_ADDRESS_FAILED });
      console.log(err.response.data);
    });
};

export const retrieveAddress = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_ACCOUNT_ADDRESS_START });

  await API.get(`accounts/addresses/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_ACCOUNT_ADDRESS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: RETRIEVE_ACCOUNT_ADDRESS_FAILED });
    });
};

export const updateAccountAddress = (
  account_id,
  full_name,
  phone_number,
  detailed_address,
  shippingFee,
  is_primary,
  id
) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ACCOUNT_ADDRESS_START });

  let addressData = new FormData();
  addressData.set("full_name", full_name);
  addressData.set("phone_number", phone_number);
  addressData.set("detailed_address", detailed_address);
  addressData.set("is_primary", is_primary);
  addressData.set("account", account_id);
  addressData.set("shipping_fee", parseFloat(shippingFee));

  await API.patch(
    `accounts/addresses/${id}/`,
    addressData,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: UPDATE_ACCOUNT_ADDRESS_SUCCESS, payload: res.data });
    })
    .then((res) => {
      dispatch(setUpdateAccountAddressModal(false));
    })
    .then((res) => {
      toast.success("Updating Address Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_ACCOUNT_ADDRESS_FAILED });
    });
};

export const updatePrimaryAddress = (account_id, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_ACCOUNT_ADDRESS_START });

  let addressData = new FormData();
  addressData.set("is_primary", true);
  addressData.set("account", account_id);

  await API.patch(
    `accounts/addresses/${id}/`,
    addressData,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: UPDATE_ACCOUNT_ADDRESS_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Updating Address Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_ACCOUNT_ADDRESS_FAILED });
    });
};

export const setAccountAddressUpdateForm = () => (dispatch) => {
  dispatch({
    type: SET_ACCOUNT_ADDRESS_UPDATE_FORM,
  });
};
