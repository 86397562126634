import React from "react";

// context
// import { ThemeContext } from "context/ThemeContext";

// redux
import { connect } from "react-redux";
import { deleteProductImage } from "store/actions/product.action";

// icon
import { Icon } from "@iconify/react";
import bxsCloudUpload from "@iconify/icons-bx/bxs-cloud-upload";
import bxTrashAlt from "@iconify/icons-bx/bx-trash-alt";

// dropzone
import { useDropzone } from "react-dropzone";
import Button from "../buttons/Button";

const DraggableImageUploadField = ({
  isMulti,
  image,
  formData,
  setFormData,
  deleteProductImage,
}) => {
  // const { theme } = React.useContext(ThemeContext);

  const onDrop = (acceptedFiles) => {
    const allFiles = [...image, ...acceptedFiles]; //save all files here

    setFormData({ ...formData, images: allFiles });

    acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    console.log(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: isMulti,
  });

  const removeFile = (file) => {
    const newFiles = [...image];

    if (file.hasOwnProperty("id")) {
      deleteProductImage(file.id);
    }

    newFiles.splice(image.indexOf(file), 1);
    setFormData({ ...formData, images: newFiles });
  };

  const removeAll = () => {
    // setImages([]);
    setFormData({ ...formData, images: [] });
  };

  const files = image
    ? image.map((file, i) => {
        return (
          <div key={i} className="flex space-x-3 items-center my-2">
            <div key={file.preview} className="w-12 h-12">
              <img
                src={file.preview || file.image || file}
                className="w-full h-full object-cover border rounded-sm"
                alt="preview"
              />
            </div>
            {/* {isMulti ? (
              <div>
                <ToggleRadioButton
                  name="is_featured"
                  keyId={file.preview}
                  value={toggleValue}
                  handleToggleChange={handleToggleChange}
                  label="Cover"
                />
              </div>
            ) : null} */}
            <div>
              <Button
                handleClick={() => removeFile(file)}
                color="bg-red-600 hover:bg-red-700 text-white"
                inline
                size="px-2 rounded-sm text-xs"
                type="button"
              >
                <div className="flex items-center">
                  <span className="">
                    <Icon icon={bxTrashAlt} className="w-4 h-4" />
                  </span>
                  <span className="">Delete</span>
                </div>
              </Button>
            </div>
          </div>
        );
      })
    : null;

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div
          className={`outline-none h-32 p-4 border-dashed border-2 flex flex-col items-center justify-center duration-300 ease-in-out hover:bg-default-primaryTint  hover:border-default-primary cursor-pointer`}
        >
          <div>
            <Icon icon={bxsCloudUpload} className="w-12 h-12 text-gray-400" />
          </div>
          <div className="font-medium text-gray-800 text-sm px-4 text-center">
            {isDragActive ? (
              <>
                <span className={`text-default-primary`}>Drop</span> your image
                here
              </>
            ) : (
              <>
                <span className={`text-default-primary`}>Drag 'n' Drop</span>{" "}
                your image here, or click to select images
              </>
            )}
          </div>
        </div>
      </div>
      <div></div>
      <div className="flex flex-col justify-center">{files}</div>
      {isMulti ? (
        <>
          {files && files.length > 0 && (
            <button onClick={removeAll}>Remove All</button>
          )}
        </>
      ) : null}
    </>
  );
};

export default connect(null, { deleteProductImage })(DraggableImageUploadField);
