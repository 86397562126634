import { createSelector } from 'reselect';

const selectTestimonial = state => state.testimonial;

export const selectTestimonialInTestimonial = createSelector(
  [selectTestimonial],
  testimonial => testimonial && testimonial.testimonial
);

export const selectTestimonialIsUpdate = createSelector(
  [selectTestimonial],
  testimonial => testimonial && testimonial.isUpdate
)

export const selectTestimonialIsSubmitSuccess = createSelector(
  [selectTestimonial],
  testimonial => testimonial && testimonial.isSubmitSuccess
)

export const selectIsTestimonialUpdateLoading = createSelector(
  [selectTestimonial],
  testimonial => testimonial && testimonial.isUpdateLoading
)

export const selectIsTestimonialRetrieveLoading = createSelector(
  [selectTestimonial],
  testimonial => testimonial && testimonial.isRetrieveLoading
)

export const selectIsTestimonialFormLoading = createSelector(
  [selectTestimonial],
  testimonial => testimonial && testimonial.isFormLoading
)