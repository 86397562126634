import {
  ADD_ACCOUNT_ADDRESS_FAILED,
  ADD_ACCOUNT_ADDRESS_START,
  ADD_ACCOUNT_ADDRESS_SUCCESS,
  DELETE_ACCOUNT_ADDRESS_FAILED,
  DELETE_ACCOUNT_ADDRESS_START,
  DELETE_ACCOUNT_ADDRESS_SUCCESS,
  GET_ACCOUNT_ADDRESSES_FAILED,
  GET_ACCOUNT_ADDRESSES_START,
  GET_ACCOUNT_ADDRESSES_SUCCESS,
  RETRIEVE_ACCOUNT_ADDRESS_FAILED,
  RETRIEVE_ACCOUNT_ADDRESS_START,
  RETRIEVE_ACCOUNT_ADDRESS_SUCCESS,
  SET_ACCOUNT_ADDRESS_UPDATE_FORM,
  UPDATE_ACCOUNT_ADDRESS_FAILED,
  UPDATE_ACCOUNT_ADDRESS_START,
  UPDATE_ACCOUNT_ADDRESS_SUCCESS,
} from "store/actions/actions.types";

const initialState = {
  addresses: [],
  address: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false,
  isUpdateLoading: false,
  isRetrieveLoading: false,
  isUpdate: false,
};

export default function address(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ACCOUNT_ADDRESS_UPDATE_FORM:
      return {
        ...state,
        isUpdate: true,
      };
    case GET_ACCOUNT_ADDRESSES_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case RETRIEVE_ACCOUNT_ADDRESS_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case ADD_ACCOUNT_ADDRESS_START:
      return {
        ...state,
        isFormLoading: true,
      };
    case DELETE_ACCOUNT_ADDRESS_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case UPDATE_ACCOUNT_ADDRESS_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case ADD_ACCOUNT_ADDRESS_FAILED:
    case GET_ACCOUNT_ADDRESSES_FAILED:
    case RETRIEVE_ACCOUNT_ADDRESS_FAILED:
    case DELETE_ACCOUNT_ADDRESS_FAILED:
    case UPDATE_ACCOUNT_ADDRESS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isFormLoading: false,
        isDeleteLoading: false,
        isRetrieveLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
      };
    case ADD_ACCOUNT_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses:
          payload.is_primary === true
            ? [payload, ...state.addresses]
            : [...state.addresses, payload],
        isFormLoading: false,
      };
    case GET_ACCOUNT_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: [...payload],
        isGetLoading: false,
      };
    case DELETE_ACCOUNT_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.filter((address) => address.id !== payload),
        isDeleteLoading: false,
      };
    case RETRIEVE_ACCOUNT_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: payload,
        isRetrieveLoading: false,
      };
    }
    case UPDATE_ACCOUNT_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.map((address) =>
          address.id === payload.id ? { ...payload } : address
        ),
        isUpdate: false,
        isUpdateLoading: false,
      };
    default:
      return state;
  }
}
