import API from "store/api";
import { toast } from "react-toastify";
import { tokenConfig } from "./auth.action";
import {
  RETRIEVE_INQUIRY_FAILED,
  RETRIEVE_INQUIRY_START,
  RETRIEVE_INQUIRY_SUCCESS,
  GET_REVIEWS_START,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILED,
  ADD_REVIEW_START,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_FAILED,
  PUBLISH_REVIEW_START,
  PUBLISH_REVIEW_SUCCESS,
  PUBLISH_REVIEW_FAILED,
  DELETE_REVIEW_START,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILED,
  CLEAR_REVIEWS,
} from "./actions.types";
import { setCreateReviewModal } from "./modal.action";
import { setOrderItemToIsRated } from "./order.action";

// add review
export const addReview = (productID, orderID, orderItemID, rating, comment) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_REVIEW_START });

  let reviewData = new FormData();
  reviewData.set("product_input", productID);
  reviewData.set("rating", rating);
  reviewData.set("comment", comment);

  console.log("orderID",orderItemID);

  API.post("/products/review/", reviewData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_REVIEW_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Your review has been sent. Thank you!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setOrderItemToIsRated(orderID, orderItemID));
      dispatch(setCreateReviewModal(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADD_REVIEW_FAILED });
      toast.error("Sending Review Failed. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// get reviews
export const getReviews = (product, page = 1) => async (dispatch, getState) => {
  if (page === 1) {
    dispatch(clearReviews());
  }

  dispatch({ type: GET_REVIEWS_START });

  let url = `products/review/`;
  if (product) {
    url = `products/review/?product=${product}&page=${page}`;
  }

  let hasMore = false;

  await API.get(url, tokenConfig(getState))
    .then((res) => {
      const result = res.data;
      if (result.next) {
        hasMore = true;
      }

      dispatch({
        type: GET_REVIEWS_SUCCESS,
        payload: result.results,
        hasMore: hasMore,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_REVIEWS_FAILED });
      toast.error(err, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// retrieve inquiry
export const retrieveInquiry = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_INQUIRY_START });

  await API.get(`site/inquiries/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_INQUIRY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: RETRIEVE_INQUIRY_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// delete inquiry
export const deleteReview = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_REVIEW_START });

  API.delete(`/products/review/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_REVIEW_SUCCESS, payload: id });
    })
    .then((res) => {
      toast.success("Deleting Review Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_REVIEW_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(err.response.data);
    });
};

// publish review
export const publishReview = (isPublished, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: PUBLISH_REVIEW_START });

  let reviewData = new FormData();
  reviewData.set("is_published", isPublished);

  console.log(isPublished);

  API.patch(`/products/review/${id}/`, reviewData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: PUBLISH_REVIEW_SUCCESS, payload: res.data });
    })
    .then((res) => {
      if (!isPublished) {
        toast.success("Review has been unpublished!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Review has been published!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: PUBLISH_REVIEW_FAILED });
      if (!isPublished) {
        toast.error("Unpublishing review failed.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Publishing review failed.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
};

export const clearReviews = (page) => (dispatch) => {
  dispatch({ type: CLEAR_REVIEWS });

  // return new Promise((resolve, reject) => {
  //   if (page === 1) {
  //     resolve();
  //   } else {
  //     reject("Error");
  //   }
  // });
};
