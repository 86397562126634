import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { login } from "store/actions/auth.action";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";

// icons
// import { Icon } from "@iconify/react";
// import bxlFacebook from "@iconify/icons-bx/bxl-facebook";
// import bxlGooglePlus from "@iconify/icons-bx/bxl-google-plus";
import ButtonSpinner from "components/common/spinner/Spinner";
import Checkbox from "components/common/form-input/Checkbox";
import Error from "components/common/alerts/Error";

// route
import { withRouter } from "react-router-dom";

// context
// import { ThemeContext } from "context/ThemeContext";

// reselect
import { selectAuthLoading } from "store/selectors/auth.selector";
import { createStructuredSelector } from "reselect";
import { selectAlertInAlert } from "store/selectors/alerts.selector";
import { removeAlert } from "store/actions/alerts.action";

const LoginForm = ({ setLogin, isLoading, login, errors, removeAlert, history }) => {
  // const { theme } = React.useContext(ThemeContext);
  const [account, setAccount] = useState({
    email: "",
    password: "",
  });
  const [rememberAccount, setRememberAccount] = useState(true);
  const { email, password } = account;
  const [hasError, setHasError] = useState(false);

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);

    localStorage.setItem("rememberMe", rememberAccount);
    localStorage.setItem("email", rememberAccount ? email : "");
    localStorage.setItem("password", rememberAccount ? password : "");

    removeAlert();
  };

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = rememberMe ? localStorage.getItem("email") : "";
    const password = rememberMe ? localStorage.getItem("password") : "";
    setAccount({ email, password });
    if (errors) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [errors]);

  return (
    <>
      {hasError ? (
        <>
          {errors && 
            errors.map((error, i) =>
              <Error
                key={i}
                message={error.message}
                status={error.status}
                onClose={() => setHasError(false)}
              />
          )}
        </>
      ) : null}
      <form onSubmit={(e) => handleSubmit(e)} className="m-0 p-0">
        <FloatingFormInput
          label="Email"
          type="email"
          name="email"
          value={email}
          formType="customerLogin"
          handleChange={handleChange}
        />
        <div className="relative">
          <FloatingFormInput
            label="Password"
            type="password"
            name="password"
            value={password}
            formType="customerLogin"
            handleChange={handleChange}
          />
          <div
            // onClick={() => setPasswordShow(!passwordShow)}
            className=" uppercase text-sm cursor-pointer absolute mt-3 mr-3 top-0 right-0"
          >
            <div
              className={`text-default-primary font-light hover:text-default-primaryShade duration-300 ease-in-out`}
              onClick={() => history.push("/forgot-password")}
            >
              Forgot ?
            </div>
          </div>
        </div>
        <div className="mt-2 mb-3">
          <Checkbox
            textLabel="Remember Me"
            handleChange={() => setRememberAccount(!rememberAccount)}
            checked={rememberAccount}
            formType="rememberLogin"
            id="rememberMe"
          />
        </div>

        <Button
          type="submit"
          color={`bg-default-primary hover:bg-default-primaryShade`}
        >
          <div className="uppercase text-white py-1 font-medium flex items-center justify-center">
            Log in{" "}
            {isLoading && (
              <ButtonSpinner size="w-6 h-6 ml-1" color="text-white" />
            )}
          </div>{" "}
        </Button>

        <div className="text-gray-600 mt-4">
          Don't have an account?{" "}
          <div
            className={`text-default-primary text-default-primaryShade duration-300 inline-block cursor-pointer`}
            onClick={() => {
              setLogin(false);
            }}
          >
            Sign up here
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectAuthLoading,
  errors: selectAlertInAlert,
});

export default connect(mapStateToProps, { login, removeAlert })(withRouter(LoginForm));
