import React from "react";

// backdrop
import Backdrop from "../modal/Backdrop";

const Sidebar = ({
  title,
  isVisible,
  onClose,
  widthStyles,
  loading,
  position,
  children,
  visibilityStyles
}) => {
  return (
    <>
      <Backdrop show={isVisible} clicked={() => onClose()} />
      <aside
        className={`h-full ${visibilityStyles} transform top-0 ${position === "left" ? "left-0" : "right-0"} w-64 bg-white fixed  overflow-auto ease-in-out transition-all duration-300 z-50
        ${widthStyles}
        ${isVisible ? `${position === "left" ? "" : "-"}translate-x-0` : `${position === "left" ? "-" : ""}translate-x-full`}
        `}
        
      >
        {loading ? <div>Loading...</div> : children}
      </aside>
    </>
  );
};

export default Sidebar;
