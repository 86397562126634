import React, { useEffect } from "react";

// components
// import BreadCrumbs from "components/common/BreadCrumbs";
import { AdminTitle } from "components/common/titles/AdminTitle";
import Table from "components/common/table/Table";
import QuickStatus from "components/admin/quick-status/QuickStatus";
import { TableLoader } from "components/common/spinner/Spinner";
import { AdminMiniSidebarLoader } from "components/common/spinner/Spinner";

// data
import { REVIEW_HEADERS } from "data/reviews";


// redux
import { connect } from "react-redux";
import { setProductModal } from "store/actions/modal.action";
import { getReviews } from "store/actions/review.action";

// reselect
import { createStructuredSelector } from "reselect";
import { selectReviews, selectIsReviewGetLoading } from "store/selectors/review.selector";


// context
// import { ThemeContext } from "context/ThemeContext";

const ReviewsByProduct = ({
  getReviews,
  reviews,
  isGetLoading,
  match,
}) => {
  // const { theme } = React.useContext(ThemeContext);

  const {
    params: { slug },
  } = match;

  useEffect(() => {
    getReviews(slug);
  }, [getReviews, slug, ]);

  const columns = React.useMemo(() => REVIEW_HEADERS, []);
  const data = reviews && reviews;

  return (
    <>
      <div className="sticky top-0 sm:top-1/20 pt-8 z-10 w-full bg-gray-1100">
        <div className="flex items-end justify-between">
          <div className="">
            {/* <BreadCrumbs /> */}
            <div className="flex items-center space-x-2">
              <AdminTitle title="Reviews" />
            </div>
          </div>
        </div>
        <hr className="my-3" />
      </div>

      <div className="flex sm:mt-2">
        <div className="w-full overflow-auto lg:w-8/12 pr-4">
          {isGetLoading ? (
            <TableLoader />
          ) : (
            <Table columns={columns} data={data} />
          )}
        </div>
        <div className="w-4/12 px-4 hidden lg:block ">
          <div className="sticky top-1/5">
            {isGetLoading ? (
              <AdminMiniSidebarLoader />
            ) : (
              <>
                <QuickStatus
                  name="Reviews"
                  totalItem={reviews.length}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  reviews: selectReviews,
  isGetLoading: selectIsReviewGetLoading,
});

export default connect(mapStateToProps, {
  setProductModal,
  getReviews,
})(ReviewsByProduct);
