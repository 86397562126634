import { createSelector } from 'reselect';

const selectLanding = state => state.landing;

export const selectLandingInLanding = createSelector(
  [selectLanding],
  landing => landing && landing.landing
);

export const selectFooter = createSelector(
  [selectLandingInLanding],
  landing => landing && landing.footer_about
);