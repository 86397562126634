import React, { useEffect, lazy, Suspense } from "react";

// other css
// carousel
import "react-slideshow-image/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// text editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
// vertical timeline
import "react-vertical-timeline-component/style.min.css";

// router
import { BrowserRouter as Router, Switch } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { loadAccount } from "store/actions/auth.action";

// SECTION routes
import CustomerRoute from "routes/CustomerRoute";
import DashboardRoute from "routes/DashboardRoute";

// common
import NotFoundPage from "pages/NotFoundPage";

// context
import ThemeContext from "context/ThemeContext";

// utilities
import ScrollToTop from "utilities/scrollToTop";
import { Spinner } from "components/common/spinner/Spinner";
import ReviewsByProduct from "pages/admin/ReviewsByProduct";
import CustomerPrivateRoute from "routes/CustomerPrivateRoute";


// SECTION pages
// customer
const Landing = lazy(() => import("pages/customer/Landing"));
const Shop = lazy(() => import("pages/customer/Shop"));
const About = lazy(() => import("pages/customer/About"));
const Contact = lazy(() => import("pages/customer/Contact"));
const FAQS = lazy(() => import("pages/customer/FAQS"));
const ProductView = lazy(() => import("pages/customer/ProductView"));
const Checkout = lazy(() => import("pages/customer/Checkout"));
const Cart = lazy(() => import("pages/customer/Cart"));
const Wishlist = lazy(() => import("pages/customer/Wishlist"));
const ForgotPassword = lazy(() => import("pages/customer/ForgotPassword"));
const AccountInfo = lazy(() => import("pages/customer/AccountInfo"));
const MyAccount = lazy(() => import("pages/customer/MyAccount"));
const MyAddresses = lazy(() => import("pages/customer/MyAddresses"));
const MyPhoneNumbers = lazy(() => import("pages/customer/MyPhoneNumbers"));
const MyOrders = lazy(() => import("pages/customer/MyOrders"));
const OrderConfirmation = lazy(() =>
import("pages/customer/OrderConfirmation")
);
const OrderThankYou = lazy(() => import("pages/customer/OrderThankYou"));
const PrivacyPolicy = lazy(() => import("pages/customer/PrivacyPolicy"));
const TermAndConditions = lazy(() =>
import("pages/customer/TermAndConditions")
);
const CookiePolicy = lazy(() => import("pages/customer/CookiePolicy"));
const TestimonialPage = lazy(() => import("pages/customer/TestimonialPage"));

// admin
const Dashboard = lazy(() => import("pages/admin/Dashboard"));
const CategoryManager = lazy(() => import("pages/admin/CategoryManager"));
const ProductManager = lazy(() => import("pages/admin/ProductManager"));
const OrderManager = lazy(() => import("pages/admin/OrderManager"));
const CustomerManager = lazy(() => import("pages/admin/CustomerManager"));
const InquiriesManager = lazy(() => import("pages/admin/InquiriesManager"));
const ReportsManager = lazy(() => import("pages/admin/ReportsManager"));
const Settings = lazy(() => import("pages/admin/Settings"));
const SiteSettings = lazy(() => import("pages/admin/SiteSettings"));
const PageSettings = lazy(() => import("pages/admin/PageSettings"));
const ReviewsManager = lazy(() => import("pages/admin/ReviewsManager"));

function App({ loadAccount }) {
  useEffect(() => {
    loadAccount();
  });

  return (
    <ThemeContext>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <CustomerRoute exact path="/shop" component={Shop} />
            <CustomerRoute exact path="/shop/category/:id" component={Shop} />
            <CustomerRoute exact path="/about" component={About} />
            <CustomerRoute exact path="/contact" component={Contact} />
            <CustomerRoute exact path="/faqs" component={FAQS} />
            <CustomerRoute
              exact
              path="/product/:slug"
              component={ProductView}
            />
            <CustomerRoute exact path="/cart" component={Cart} />
            <CustomerPrivateRoute exact path="/checkout" component={Checkout} />
            <CustomerRoute exact path="/wishlist" component={Wishlist} />
            <CustomerRoute
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <CustomerRoute
              exact
              path="/terms-and-conditions"
              component={TermAndConditions}
            />
            <CustomerRoute
              exact
              path="/cookie-policy"
              component={CookiePolicy}
            />
            <CustomerRoute
              exact
              path="/testimonials"
              component={TestimonialPage}
            />
            <CustomerRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <CustomerPrivateRoute exact path="/dashboard" component={AccountInfo} />
            <CustomerPrivateRoute exact path="/profile" component={MyAccount} />
            <CustomerPrivateRoute exact path="/addresses" component={MyAddresses} />
            <CustomerPrivateRoute
              exact
              path="/phone-numbers"
              component={MyPhoneNumbers}
            />
            <CustomerPrivateRoute exact path="/orders" component={MyOrders} />
            <CustomerPrivateRoute
              exact
              path="/order-confirm"
              component={OrderConfirmation}
            />
            <CustomerPrivateRoute
              exact
              path="/order-success"
              component={OrderThankYou}
            />

            {/* admin */}
            <DashboardRoute
              exact
              path="/admin/dashboard"
              component={Dashboard}
            />
            <DashboardRoute
              exact
              path="/admin/categories"
              component={CategoryManager}
            />
            <DashboardRoute
              exact
              path="/admin/products"
              component={ProductManager}
            />
            <DashboardRoute
              exact
              path="/admin/orders"
              component={OrderManager}
            />
            <DashboardRoute
              exact
              path="/admin/customers"
              component={CustomerManager}
            />
            <DashboardRoute
              exact
              path="/admin/inquiries"
              component={InquiriesManager}
            />
            <DashboardRoute
              exact
              path="/admin/reports"
              component={ReportsManager}
            />
            <DashboardRoute exact path="/admin/settings" component={Settings} />
            <DashboardRoute
              exact
              path="/admin/site-settings"
              component={SiteSettings}
            />
            <DashboardRoute
              exact
              path="/admin/page-settings"
              component={PageSettings}
            />
            <DashboardRoute
              exact
              path="/admin/reviews"
              component={ReviewsManager}
            />
            <DashboardRoute
              exact
              path="/admin/review/:slug"
              component={ReviewsByProduct}
            />

            <CustomerRoute exact path="/" component={Landing} />
            <CustomerRoute component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeContext>
  );
}

export default connect(null, { loadAccount })(App);
