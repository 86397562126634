import { createSelector } from 'reselect';

const selectProductTag = state => state.product_tag;

export const selectProductTagInProductTag = createSelector(
  [selectProductTag],
  product_tag => product_tag.product_tag
);

export const selectProductTags = createSelector(
  [selectProductTag],
  product_tag => product_tag.product_tags
);

export const selectIsProductTagRetrieveLoading = createSelector(
  [selectProductTag],
  product_tag => product_tag.isRetrieveLoading
)

export const selectIsProductTagGetLoading = createSelector(
  [selectProductTag],
  product_tag => product_tag.isGetLoading
)

export const selectIsProductTagFormLoading = createSelector(
  [selectProductTag],
  product_tag => product_tag.isFormLoading
)
