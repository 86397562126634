import React from "react";

// router
import { Redirect, Route } from "react-router-dom";

// layout
import CustomerLayout from "layouts/CustomerLayout";

// redux
import { connect } from "react-redux";

// reselect
import { selectAccountType, selectIsAuthenticated } from "store/selectors/auth.selector";
import { createStructuredSelector } from "reselect";

const CustomerPrivateRoute = ({
  component: Component,
  accountType,
  isAuthenticated,
  ...rest
}) => {
  if (isAuthenticated && accountType === "Customer") {
    return (
      <Route
        {...rest}
        render={(props) => (
          <CustomerLayout>
            <Component {...props} />
          </CustomerLayout>
        )}
      />
    );
  } else {
    return <Redirect to="/" />;
  }
};

const mapStateToProps = createStructuredSelector({
  accountType: selectAccountType,
  isAuthenticated: selectIsAuthenticated,
});

export default connect(mapStateToProps)(CustomerPrivateRoute);
