// types
import { SET_UPDATE_ACCOUNT_ADDRESS_MODAL, SET_AUTH_MODAL, SET_ADD_PHONE_NUMBER_MODAL, SET_ADD_ADDRESS_MODAL, SET_CATEGORY_MODAL, SET_PRODUCT_MODAL, SET_ADD_PAYMENT_CARD_MODAL, SET_QUICK_VIEW_MODAL, SET_UPDATE_CATEGORY_MODAL, SET_UPDATE_PRODUCT_MODAL, SET_FAQ_MODAL, SET_UPDATE_FAQ_MODAL, SET_TESTIMONIAL_MODAL, SET_UPDATE_TESTIMONIAL_MODAL, VIEW_INQUIRY_MODAL, VIEW_ORDER_DETAILS_MODAL, SET_CREATE_REVIEW_MODAL, SET_CREATE_NEW_HEADER_MODAL, SET_UPDATE_HEADER_HERO_MODAL } from "store/actions/actions.types";

const INITIAL_STATE = {
  isAuthModalVisible: false,
  isQuickViewModalVisible: false,

  isCategoryModalVisible: false,
  isProductModalVisible: false,
  isFAQModalVisible: false,
  isCreateNewHeaderModalVisible: false,
  isTestimonialModalVisible: false,
  isUpdateCategoryModalVisible: false,
  isUpdateProductModalVisible: false,
  isUpdateAccountAddressModalVisible: false,
  isUpdateFAQModalVisible: false,
  isUpdateTestimonialModalVisible: false,
  isUpdateHeaderHeroModalVisible: false,

  isViewInquiryModalVisible: false,
  isViewOrderDetailsModalVisible: false,
  
  isAddingAddressModalOpen: false,
  isAddingPhoneNumberModalOpen: false,
  isAddingPaymentCardModalOpen: false,
  isCreateReviewModalVisible: false,
};

export default function formModal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_AUTH_MODAL:
      return {
        ...state,
        isAuthModalVisible: !state.isAuthModalVisible,
      };
    case SET_QUICK_VIEW_MODAL:
      return {
        ...state,
        isQuickViewModalVisible: !state.isQuickViewModalVisible,
      };
    case SET_CATEGORY_MODAL:
      return {
        ...state,
        isCategoryModalVisible: !state.isCategoryModalVisible,
      };
    case SET_FAQ_MODAL:
      return {
        ...state,
        isFAQModalVisible: !state.isFAQModalVisible,
      };
    case SET_TESTIMONIAL_MODAL:
      return {
        ...state,
        isTestimonialModalVisible: !state.isTestimonialModalVisible,
      };
    case SET_UPDATE_TESTIMONIAL_MODAL:
      return {
        ...state,
        isUpdateTestimonialModalVisible: !state.isUpdateTestimonialModalVisible,
      };
    case SET_UPDATE_FAQ_MODAL:
      return {
        ...state,
        isUpdateFAQModalVisible: !state.isUpdateFAQModalVisible,
      };
    case SET_UPDATE_CATEGORY_MODAL:
      return {
        ...state,
        isUpdateCategoryModalVisible: !state.isUpdateCategoryModalVisible,
      };
    case SET_UPDATE_PRODUCT_MODAL:
      return {
        ...state,
        isUpdateProductModalVisible: !state.isUpdateProductModalVisible,
      };
    case SET_UPDATE_ACCOUNT_ADDRESS_MODAL:
        return {
          ...state,
          isUpdateAccountAddressModalVisible: !state.isUpdateAccountAddressModalVisible,
        };
    case SET_PRODUCT_MODAL:
      return {
        ...state,
        isProductModalVisible: !state.isProductModalVisible,
      };
    case SET_ADD_ADDRESS_MODAL:
        return {
          ...state,
          isAddingAddressModalOpen: !state.isAddingAddressModalOpen,
        };
    case SET_ADD_PHONE_NUMBER_MODAL:
        return {
          ...state,
          isAddingPhoneNumberModalOpen: !state.isAddingPhoneNumberModalOpen,
        };
    case SET_ADD_PAYMENT_CARD_MODAL:
        return {
          ...state,
          isAddingPaymentCardModalOpen: !state.isAddingPaymentCardModalOpen,
        };
    case VIEW_INQUIRY_MODAL:
        return {
          ...state,
          isViewInquiryModalVisible: !state.isViewInquiryModalVisible,
        };
    case VIEW_ORDER_DETAILS_MODAL:
        return {
          ...state,
          isViewOrderDetailsModalVisible: !state.isViewOrderDetailsModalVisible,
        };
    case SET_CREATE_REVIEW_MODAL:
        return {
          ...state,
          isCreateReviewModalVisible: !state.isCreateReviewModalVisible,
        };
    case SET_CREATE_NEW_HEADER_MODAL:
        return {
          ...state,
          isCreateNewHeaderModalVisible: !state.isCreateNewHeaderModalVisible,
        }
    case SET_UPDATE_HEADER_HERO_MODAL:
      return {
        ...state,
        isUpdateHeaderHeroModalVisible: !state.isUpdateHeaderHeroModalVisible,
      };
    default:
      return state;
  }
};