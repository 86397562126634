import React, { useState, useEffect } from "react";

// icons
import { Icon } from "@iconify/react";
import bxPlus from "@iconify/icons-bx/bx-plus";
import bxMinus from "@iconify/icons-bx/bx-minus";

// redux
import { connect } from "react-redux";
import { addToCart, decreaseCartItem } from "store/actions/cart.action";

// form
import { useForm } from "react-hook-form";
import ButtonSpinner from "../spinner/Spinner";

const QuantityInput = ({
  cartItemId,
  productId,
  addToCart,
  quantity,
  decreaseCartItem,
}) => {
  const { register, handleSubmit, setValue } = useForm();
  const [addLoading, setAddLoading] = useState(false);
  const [decreaseLoading, setDecreaseLoading] = useState(false);

  useEffect(() => {
    if (quantity) {
      setValue("quantity", quantity, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [quantity, setValue]);

  const onSubmit = handleSubmit((data) => {
    console.log(JSON.stringify(data));
  });

  const addToCartHandler = async (id) => {
    setAddLoading(true);
    await addToCart(id);
    setAddLoading(false);
  };

  const decreaseCartHandler = async (id) => {
    setDecreaseLoading(true);
    await decreaseCartItem(id);
    setDecreaseLoading(false);
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center border justify-between w-20">
        <div
          onClick={() => decreaseCartHandler(cartItemId)}
          className="p-1  hover:text-default-primary duration-300 cursor-pointer"
        >
          <Icon icon={bxMinus} className="w-3 h-3" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            name="quantity"
            type="number"
            ref={register}
            className="outline-none text-semiBase h-full w-full p-1 text-center text-black"
          />
        </form>
        <div
          onClick={() => addToCartHandler(productId)}
          className="p-1 hover:text-default-primary duration-300 cursor-pointer"
        >
          <Icon icon={bxPlus} className="w-3 h-3" />
        </div>
      </div>
      {addLoading ? <ButtonSpinner color="text-black" size="w-6-h-6" /> : null}
      {decreaseLoading ? <ButtonSpinner color="text-black" size="w-6-h-6" /> : null}
      {console.log("decreaseloading", decreaseLoading)}
      {/* {console.log("INcreaseloading", decreaseLoading)} */}
    </div>
  );
};

export default connect(null, { addToCart, decreaseCartItem })(QuantityInput);
