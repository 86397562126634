import { createSelector } from 'reselect';

const selectFAQ = state => state.faq;

export const selectFAQInFAQ = createSelector(
  [selectFAQ],
  faq => faq && faq.faq
);

export const selectInquiries = createSelector(
  [selectFAQ],
  faq => faq && faq.faqs
);

export const selectIsInquiryRetrieveLoading = createSelector(
  [selectFAQ],
  faq => faq && faq.isRetrieveLoading
)

export const selectIsInquiryGetLoading = createSelector(
  [selectFAQ],
  faq => faq && faq.isGetLoading
)

export const selectIsFAQFormLoading = createSelector(
  [selectFAQ],
  faq => faq && faq.isFormLoading
)

export const selectIsFAQUpdateLoading = createSelector(
  [selectFAQ],
  faq => faq && faq.isUpdateLoading
)

export const selectIsFAQIsUpdate = createSelector(
  [selectFAQ],
  faq => faq && faq.isUpdate
)

export const selectFAQIsSubmitSuccess = createSelector(
  [selectFAQ],
  faq => faq && faq.isSubmitSuccess
)
