import React, { useState, useEffect } from "react";

// redux
import { connect } from "react-redux";
import {
  addCategory,
  setCategoryUpdateForm,
  updateCategory,
} from "store/actions/category.action";

// components
import Button from "components/common/buttons/Button";
import ButtonSpinner, { ModalLoader } from "components/common/spinner/Spinner";
import DraggableImageUploadField from "components/common/form-input/DraggableImageUploadField";

// layout
import AdminFormContainer from "layouts/form-container/AdminFormContainer";
import { CustomInputField } from "components/common/form-input/FloatingFormInput";

import { useForm } from "react-hook-form";
import { ErrorMessageInField } from "components/common/form-input/FloatingFormInput";
import { CustomTextareaField } from "components/common/form-input/FloatingTextarea";
import { createStructuredSelector } from "reselect";
import { selectCategoryInCategory, selectIsCategoryFormLoading, selectIsCategoryRetrieveLoading, selectIsCategoryUpdateLoading, selectIsCategoryUpdate, selectIsCategorySubmitSuccess } from "store/selectors/categories.selector";

const CategoryForm = ({
  title,
  isOnModal,
  addCategory,
  isFormLoading,
  isUpdateLoading,
  isRetrieveLoading,
  isUpdate,
  isSubmitSuccess,
  category,
  updateCategory,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const [formData, setFormData] = useState({
    images: [],
  });

  const { images } = formData;

  useEffect(() => {
    if (category) {
      setFormData({
        id: category.id,
        images: [category.category_image],
      });
      setValue("name", category.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("description", category.description, {
        shouldValidate: true,
        shouldDirty: true,
      });

      if (!isUpdate) {
        setFormData({
          images: [],
        });
      }
    }
  }, [category, isUpdate, isSubmitSuccess, setValue]);

  const onSubmit = async (data, e) => {
    if (isUpdate) {
      const { images, id } = formData;
      await updateCategory(data.name, data.description, images, id);
    } else {
      const { images } = formData;
      await addCategory(data.name, data.description, images);
    }
    setFormData({
      images: [],
    });
    e.target.reset();
  };

  const form = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0">
        {(isUpdateLoading || isFormLoading) && (
          <div className="my-2 text-sm text-red-400">
            Please wait while the category is being{" "}
            {isUpdateLoading ? "updated in" : "added to"} our database.
          </div>
        )}
        {/* STUB IMAGE */}
        <div className="">
          <DraggableImageUploadField
            image={images}
            formData={formData}
            setFormData={setFormData}
            isMulti={false}
            name="image"
          />
        </div>
        {/* STUB NAME */}
        <CustomInputField
          type="text"
          name="name"
          inputRef={register({ required: true, minLength: 3, maxLength: 50 })}
          label="Name"
          aria-invalid={errors.name ? true : false}
          isError={errors.name}
        />
        <ErrorMessageInField>
          {errors.name && errors.name.type === "required" && (
            <p>Name is required.</p>
          )}
          {errors.name && errors.name.type === "minLength" && (
            <p>Name should be at-least 2 characters.</p>
          )}
          {errors.name && errors.name.type === "maxLength" && (
            <p>Name should be not more than 50 characters.</p>
          )}
        </ErrorMessageInField>

        {/* STUB DESCRIPTION */}
        <CustomTextareaField
          type="text"
          name="description"
          inputRef={register({
            required: true,
          })}
          label="Description"
          aria-invalid={errors.description ? true : false}
          isError={errors.description}
        />
        <ErrorMessageInField>
          {errors.description && errors.description.type === "required" && (
            <p>Description is required.</p>
          )}
        </ErrorMessageInField>

        <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
          <div className="uppercase text-white font-medium px-3 py-2 text-sm flex items-center">
            <span>{isUpdate ? "Update Category" : "Create Category"}</span>
            <span>
              {(isUpdateLoading || isFormLoading) && (
                <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
              )}
            </span>
          </div>{" "}
        </Button>
      </form>
    </>
  );
  return (
    <>
      {isOnModal ? (
        <> {isRetrieveLoading ? <ModalLoader /> : form} </>
      ) : (
        <AdminFormContainer title={title}>
          <div className="sticky top-1/5">{form}</div>
        </AdminFormContainer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isFormLoading: selectIsCategoryFormLoading,
  isUpdateLoading: selectIsCategoryUpdateLoading,
  category: selectCategoryInCategory,
  isUpdate: selectIsCategoryUpdate,
  isSubmitSuccess: selectIsCategorySubmitSuccess,
  isRetrieveLoading: selectIsCategoryRetrieveLoading,
});

export default connect(mapStateToProps, {
  addCategory,
  updateCategory,
  setCategoryUpdateForm,
})(CategoryForm);
