import React from "react";

// image
import defaultUser from "assets/common/default_user.png";

// route
import { Link } from "react-router-dom";

// components
import Button from "components/common/buttons/Button";
import { connect } from "react-redux";

// reselect
import {
  selectAccount,
  selectIsAuthenticated,
} from "store/selectors/auth.selector";
import { createStructuredSelector } from "reselect";
import { setAuthModal } from "store/actions/modal.action";

const ProfileSidebar = ({
  isAuthenticated,
  account,
  handleClick,
}) => {
  return (
    <>
      <div className="flex items-center p-4">
        <img
          src={defaultUser}
          className="w-12 h-12 object-cover"
          alt="avatar"
        />
        <div className="flex flex-col pl-3">
          {/* if not logged in */}
          {/* <Link to="/login">Login</Link>
        <Link to="/register">Create Account</Link> */}
          {/* if logged in */}
          {isAuthenticated && account ? (
            <Link
              to="/profile/me"
              className="font-medium text-lg text-green-500"
            >
              {account.username}
            </Link>
          ) : null}
        </div>
      </div>
      {isAuthenticated ? null : (
        <div className="flex items-center p-3 space-x-2">
          <Button
            color="bg-gray-800 outline-none focus:bg-gray-900 text-white"
            onClick={() => handleClick()}
          >
            Login or Register
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  account: selectAccount,
  isAuthenticated: selectIsAuthenticated,
});

export default connect(mapStateToProps, { setAuthModal })(ProfileSidebar);
