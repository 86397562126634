import React, { useState } from "react";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";

const PaymentCardForm = ({ isOnModal }) => {
  const [formData, setFormData] = useState({
    number: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { number } = formData;

  const form = (
    <form className="m-0 p-0 w-full ">
      <FloatingFormInput
        label="Card Number"
        type="text"
        name="number"
        value={number}
        formType="customerCardNumber"
        handleChange={handleChange}
      />

      <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
        <div className="uppercase text-white font-medium px-3 py-2 text-sm">Save</div>{" "}
      </Button>
    </form>
  );

  return (
    <>
      {isOnModal ? (
        <> {form} </>
        ) : (
          
          <> {form} </>
      )}
    </>
  )
};

export default PaymentCardForm;
