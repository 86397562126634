import React from "react";

// components
import { LinkButton } from "components/common/buttons/Button";

const NotFoundPage = (props) => {
  return (
    <div className="relative z-1">
      <div className="relative">
        <div className="mx-auto w-full md:w-9/12 px-3">
          <div className="h-60v flex flex-col  items-center justify-center">
            <div className="text-5xl sm:text-200x font-bold text-gray-500 leading-none">
              404
            </div>
            <div className="text-3xl sm:text-5xl font-medium text-gray-500 leading-1 uppercase">
              Page Not Found
            </div>
            <div className="">
              <LinkButton
                color="bg-gray-800 hover:bg-gray-900 text-white"
                uppercase
                linkPath="/"
              >
                <div className="font-bold py-2 px-4  sm:py-4 sm:px-6">Back to Home</div>
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
