import {
  ADD_PRODUCT_FAILED,
  ADD_PRODUCT_START,
  ADD_PRODUCT_SUCCESS,
  CLEAR_PRODUCT,
  CLEAR_PRODUCTS,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_TAG_START,
  DELETE_PRODUCT_VARIANT_FAILED,
  DELETE_PRODUCT_VARIANT_START,
  DELETE_PRODUCT_VARIANT_SUCCESS,
  GET_BEST_SELLER_PRODUCTS_SUCCESS,
  GET_NEW_RELEASE_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_RECOMMENDATION_PRODUCTS_SUCCESS,
  GET_TOP_RATED_PRODUCTS_SUCCESS,
  RETRIEVE_PRODUCT_FAILED,
  RETRIEVE_PRODUCT_START,
  RETRIEVE_PRODUCT_SUCCESS,
  SET_PRODUCT_UPDATE_FORM,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_SUCCESS,
} from "store/actions/actions.types";

const initialState = {
  products: [],
  product: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false,
  isUpdateLoading: false,
  isRetrieveLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
  isDeleteVariantLoading: false,
  hasMore: false,
  hasPriceFilter: false,
  priceRange: null,
  bestSellers: [],
  newRelease: [],
  recommendations: [],
  topRated: [],
};

export default function product(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PRODUCT_UPDATE_FORM:
      return {
        ...state,
        isUpdate: true,
      };
    case ADD_PRODUCT_START:
      return {
        ...state,
        isFormLoading: true,
        isSubmitSuccess: false,
      };
    case GET_PRODUCTS_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case RETRIEVE_PRODUCT_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case DELETE_PRODUCT_TAG_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case UPDATE_PRODUCT_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case DELETE_PRODUCT_VARIANT_START:
      return {
        ...state,
        isDeleteVariantLoading: true,
      };
    case ADD_PRODUCT_FAILED:
    case GET_PRODUCTS_FAILED:
    case DELETE_PRODUCT_FAILED:
    case UPDATE_PRODUCT_FAILED:
    case RETRIEVE_PRODUCT_FAILED:
    case DELETE_PRODUCT_VARIANT_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isFormLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
        isSubmitSuccess: false,
        isRetrieveLoading: false,
        isDeleteVariantLoading: false,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, payload],
        isFormLoading: false,
        isSubmitSuccess: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((product) => product.id !== payload),
        isDeleteLoading: false,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === payload.id ? { ...payload } : product
        ),
        isUpdate: false,
        isUpdateLoading: false,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...state.products, ...payload],
        isGetLoading: false,
        hasMore: action.hasMore,
        hasPriceFilter: action.hasPriceFilter,
        priceRange: action.priceRange,
      };
    case RETRIEVE_PRODUCT_SUCCESS: {
      return {
        ...state,
        product: payload,
        isRetrieveLoading: false,
      };
    }
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: [],
      };
    case GET_BEST_SELLER_PRODUCTS_SUCCESS:
      return {
        ...state,
        bestSellers: payload,
      };
    case GET_TOP_RATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        topRated: payload,
      };
    case GET_NEW_RELEASE_PRODUCTS_SUCCESS:
      return {
        ...state,
        newRelease: payload,
      };
    case GET_RECOMMENDATION_PRODUCTS_SUCCESS:
      return {
        ...state,
        recommendations: payload,
      };
    case DELETE_PRODUCT_VARIANT_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          variants: state.product.variants.filter(
            (variant) => variant.id !== payload
          ),
        },
      };
    case CLEAR_PRODUCT:
      return {
        ...state,
        product: null,
        isUpdate: false,
      };
    default:
      return state;
  }
}
