import {
  ADD_TO_CART_FAILED,
  ADD_TO_CART_START,
  ADD_TO_CART_SUCCESS,
  CLEAR_CART,
  CLEAR_CART_ITEMS,
  DECREASE_CART_ITEM_FAILED,
  DECREASE_CART_ITEM_START,
  DECREASE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAILED,
  DELETE_CART_ITEM_START,
  DELETE_CART_ITEM_SUCCESS,
  GET_CART_FAILED,
  GET_CART_START,
  GET_CART_SUCCESS,
  REMOVE_QTY_TO_CART_FAILED,
  REMOVE_QTY_TO_CART_START,
  REMOVE_QTY_TO_CART_SUCCESS,
} from "store/actions/actions.types";
import { addToCart } from "store/utilities/cart.utils";

const INITIAL_STATE = {
  cart: null,
  isLoading: false,
  isAddLoading: false,
  isDeleteLoading: false,
  isRemoveLoading: false,
  isDecreaseLoading: false,
};

export default function cart(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case ADD_TO_CART_START:
      return {
        ...state,
        isAddLoading: true,
      };
    case GET_CART_START:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CART_ITEM_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case REMOVE_QTY_TO_CART_START:
      return {
        ...state,
        isRemoveLoading: true,
      };
    case ADD_TO_CART_FAILED:
    case GET_CART_FAILED:
    case DELETE_CART_ITEM_FAILED:
    case REMOVE_QTY_TO_CART_FAILED:
      return {
        ...state,
        isAddLoading: false,
        isLoading: false,
        isDeleteLoading: false,
        isRemoveLoading: false,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: payload,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isAddLoading: false,
        cart: addToCart(state.cart, state.cart.cart_items, payload),
      };
    case DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items.filter(
            (cartItem) => cartItem.id !== payload.id
          ),
          total_price: payload.total_price,
        },
        isDeleteLoading: false,
      };
    case REMOVE_QTY_TO_CART_SUCCESS:
      return {
        ...state,
        isRemoveLoading: false,
        // cart: addToCart(state.cart, state.cart.cart_items , payload)
      };
    case DECREASE_CART_ITEM_START:
      return {
        ...state,
        isDecreaseLoading: true,
      };
    case DECREASE_CART_ITEM_SUCCESS:
      return {
        ...state,
        isDecreaseLoading: false,
        cart: addToCart(state.cart, state.cart.cart_items, payload),
      };
    case DECREASE_CART_ITEM_FAILED:
      return {
        ...state,
        isDecreaseLoading: false,
      };
    case CLEAR_CART_ITEMS:
      return {
        ...state,
        cart: {
          ...state.cart,
          total_price: 0,
          cart_items: [],
        },
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: null,
      };
    default:
      return state;
  }
}
