import API from "store/api";
import { toast } from "react-toastify";
import { tokenConfig } from "./auth.action";
import { ADD_TESTIMONIAL_FAILED, ADD_TESTIMONIAL_START, ADD_TESTIMONIAL_SUCCESS, DELETE_TESTIMONIAL_FAILED, DELETE_TESTIMONIAL_START, DELETE_TESTIMONIAL_SUCCESS, GET_TESTIMONIALS_FAILED, GET_TESTIMONIALS_START, GET_TESTIMONIALS_SUCCESS, RETRIEVE_TESTIMONIAL_FAILED, RETRIEVE_TESTIMONIAL_START, RETRIEVE_TESTIMONIAL_SUCCESS, SET_TESTIMONIAL_UPDATE_FORM, UPDATE_TESTIMONIAL_FAILED, UPDATE_TESTIMONIAL_START, UPDATE_TESTIMONIAL_SUCCESS } from "./actions.types";
import { setAddTestimonialModal, setUpdateTestimonialModal } from "./modal.action";

// add inquiry
export const addTestomonial = (name, message) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_TESTIMONIAL_START });

  let testimonialData = new FormData();
  testimonialData.set("name", name);
  testimonialData.set("message", message);


  API.post("/site/testimonials/", testimonialData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_TESTIMONIAL_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Testimonial has been added.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .then((res) => {
      dispatch(setAddTestimonialModal(false));
    })
    .catch((err) => {
      dispatch({ type: ADD_TESTIMONIAL_FAILED });
      console.log(err.response.data);
    });
};

// get faq
export const getTestimonials = () => async (dispatch) => {
  dispatch({ type: GET_TESTIMONIALS_START });

  await API.get("/site/testimonials")
    .then((res) => {
      dispatch({ type: GET_TESTIMONIALS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: GET_TESTIMONIALS_FAILED });
    })
}


// delete faq
export const deleteTestimonial = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_TESTIMONIAL_START });

  API.delete(`/site/testimonials/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_TESTIMONIAL_SUCCESS, payload: id });
    })
    .then((res) => {
      // dispatch(setAlert('Deleting category successful!'));
      toast.success("Deleting Testimonial Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_TESTIMONIAL_FAILED });
      console.log(err.response.data);
    });
};


// retrieve faq
export const retrieveTestimonial = (id) => async (dispatch) => {
  dispatch({ type: RETRIEVE_TESTIMONIAL_START });

  await API.get(`site/testimonials/${id}`)
    .then((res) => {
      dispatch({ type: RETRIEVE_TESTIMONIAL_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: RETRIEVE_TESTIMONIAL_FAILED });
    });
};


// update faq
export const updateTestimonial = (name, message, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_TESTIMONIAL_START });

  let testimonialData = new FormData();
  testimonialData.set("name", name);
  testimonialData.set("message", message);


  await API.patch(
    `site/testimonials/${id}/`,
    testimonialData,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: UPDATE_TESTIMONIAL_SUCCESS, payload: res.data });
    })
    .then((res) => {
      dispatch(setUpdateTestimonialModal(false));
    })
    .then((res) => {
      toast.success("Updating Testimonial Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_TESTIMONIAL_FAILED });
    });
};

export const setTestimonialUpdateForm = () => (dispatch) => {
  dispatch({
    type: SET_TESTIMONIAL_UPDATE_FORM,
  });
};