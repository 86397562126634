import {
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  GET_ACCOUNTS_FAILED,
  GET_ACCOUNTS_START,
  GET_ACCOUNTS_SUCCESS,
} from "store/actions/actions.types";

const initialState = {
  accounts: [],
  account: null,
  isGetLoading: false,
  isForgotPasswordLoading: false,
};

export default function account(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACCOUNTS_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case GET_ACCOUNTS_FAILED:
      return {
        ...state,
        isGetLoading: false,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: [...payload],
        isGetLoading: false,
      };
    case FORGOT_PASSWORD_START:
      return {
        ...state,
        isForgotPasswordLoading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isForgotPasswordLoading: false,
      };

    default:
      return state;
  }
}
