import API from "store/api";
import { toast } from "react-toastify";
import { tokenConfig } from "./auth.action";
import { ADD_FAQ_FAILED, ADD_FAQ_START, ADD_FAQ_SUCCESS, DELETE_FAQ_FAILED, DELETE_FAQ_START, DELETE_FAQ_SUCCESS, GET_FAQS_FAILED, GET_FAQS_START, GET_FAQS_SUCCESS, RETRIEVE_FAQ_FAILED, RETRIEVE_FAQ_START, RETRIEVE_FAQ_SUCCESS, SET_FAQ_UPDATE_FORM, UPDATE_FAQ_FAILED, UPDATE_FAQ_START, UPDATE_FAQ_SUCCESS } from "./actions.types";
import { setAddFAQModal, setUpdateFAQModal } from "./modal.action";

// add inquiry
export const addFAQ = (question, answer) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_FAQ_START });

  let faqData = new FormData();
  faqData.set("question", question);
  faqData.set("answer", answer);


  API.post("/site/faqs/", faqData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_FAQ_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("FAQ has been added.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .then((res) => {
      dispatch(setAddFAQModal(false));
    })
    .catch((err) => {
      dispatch({ type: ADD_FAQ_FAILED });
      console.log(err.response.data);
    });
};

// get faq
export const getFAQs = () => async (dispatch) => {
  dispatch({ type: GET_FAQS_START });

  await API.get("/site/faqs")
    .then((res) => {
      dispatch({ type: GET_FAQS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: GET_FAQS_FAILED });
    })
}


// delete faq
export const deleteFAQ = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_FAQ_START });

  API.delete(`/site/faqs/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_FAQ_SUCCESS, payload: id });
    })
    .then((res) => {
      // dispatch(setAlert('Deleting category successful!'));
      toast.success("Deleting FAQ Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_FAQ_FAILED });
      console.log(err.response.data);
    });
};


// retrieve faq
export const retrieveFAQ = (id) => async (dispatch) => {
  dispatch({ type: RETRIEVE_FAQ_START });

  await API.get(`site/faqs/${id}`)
    .then((res) => {
      dispatch({ type: RETRIEVE_FAQ_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: RETRIEVE_FAQ_FAILED });
    });
};


// update faq
export const updateFAQ = (question, answer, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_FAQ_START });

  let faqData = new FormData();
  faqData.set("question", question);
  faqData.set("answer", answer);


  await API.patch(
    `site/faqs/${id}/`,
    faqData,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: UPDATE_FAQ_SUCCESS, payload: res.data });
    })
    .then((res) => {
      dispatch(setUpdateFAQModal(false));
    })
    .then((res) => {
      toast.success("Updating FAQ Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_FAQ_FAILED });
    });
};

export const setFAQUpdateForm = () => (dispatch) => {
  dispatch({
    type: SET_FAQ_UPDATE_FORM,
  });
};