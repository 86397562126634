import React from "react";

// components
import { LinkButton } from "components/common/buttons/Button";
import { PaymentCards } from "components/landing/PaymentCards";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

// redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// context
// import { ThemeContext } from "context/ThemeContext";
import { createStructuredSelector } from "reselect";
import { selectCategories } from "store/selectors/categories.selector";
import { selectFooter } from "store/selectors/landing.selector";

const Footer = ({ categories, footerAbout, isAdminView }) => {
  // const { theme } = React.useContext(ThemeContext);

  const categoryList = categories
    .sort((a, b) => a.name.localeCompare(b.name))
    // .filter((item) => item.name.toLowerCase() !== "new")
    .filter((item) => {
      if (
        item.name.toLowerCase().includes("hot") ||
        item.name.toLowerCase().includes("new")
      ) {
        return null;
      } else {
        return item;
      }
    })
    .map((category, i) => (
      <Link
        key={category.id}
        to={`/shop/category/${category.slug}`}
        className={`text-lg py-2 cursor-pointer duration-300 hover:text-default-primary  ${
          i === categories.length - 3 ? "border-none" : "border-b"
        } border-gray-700`}
      >
        {category.name}
      </Link>
    ));

    const ordering_info_links = (
      <>
        <div className="text-lg py-2 ">Shipping and Returns</div>
      <Link
        to="/testimonials"
        className={`cursor-pointer hover:text-default-primary duration-300 text-lg py-2 border-t border-b border-gray-700`}
      >
        Testimonials
      </Link>
      <Link
        to="/faqs"
        className={`cursor-pointer hover:text-default-primary duration-300 text-lg py-2 border-gray-700`}
      >
        FAQ
      </Link>
      <Link
        to="/profile"
        className={`cursor-pointer hover:text-default-primary duration-300 text-lg py-2 border-t border-b border-gray-700`}
      >
        My Account
      </Link>
      <Link
        to="/wishlist"
        className={`cursor-pointer hover:text-default-primary duration-300 text-lg py-2 border-gray-700`}
      >
        Wishlist
      </Link>
    </>
  );
  return (
    <>
      <div className="text-gray-400 " style={{ backgroundColor: "#2b2b2b" }}>
        <div className="sm:hidden footer-accordion">
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>About Us</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="tracking-wider">
                 { footerAbout }
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            {isAdminView ? null : (
              <>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Shop Now</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="flex flex-col text-gray-400">
                      {categoryList}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Ordering Info</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {/* <div className="text-gray-400">
                      <div className="text-lg py-2 border-t border-b border-gray-700">
                        Shipping and Returns
                      </div>
                      <div className=" text-lg py-2 border-t border-b border-gray-700">
                        Testimonials
                      </div>
                      <div className=" text-lg py-2">FAQ</div>
                      <div className=" text-lg py-2 border-t border-b border-gray-700">
                        <Link
                          to="/profile"
                          className={`cursor-pointer hover:text-default-primary duration-300 text-lg border-gray-700`}
                        >
                          My Account
                        </Link>
                      </div>

                      <div
                        className={`duration-300 text-lg py-2 hover:text-default-primary cursor-pointer`}
                        onClick={() => history.push("/wishlist")}
                      >
                        Wishlist
                      </div>
                    </div> */}
                    <div className="w-full flex flex-col text-gray-400">
                      {ordering_info_links}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </>
            )}
          </Accordion>
          {isAdminView ? null : 
            <div className="p-4">
              <div className="text-xl uppercase font-medium">Stay Updated</div>
              <div className="h-1 bg-gray-400 w-8 mt-2 mb-4"></div>
              <div className="sm:my-2 text-lg">
                Stay up to date and subscribe to our mailing list
              </div>
              <div className="sm:my-2 text-xl">
                <LinkButton linkPath="/register" outlineBorder inline>
                  Sign Up Now
                </LinkButton>
              </div>
            </div>
          }
          <div style={{ backgroundColor: "#4e4e4e" }} className="">
            <div className="md:w-11/12 lg:w-10/12 xl:w-8/12 mx-auto text-gray-400 flex flex-col items-center justify-center sm:flex-row-reverse sm:justify-between  text-xl p-4 font-medium ">
              <PaymentCards backgroundColor="bg-gray-1000" />
              <div className="">
                <div className="border-b border-gray-500 pb-2 flex flex-wrap justify-center space-x-2 xl:space-x-4 text-sm uppercase">
                  <div className="">privacy policy</div>
                  <div className="">terms and conditions </div>
                  <div className="">cookie policy </div>
                </div>
                <div className="text-sm text-center xl:text-left font-light mt-2">
                  Copyright © {new Date().getFullYear()}{" "}
                  <span className="font-medium">Bubbles and Cream Inc.</span>{" "}
                  All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block">
          <div className="flex flex-wrap md:w-11/12 lg:w-10/12 xl:w-8/12 mx-auto py-4 xl:py-8">
            <div
              className={`flex flex-col my-3 w-full ${
                isAdminView ? "w-full" : "sm:w-1/2 md:w-1/3 lg:w-1/4"
              }  px-4`}
            >
              <div className="text-xl uppercase font-medium">About Us</div>
              <div className="h-1 bg-gray-400 w-8 mt-2 mb-4"></div>
              <p className="tracking-wider">
                { footerAbout }
              </p>
            </div>
            {isAdminView ? null : (
              <>
                <div className="flex flex-col my-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4">
                  {/*  md:-ml-24 */}
                  <div className="text-xl uppercase font-medium">Shop Now</div>
                  <div className="h-1 bg-gray-400 w-8 mt-2 mb-4"></div>
                  {categoryList}
                </div>
                <div className="flex flex-col my-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4">
                  <div className="text-xl uppercase font-medium">
                    Ordering Info
                  </div>
                  <div className="h-1 bg-gray-400 w-8 mt-2 mb-4"></div>
                  {ordering_info_links}
                </div>
                <div className="flex flex-col my-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 md:flex-1">
                  <div className="text-xl uppercase font-medium">
                    Stay Updated
                  </div>
                  <div className="h-1 bg-gray-400 w-8 mt-2 mb-4"></div>
                  <div className="sm:my-2 text-lg">
                    {/* Stay up to date and subscribe to our mailing list */}
                    Stay up to date and contact us in our email
                  </div>
                  {/* <div className="sm:my-2 text-xl">
                <LinkButton linkPath="/register" outlineBorder inline>
                  <div className="px-3 py-1">Sign Up Now</div>
                </LinkButton>
              </div> */}
                  Email: info@bubblesandcream.net
                </div>
              </>
            )}
            {/* </div> */}
          </div>

          <div style={{ backgroundColor: "#4e4e4e" }} className="">
            <div className="md:w-11/12 lg:w-10/12 xl:w-8/12 mx-auto text-gray-400 flex flex-col items-center justify-center sm:flex-row-reverse sm:justify-between  text-xl p-4 font-medium ">
              <PaymentCards
                backgroundColor="bg-gray-1000"
                color="hover:text-white"
              />
              <div className="">
                <div className="border-b border-gray-500 pb-2 flex flex-wrap justify-center space-x-2 xl:space-x-4 text-sm uppercase">
                  <Link
                    to="/privacy-policy"
                    className={`cursor-pointer hover:text-default-primary duration-300`}
                  >
                    privacy policy
                  </Link>
                  <Link
                    to="/terms-and-conditions"
                    className={`cursor-pointer hover:text-default-primary duration-300`}
                  >
                    terms and conditions{" "}
                  </Link>
                  <Link
                    to="/cookie-policy"
                    className={`cursor-pointer hover:text-default-primary duration-300`}
                  >
                    cookie policy{" "}
                  </Link>
                </div>
                <div className="text-sm text-center xl:text-left font-light mt-2">
                  Copyright © {new Date().getFullYear()}{" "}
                  <span className="font-medium">Bubbles and Cream Inc.</span>{" "}
                  All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  categories: selectCategories,
  footerAbout: selectFooter
});

export default connect(mapStateToProps)(Footer);
