// modals
export const SET_AUTH_MODAL = "SET_AUTH_MODAL";

export const SET_QUICK_VIEW_MODAL = "SET_QUICK_VIEW_MODAL";

export const SET_ADD_ADDRESS_MODAL = "SET_ADD_ADDRESS_MODAL";

export const SET_ADD_PHONE_NUMBER_MODAL = "SET_ADD_PHONE_NUMBER_MODAL";

export const SET_ADD_PAYMENT_CARD_MODAL = "SET_ADD_PAYMENT_CARD_MODAL";

// admin
export const SET_CATEGORY_MODAL = "SET_CATEGORY_MODAL";
export const SET_PRODUCT_MODAL = "SET_PRODUCT_MODAL";
export const SET_FAQ_MODAL = "SET_FAQ_MODAL";
export const SET_TESTIMONIAL_MODAL = "SET_TESTIMONIAL_MODAL";

export const SET_UPDATE_CATEGORY_MODAL = "SET_UPDATE_CATEGORY_MODAL";
export const SET_UPDATE_PRODUCT_MODAL = "SET_UPDATE_PRODUCT_MODAL";
export const SET_UPDATE_ACCOUNT_ADDRESS_MODAL =
  "SET_UPDATE_ACCOUNT_ADDRESS_MODAL";
export const SET_UPDATE_FAQ_MODAL = "SET_UPDATE_FAQ_MODAL";
export const SET_UPDATE_TESTIMONIAL_MODAL = "SET_UPDATE_TESTIMONIAL_MODAL";
export const SET_UPDATE_HEADER_HERO_MODAL = "SET_UPDATE_HEADER_HERO_MODAL";

export const VIEW_INQUIRY_MODAL = "VIEW_INQUIRY_MODAL";
export const VIEW_ORDER_DETAILS_MODAL = "VIEW_ORDER_DETAILS_MODAL";

export const SET_CREATE_REVIEW_MODAL = "SET_CREATE_REVIEW_MODAL";
export const SET_CREATE_NEW_HEADER_MODAL = "SET_CREATE_NEW_HEADER_MODAL";

// sidebars
export const SET_SIDEBAR_FILTER = "SET_SIDEBAR_FILTER";

export const SET_SIDEBAR_CLIENT = "SET_SIDEBAR_CLIENT";

export const SET_SIDEBAR_CART = "SET_SIDEBAR_CART";

// alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// auth
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const REGISTRATION_SUCCESSFUL = "REGISTRATION_SUCCESSFUL";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";

export const AUTH_ERROR = "AUTH_ERROR";

export const LOAD_ACCOUNT_START = "LOAD_ACCOUNT_START";
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const LOAD_ACCOUNT_FAILED = "LOAD_ACCOUNT_FAILED";

export const LOGOUT_ACCOUNT = "LOGOUT_ACCOUNT";

// profile

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

// account address
export const GET_ACCOUNT_ADDRESSES_START = "GET_ACCOUNT_ADDRESSES_START";
export const GET_ACCOUNT_ADDRESSES_SUCCESS = "GET_ACCOUNT_ADDRESSES_SUCCESS";
export const GET_ACCOUNT_ADDRESSES_FAILED = "GET_ACCOUNT_ADDRESSES_FAILED";

export const ADD_ACCOUNT_ADDRESS_START = "ADD_ACCOUNT_ADDRESS_START";
export const ADD_ACCOUNT_ADDRESS_SUCCESS = "ADD_ACCOUNT_ADDRESS_SUCCESS";
export const ADD_ACCOUNT_ADDRESS_FAILED = "ADD_ACCOUNT_ADDRESS_FAILED";

export const DELETE_ACCOUNT_ADDRESS_START = "DELETE_ACCOUNT_ADDRESS_START";
export const DELETE_ACCOUNT_ADDRESS_SUCCESS = "DELETE_ACCOUNT_ADDRESS_SUCCESS";
export const DELETE_ACCOUNT_ADDRESS_FAILED = "DELETE_ACCOUNT_ADDRESS_FAILED";

export const UPDATE_ACCOUNT_ADDRESS_START = "UPDATE_ACCOUNT_ADDRESS_START";
export const UPDATE_ACCOUNT_ADDRESS_SUCCESS = "UPDATE_ACCOUNT_ADDRESS_SUCCESS";
export const UPDATE_ACCOUNT_ADDRESS_FAILED = "UPDATE_ACCOUNT_ADDRESS_FAILED";

export const RETRIEVE_ACCOUNT_ADDRESS_START = "RETRIEVE_ACCOUNT_ADDRESS_START";
export const RETRIEVE_ACCOUNT_ADDRESS_SUCCESS =
  "RETRIEVE_ACCOUNT_ADDRESS_SUCCESS";
export const RETRIEVE_ACCOUNT_ADDRESS_FAILED =
  "RETRIEVE_ACCOUNT_ADDRESS_FAILED";

// categories
export const ADD_CATEGORY_START = "ADD_CATEGORY_START";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILED = "ADD_CATEGORY_FAILED";

export const GET_CATEGORIES_START = "GET_CATEGORIES_START";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";

export const DELETE_CATEGORY_START = "DELETE_CATEGORY_START";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED";

export const RETRIEVE_CATEGORY_START = "RETRIEVE_CATEGORY_START";
export const RETRIEVE_CATEGORY_SUCCESS = "RETRIEVE_CATEGORY_SUCCESS";
export const RETRIEVE_CATEGORY_FAILED = "RETRIEVE_CATEGORY_FAILED";

// form
export const SET_CATEGORY_UPDATE_FORM = "SET_CATEGORY_UPDATE_FORM";
export const SET_PRODUCT_UPDATE_FORM = "SET_PRODUCT_UPDATE_FORM";
export const SET_ACCOUNT_ADDRESS_UPDATE_FORM =
  "SET_ACCOUNT_ADDRESS_UPDATE_FORM";
export const SET_FAQ_UPDATE_FORM = "SET_FAQ_UPDATE_FORM";
export const SET_TESTIMONIAL_UPDATE_FORM = "SET_TESTIMONIAL_UPDATE_FORM";
export const SET_HEADER_HERO_UPDATE_FORM = "SET_HEADER_HERO_UPDATE_FORM";

// accounts
export const GET_ACCOUNTS_START = "GET_ACCOUNTS_START";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILED = "GET_ACCOUNTS_FAILED";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

// product tags
export const GET_PRODUCT_TAGS_START = "GET_PRODUCT_TAGS_START";
export const GET_PRODUCT_TAGS_SUCCESS = "GET_PRODUCT_TAGS_SUCCESS";
export const GET_PRODUCT_TAGS_FAILED = "GET_PRODUCT_TAGS_FAILED";

export const ADD_PRODUCT_TAG_START = "ADD_PRODUCT_TAG_START";
export const ADD_PRODUCT_TAG_SUCCESS = "ADD_PRODUCT_TAG_SUCCESS";
export const ADD_PRODUCT_TAG_FAILED = "ADD_PRODUCT_TAG_FAILED";

export const DELETE_PRODUCT_TAG_START = "DELETE_PRODUCT_TAG_START";
export const DELETE_PRODUCT_TAG_SUCCESS = "DELETE_PRODUCT_TAG_SUCCESS";
export const DELETE_PRODUCT_TAG_FAILED = "DELETE_PRODUCT_TAG_FAILED";

// products
export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_TOP_RATED_PRODUCTS_SUCCESS = "GET_TOP_RATED_PRODUCTS_SUCCESS";
export const GET_BEST_SELLER_PRODUCTS_SUCCESS =
  "GET_BEST_SELLER_PRODUCTS_SUCCESS";
export const GET_NEW_RELEASE_PRODUCTS_SUCCESS =
  "GET_BEST_SELLERS_PRODUCTS_SUCCESS";
export const GET_RECOMMENDATION_PRODUCTS_SUCCESS =
  "GET_RECOMMENDATION_PRODUCTS_SUCCESS";
export const GET_FILTERED_BY_PRICE_PRODUCTS_SUCCESS =
  "GET_FILTERED_BY_PRICE_PRODUCTS_SUCCESS";
export const GET_SORT_BY_LOW_TO_HIGH_PRODUCT_PRICE_SUCCESS =
  "GET_SORT_BY_LOW_TO_HIGH_PRODUCT_PRICE_SUCCESS";
export const GET_SORT_HIGH_TO_LOW_PRODUCT_PRICE_SUCCESS =
  "GET_SORT_HIGH_TO_LOW_PRODUCT_PRICE_SUCCESS";
export const GET_SORT_BY_NEWNESS_PRODUCT_SUCCESS =
  "GET_SORT_BY_NEWNESS_PRODUCT_SUCCESS";
export const GET_SORT_BY_POPULARITY_PRODUCT_SUCCESS =
  "GET_SORT_BY_POPULARITY_PRODUCT_SUCCESS";
export const GET_SORT_BY_RATING_PRODUCT_SUCCESS =
  "GET_SORT_BY_RATING_PRODUCT_SUCCESS";

export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";

export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";

export const ADD_PRODUCT_START = "ADD_PRODUCT_START";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "ADD_PRODUCT_FAILED";

export const RETRIEVE_PRODUCT_START = "RETRIEVE_PRODUCT_START";
export const RETRIEVE_PRODUCT_SUCCESS = "RETRIEVE_PRODUCT_SUCCESS";
export const RETRIEVE_PRODUCT_FAILED = "RETRIEVE_PRODUCT_FAILED";

export const UPDATE_PRODUCT_START = "UPDATE_PRODUCT_START";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED";

export const DELETE_PRODUCT_IMAGE = "DELETE_PRODUCT_IMAGE";

export const DELETE_PRODUCT_VARIANT_START = "DELETE_PRODUCT_VARIANT_START";
export const DELETE_PRODUCT_VARIANT_SUCCESS = "DELETE_PRODUCT_VARIANT_SUCCESS";
export const DELETE_PRODUCT_VARIANT_FAILED = "DELETE_PRODUCT_VARIANT_FAILED";

// Cart
export const GET_CART_START = "GET_CART_START";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILED = "GET_CART_FAILED";

export const ADD_TO_CART_START = "ADD_TO_CART_START";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED";

export const REMOVE_QTY_TO_CART_START = "REMOVE_QTY_TO_CART_START";
export const REMOVE_QTY_TO_CART_SUCCESS = "REMOVE_QTY_TO_CART_SUCCESS";
export const REMOVE_QTY_TO_CART_FAILED = "REMOVE_QTY_TO_CART_FAILED";

export const DELETE_CART_ITEM_START = "DELETE_CART_ITEM_START";
export const DELETE_CART_ITEM_SUCCESS = "DELETE_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_FAILED = "DELETE_CART_ITEM_FAILED";

export const DECREASE_CART_ITEM_START = "DECREASE_CART_ITEM_START";
export const DECREASE_CART_ITEM_SUCCESS = "DECREASE_CART_ITEM_SUCCESS";
export const DECREASE_CART_ITEM_FAILED = "DECREASE_CART_ITEM_FAILED";

export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";

export const CLEAR_CART = "CLEAR_CART";

// wishlist

export const GET_WISHLIST_START = "GET_WISHLIST_START";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILED = "GET_WISHLIST_FAILED";

export const GET_WISHLIST_ITEMS_START = "GET_WISHLIST_ITEMS_START";
export const GET_WISHLIST_ITEMS_SUCCESS = "GET_WISHLIST_ITEMS_SUCCESS";
export const GET_WISHLIST_ITEMS_FAILED = "GET_WISHLIST_ITEMS_FAILED";

export const ADD_TO_WISHLIST_START = "ADD_TO_WISHLIST_START";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAILED = "ADD_TO_WISHLIST_FAILED";

export const REMOVE_TO_WISHLIST_START = "REMOVE_TO_WISHLIST_START";
export const REMOVE_TO_WISHLIST_SUCCESS = "REMOVE_TO_WISHLIST_SUCCESS";
export const REMOVE_TO_WISHLIST_FAILED = "REMOVE_TO_WISHLIST_FAILED";

export const CLEAR_WISHLIST_ITEMS = "CLEAR_WISHLIST_ITEMS";

export const CLEAR_WISHLIST = "CLEAR_WISHLIST";

// Inquiries
export const ADD_INQUIRY_START = "ADD_INQUIRY_START";
export const ADD_INQUIRY_SUCCESS = "ADD_INQUIRY_SUCCESS";
export const ADD_INQUIRY_FAILED = "ADD_INQUIRY_FAILED";

export const GET_INQUIRIES_START = "GET_INQUIRIES_START";
export const GET_INQUIRIES_SUCCESS = "GET_INQUIRIES_SUCCESS";
export const GET_INQUIRIES_FAILED = "GET_INQUIRIES_FAILED";

export const RETRIEVE_INQUIRY_START = "RETRIEVE_INQUIRY_START";
export const RETRIEVE_INQUIRY_SUCCESS = "RETRIEVE_INQUIRY_SUCCESS";
export const RETRIEVE_INQUIRY_FAILED = "RETRIEVE_INQUIRY_FAILED";

export const DELETE_INQUIRY_START = "DELETE_INQUIRY_START";
export const DELETE_INQUIRY_SUCCESS = "DELETE_INQUIRY_SUCCESS";
export const DELETE_INQUIRY_FAILED = "DELETE_INQUIRY_FAILED";

// Frequently Asked Questions
export const GET_FAQS_START = "GET_FAQS_START";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAILED = "GET_FAQS_FAILED";

export const ADD_FAQ_START = "ADD_FAQ_START";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_FAILED = "ADD_FAQ_FAILED";

export const DELETE_FAQ_START = "DELETE_FAQ_START";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED";

export const UPDATE_FAQ_START = "UPDATE_FAQ_START";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAILED = "UPDATE_FAQ_FAILED";

export const RETRIEVE_FAQ_START = "RETRIEVE_FAQ_START";
export const RETRIEVE_FAQ_SUCCESS = "RETRIEVE_FAQ_SUCCESS";
export const RETRIEVE_FAQ_FAILED = "RETRIEVE_FAQ_FAILED";

// About Us
export const GET_ABOUTS_START = "GET_ABOUTS_START";
export const GET_ABOUTS_SUCCESS = "GET_ABOUTS_SUCCESS";
export const GET_ABOUTS_FAILED = "GET_ABOUTS_FAILED";

export const RETRIEVE_ABOUT_START = "RETRIEVE_ABOUT_START";
export const RETRIEVE_ABOUT_SUCCESS = "RETRIEVE_ABOUT_SUCCESS";
export const RETRIEVE_ABOUT_FAILED = "RETRIEVE_ABOUT_FAILED";

export const UPDATE_ABOUT_START = "UPDATE_ABOUT_START";
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS";
export const UPDATE_ABOUT_FAILED = "UPDATE_ABOUT_FAILED";

// Contact Us
export const RETRIEVE_CONTACT_START = "RETRIEVE_CONTACT_START";
export const RETRIEVE_CONTACT_SUCCESS = "RETRIEVE_CONTACT_SUCCESS";
export const RETRIEVE_CONTACT_FAILED = "RETRIEVE_CONTACT_FAILED";

export const UPDATE_CONTACT_START = "UPDATE_CONTACT_START";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILED = "UPDATE_CONTACT_FAILED";

// Testimonials
export const GET_TESTIMONIALS_START = "GET_TESTIMONIALS_START";
export const GET_TESTIMONIALS_SUCCESS = "GET_TESTIMONIALS_SUCCESS";
export const GET_TESTIMONIALS_FAILED = "GET_TESTIMONIALS_FAILED";

export const ADD_TESTIMONIAL_START = "ADD_TESTIMONIAL_START";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCCESS";
export const ADD_TESTIMONIAL_FAILED = "ADD_TESTIMONIAL_FAILED";

export const DELETE_TESTIMONIAL_START = "DELETE_TESTIMONIAL_START";
export const DELETE_TESTIMONIAL_SUCCESS = "DELETE_TESTIMONIAL_SUCCESS";
export const DELETE_TESTIMONIAL_FAILED = "DELETE_TESTIMONIAL_FAILED";

export const UPDATE_TESTIMONIAL_START = "UPDATE_TESTIMONIAL_START";
export const UPDATE_TESTIMONIAL_SUCCESS = "UPDATE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_FAILED = "UPDATE_TESTIMONIAL_FAILED";

export const RETRIEVE_TESTIMONIAL_START = "RETRIEVE_TESTIMONIAL_START";
export const RETRIEVE_TESTIMONIAL_SUCCESS = "RETRIEVE_TESTIMONIAL_SUCCESS";
export const RETRIEVE_TESTIMONIAL_FAILED = "RETRIEVE_TESTIMONIAL_FAILED";

// landing page
export const UPDATE_LANDING_SECTION_HEADER_START =
  "UPDATE_LANDING_SECTION_HEADER_START";
export const UPDATE_LANDING_SECTION_HEADER_SUCCESS =
  "UPDATE_LANDING_SECTION_HEADER_SUCCESS";
export const UPDATE_LANDING_SECTION_HEADER_FAILED =
  "UPDATE_LANDING_SECTION_HEADER_FAILED";

export const UPDATE_LANDING_SECTION_TWO_START =
  "UPDATE_LANDING_SECTION_TWO_START";
export const UPDATE_LANDING_SECTION_TWO_SUCCESS =
  "UPDATE_LANDING_SECTION_TWO_SUCCESS";
export const UPDATE_LANDING_SECTION_TWO_FAILED =
  "UPDATE_LANDING_SECTION_TWO_FAILED";

export const RETRIEVE_LANDING_START = "RETRIEVE_LANDING_START";
export const RETRIEVE_LANDING_SUCCESS = "RETRIEVE_LANDING_SUCCESS";
export const RETRIEVE_LANDING_FAILED = "RETRIEVE_LANDING_FAILED";

export const UPDATE_LANDING_SECTION_FOUR_START =
  "UPDATE_LANDING_SECTION_FOUR_START";
export const UPDATE_LANDING_SECTION_FOUR_SUCCESS =
  "UPDATE_LANDING_SECTION_FOUR_SUCCESS";
export const UPDATE_LANDING_SECTION_FOUR_FAILED =
  "UPDATE_LANDING_SECTION_FOUR_FAILED";

export const UPDATE_FORM_FOOTER_START = "UPDATE_FORM_FOOTER_START";
export const UPDATE_FORM_FOOTER_SUCCESS = "UPDATE_FORM_FOOTER_SUCCESS";
export const UPDATE_FORM_FOOTER_FAILED = "UPDATE_FORM_FOOTER_FAILED";

export const ADD_HEADER_HERO_START = "ADD_HEADER_HERO_START";
export const ADD_HEADER_HERO_SUCCESS = "ADD_HEADER_HERO_SUCCESS";
export const ADD_HEADER_HERO_FAILED = "ADD_HEADER_HERO_FAILED";

export const GET_HEADER_HEROES_START = "GET_HEADER_HEROES_START";
export const GET_HEADER_HEROES_SUCCESS = "GET_HEADER_HEROES_SUCCESS";
export const GET_HEADER_HEROES_FAILED = "GET_HEADER_HEROES_FAILED";

export const DELETE_HEADER_HERO_START = "DELETE_HEADER_HERO_START";
export const DELETE_HEADER_HERO_SUCCESS = "DELETE_HEADER_HERO_SUCCESS";
export const DELETE_HEADER_HERO_FAILED = "DELETE_HEADER_HERO_FAILED";

export const RETRIEVE_HEADER_HERO_START = "RETRIEVE_HEADER_HERO_START";
export const RETRIEVE_HEADER_HERO_SUCCESS = "RETRIEVE_HEADER_HERO_SUCCESS";
export const RETRIEVE_HEADER_HERO_FAILED = "RETRIEVE_HEADER_HERO_FAILED";

export const UPDATE_HEADER_HERO_START = "UPDATE_HEADER_HERO_START";
export const UPDATE_HEADER_HERO_SUCCESS = "UPDATE_HEADER_HERO_SUCCESS";
export const UPDATE_HEADER_HERO_FAILED = "UPDATE_HEADER_HERO_FAILED";

// policy
export const RETRIEVE_POLICY_START = "RETRIEVE_POLICY_START";
export const RETRIEVE_POLICY_SUCCESS = "RETRIEVE_POLICY_SUCCESS";
export const RETRIEVE_POLICY_FAILED = "RETRIEVE_POLICY_FAILED";

export const UPDATE_PRIVACY_POLICY_START = "UPDATE_PRIVACY_POLICY_START";
export const UPDATE_PRIVACY_POLICY_SUCCESS = "UPDATE_PRIVACY_POLICY_SUCCESS";
export const UPDATE_PRIVACY_POLICY_FAILED = "UPDATE_PRIVACY_POLICY_FAILED";

export const UPDATE_TERMS_START = "UPDATE_TERMS_START";
export const UPDATE_TERMS_SUCCESS = "UPDATE_TERMS_SUCCESS";
export const UPDATE_TERMS_FAILED = "UPDATE_TERMS_FAILED";

export const UPDATE_COOKIE_POLICY_START = "UPDATE_COOKIE_POLICY_START";
export const UPDATE_COOKIE_POLICY_SUCCESS = "UPDATE_COOKIE_POLICY_SUCCESS";
export const UPDATE_COOKIE_POLICY_FAILED = "UPDATE_COOKIE_POLICY_FAILED";

// instagram
export const GET_INSTAGRAM_FEED_START = "GET_INSTAGRAM_FEED_START";
export const GET_INSTAGRAM_FEED_SUCCESS = "GET_INSTAGRAM_FEED_SUCCESS";
export const GET_INSTAGRAM_FEED_FAILED = "GET_INSTAGRAM_FEED_FAILED";

// order
export const ADD_ORDER_START = "ADD_ORDER_START";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILED = "ADD_ORDER_FAILED";

export const GET_ORDERS_START = "GET_ORDERS_START";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED";

export const RETRIEVE_ORDER_START = "RETRIEVE_ORDER_START";
export const RETRIEVE_ORDER_SUCCESS = "RETRIEVE_ORDER_SUCCESS";
export const RETRIEVE_ORDER_FAILED = "RETRIEVE_ORDER_FAILED";

export const RETRIEVE_ORDER_ITEM_START = "RETRIEVE_ORDER_ITEM_START";
export const RETRIEVE_ORDER_ITEM_SUCCESS = "RETRIEVE_ORDER_ITEM_SUCCESS";
export const RETRIEVE_ORDER_ITEM_FAILED = "RETRIEVE_ORDER_ITEM_FAILED";

export const SET_TO_SHIPPING_START = "SET_TO_SHIPPING_START";
export const SET_TO_SHIPPING_SUCCESS = "SET_TO_SHIPPING_SUCCESS";
export const SET_TO_SHIPPING_FAILED = "SET_TO_SHIPPING_FAILED";

export const ORDER_RECEIVE_START = "ORDER_RECEIVE_START";
export const ORDER_RECEIVE_SUCCESS = "ORDER_RECEIVE_SUCCESS";
export const ORDER_RECEIVE_FAILED = "ORDER_RECEIVE_FAILED";

export const ORDER_DELIVERED_START = "ORDER_DELIVERED_START";
export const ORDER_DELIVERED_SUCCESS = "ORDER_DELIVERED_SUCCESS";
export const ORDER_DELIVERED_FAILED = "ORDER_DELIVERED_FAILED";

export const SET_ORDER_IS_RATED = "SET_ORDER_IS_RATED";

// reviews
export const RETRIEVE_REVIEW_START = "RETRIEVE_REVIEW_START";
export const RETRIEVE_REVIEW_SUCCESS = "RETRIEVE_REVIEW_SUCCESS";
export const RETRIEVE_REVIEW_FAILED = "RETRIEVE_REVIEW_FAILED";

export const ADD_REVIEW_START = "ADD_REVIEW_START";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
export const ADD_REVIEW_FAILED = "ADD_REVIEW_FAILED";

export const GET_REVIEWS_START = "GET_REVIEWS_START";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILED = "GET_REVIEWS_FAILED";

export const PUBLISH_REVIEW_START = "PUBLISH_REVIEW_START";
export const PUBLISH_REVIEW_SUCCESS = "PUBLISH_REVIEW_SUCCESS";
export const PUBLISH_REVIEW_FAILED = "PUBLISH_REVIEW_FAILED";

export const DELETE_REVIEW_START = "DELETE_REVIEW_START";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_FAILED = "DELETE_REVIEW_FAILED";

export const CLEAR_REVIEWS = "CLEAR_REVIEWS";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";

// tab
export const TAB_PROCESSING_SUCCESS = "TAB_PROCESSING_SUCCESS";
export const TAB_SHIPPING_SUCCESS = "TAB_SHIPPING_SUCCESS";
export const TAB_REVIEW_SUCCESS = "TAB_REVIEW_SUCCESS";
export const TAB_HISTORY_SUCCESS = "TAB_HISTORY_SUCCESS";
