import React, { useEffect, useState } from "react";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";
import ButtonSpinner from "components/common/spinner/Spinner";

// redux
import { connect } from "react-redux";
import { addAddress, updateAccountAddress } from "store/actions/address.action";
import { ModalLoader } from "components/common/spinner/Spinner";
import ToggleButton from "components/common/buttons/ToggleButton";

// reselect
import { selectAccount } from "store/selectors/auth.selector";

// select
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { countryOptions } from "const";
const animatedComponents = makeAnimated();

const AddressForm = ({
  isOnModal,
  account,
  addAddress,
  address,
  setAddAddress,
  updateAccountAddress,
}) => {
  const [newAddress, setNewAddress] = useState({
    full_name: account
      ? "".concat(account.first_name, " ", account.last_name)
      : "",
    phone_number: account ? account.phone_number : "",
    address_details: "",
    zip_code: "",
    country: null,
    city: "",
    region: "",
    is_primary: false,
    shippingFee: 0,
  });

  const {
    address_details,
    zip_code,
    country,
    city,
    region,
    full_name,
    is_primary,
    phone_number,
  } = newAddress;

  const handleChange = (e) => {
    setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (address.address) {
      const splittedAddress = address.address.detailed_address.split(",");
      const getZipCode = splittedAddress.slice(-1)[0];
      splittedAddress.pop();
      const getCountry = {
        id: splittedAddress.slice(-1)[0],
        country: splittedAddress.slice(-1)[0],
      };
      splittedAddress.pop();
      const getState = splittedAddress.slice(-1)[0];
      splittedAddress.pop();
      const getCity = splittedAddress.slice(-1)[0];
      splittedAddress.pop();
      const newDetailedAddress = splittedAddress.join(",");
      setNewAddress({
        id: address.address.id,
        full_name: address.address.full_name,
        phone_number: address.address.phone_number,
        address_details: newDetailedAddress,
        city: getCity,
        region: getState,
        country: getCountry,
        zip_code: getZipCode,
        shippingFee: address.address.shipping_fee,
        is_primary: address.address.is_primary,
      });

      if (!address.isUpdate) {
        setNewAddress({
          id: address.id,
          full_name: "",
          phone_number: "",
          address_details: "",
          zip_code: "",
          country: null,
          city: "",
          region: "",
          is_primary: false,
        });
      }
    }
  }, [address, account]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const detailed_address = "".concat(
      address_details,
      ", ",
      city,
      ", ",
      region,
      ", ",
      country.country,
      ", ",
      zip_code
    );

    if (account) {
      const account_id = account.id;

      if (address.isUpdate) {
        const {
          full_name,
          phone_number,
          is_primary,
          shippingFee,
          id,
        } = newAddress;
        console.log(newAddress);
        await updateAccountAddress(
          account_id,
          full_name,
          phone_number,
          detailed_address,
          shippingFee,
          is_primary,
          id
        );
      } else {
        const { full_name, phone_number, shippingFee, is_primary } = newAddress;
        addAddress(
          account_id,
          full_name,
          phone_number,
          detailed_address,
          shippingFee,
          is_primary
        );
      }
    }

    setNewAddress({
      full_name: "",
      phone_number: "",
      address_details: "",
      zip_code: "",
      country: null,
      city: "",
      region: "",
      is_primary: false,
      shippingFee: 0.0,
    });
  };

  const selectStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "#f7f7f7",
      color: "#e2e8f0",
      borderColor: "#e2e8f0",
      borderWidth: "2px",
      height: "2.8rem",
      boxShadow: isFocused ? "2px solid #68d391" : "2px solid #e2e8f0",
      "&:hover": {
        border: isFocused ? "2px solid #68d391" : "2px solid #e2e8f0",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#cbd5e0",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#c6f6d5" : "#f7f7f7",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: "#c6f6d5",
        },
      };
    },
  };

  const form = (
    <form onSubmit={(e) => handleSubmit(e)} className="m-0 p-0">
      {isOnModal ? null : (
        <>
          <div className="mt-6 text-gray-800 font-medium text-lg">
            Add New Address
          </div>
          <hr className="border-2 border-gray-300 my-2" />
        </>
      )}
      <FloatingFormInput
        label="Full Name"
        type="text"
        name="full_name"
        value={full_name}
        formType="MyAddresses"
        handleChange={handleChange}
      />
      <FloatingFormInput
        label="Phone Number"
        type="text"
        name="phone_number"
        value={phone_number}
        formType="MyAddresses"
        handleChange={handleChange}
      />
      <div className="mb-4">
        <FloatingFormInput
          label="Detailed Address"
          type="text"
          name="address_details"
          value={address_details}
          formType="MyAddresses"
          handleChange={handleChange}
        />
        <div className=" ml-1 text-xs text-gray-500">
          Unit Number, House Number, Building, Street Name
        </div>
      </div>
      <div className="flex space-x-2 w-full items-center">
        <div className="w-1/2">
          <FloatingFormInput
            label="City"
            type="text"
            name="city"
            value={city}
            formType="MyAddresses"
            handleChange={handleChange}
          />
        </div>
        <div className="w-1/2">
          <FloatingFormInput
            label="State/Province/Region"
            type="text"
            name="region"
            value={region}
            formType="MyAddresses"
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="flex space-x-2 w-full items-center">
        <div className="w-1/2">
          <FloatingFormInput
            label="Zip Code"
            type="text"
            name="zip_code"
            value={zip_code}
            formType="MyAddresses"
            handleChange={handleChange}
          />
        </div>
        <div className="w-1/2">
          <span className="text-xs text-gray-400 font-medium capitalize pl-2">
            Country
          </span>
          <Select
            placeholder="Select Country"
            options={countryOptions}
            value={country}
            closeMenuOnSelect={false}
            components={animatedComponents}
            onChange={(value) =>
              value.country === 'United Kingdom' ?
              setNewAddress({ ...newAddress, shippingFee: 4.8, country: value })
              :
              setNewAddress({ ...newAddress, shippingFee: 14, country: value })
            }
            styles={selectStyles}
            getOptionLabel={(newAddress) => newAddress.country}
            getOptionValue={(newAddress) => newAddress.id}
          />
        </div>
      </div>
      <div className="my-4">
        <ToggleButton
          label="Set as Default Address"
          toggleState={is_primary}
          handleToggleChange={() =>
            setNewAddress({ ...newAddress, is_primary: !is_primary })
          }
        />
      </div>
      <div className="flex space-x-2">
        <Button
          type="submit"
          id="btn"
          disabled={
            !address_details ||
            !phone_number ||
            !city ||
            !zip_code ||
            !region ||
            !country
          }
          color="bg-gray-800 hover:bg-gray-900"
          inline
        >
          <div className="px-3 py-1 text-sm uppercase text-white font-medium flex items-center justify-center">
            <span>Save</span>
            <span>
              {(address.isFormLoading || address.isUpdateLoading) && (
                <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
              )}
            </span>
          </div>
        </Button>
        {isOnModal ? null : (
          <Button
            onClick={() => setAddAddress(false)}
            color="bg-gray-300 hover:bg-gray-400"
            inline
          >
            <div className="px-3 py-1 text-sm uppercase text-white font-medium flex items-center justify-center">
              Cancel
            </div>
          </Button>
        )}
      </div>
    </form>
  );

  return (
    <>
      {isOnModal ? (
        <>{address.isRetrieveLoading ? <ModalLoader /> : form}</>
      ) : (
        <> {form} </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  address: state.address,
  account: selectAccount(state),
});

export default connect(mapStateToProps, { addAddress, updateAccountAddress })(
  AddressForm
);
