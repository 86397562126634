import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectOrderInOrder,
  selectIsOrderRetrieveLoading,
} from "store/selectors/order.selector";
import OrderItem from "./OrderItem";

import defaultImage from "assets/common/empty-image.jpg";
import { ModalLoader } from "components/common/spinner/Spinner";
import OrderStatus from "./OrderStatus";
import OrderId from "./OrderId";
import OrderTotal from "./OrderTotal";
import Button from "components/common/buttons/Button";

import { Icon } from "@iconify/react";
import truckFast from "@iconify/icons-mdi/truck-fast";
import bxsBox from "@iconify/icons-bx/bxs-box";

const ViewOrders = ({ order, isRetrieveLoading, isAdminView }) => {
  return isRetrieveLoading ? (
    <ModalLoader />
  ) : (
    <div>
      <div className="my-2">
        <OrderId
          reference_code={order && order.reference_code}
          ordered_date={order && order.ordered_date}
        />
      </div>
      <div className="my-2 ml-2">
        <OrderStatus status={order && order.status} />
      </div>
      <div className="my-2 border-t border-b py-2 border-dashed">
        <div className="text-gray-800 font-medium flex items-center">
          <Icon icon={truckFast} className="w-5 h-5 mr-1" />
          <span>Shipping Information</span>
        </div>
        <div className="ml-6 mt-2 text-gray-600 font-light">
          <div>{order && order.shipping_address.full_name}</div>
          <div>{order && order.shipping_address.phone_number}</div>
          <div>{order && order.shipping_address.detailed_address}</div>
        </div>
      </div>
      <div>
        <div className="font-medium text-gray-800 flex items-center">
          <Icon icon={bxsBox} className="w-4 h-4 mr-1" />
          <span>Orders</span>
        </div>
        {order &&
          order.order_items.map((orderItem) => {
            return (
              <div className="my-3 mx-2">
                <OrderItem
                  key={orderItem.id}
                  imageSrc={
                    orderItem &&
                    orderItem.product.image &&
                    orderItem.product.image.image
                      ? orderItem.product.image.image
                      : defaultImage
                  }
                  name={orderItem.product.name}
                  price={
                    orderItem
                      ? parseFloat(orderItem.product.price).toFixed(2)
                      : null
                  }
                  quantity={orderItem ? orderItem.quantity : null}
                  total_price={orderItem.total_price}
                />
              </div>
            );
          })}
      </div>
      <div className=" border-t border-dashed pt-2">
        <OrderTotal
          total_price={order && order.total_price}
          shippingFee={order && order.shipping_address.shipping_fee}
          isOnModal
        />
      </div>
      {isAdminView ? null : (
        <div className="flex items-center justify-end space-x-2">
          {/* <div className="flex items-center space-x-2">
            <LinkButton
              inline
              color="bg-default-primary hover:bg-default-primaryShade text-white"
              linkPath="/"
            >
              <div className="px-2 text-semiBase">Track Order</div>
            </LinkButton>
          </div> */}
          {/* {order && order.status === "to_review" ? (
            <Button
              color="bg-default-primary hover:bg-default-primaryShade text-white hover:text-white"
              inline
              outlineBorder
              outlineColor="border-green-400 hover:border-green-400"
            >
              <div className="font-light text-sm px-2">Rate</div>
            </Button>
          ) : null} */}
          <Button
            color="hover:bg-default-primary  text-gray-700 hover:text-white"
            outlineColor=" hover:border-green-400"
            outlineBorder
            inline
          >
            <div className="font-light text-sm px-6 ">Buy Again</div>
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  order: selectOrderInOrder,
  isRetrieveLoading: selectIsOrderRetrieveLoading,
});

export default connect(mapStateToProps)(ViewOrders);
