import { ADD_TESTIMONIAL_FAILED, ADD_TESTIMONIAL_START, ADD_TESTIMONIAL_SUCCESS, DELETE_TESTIMONIAL_FAILED, DELETE_TESTIMONIAL_START, DELETE_TESTIMONIAL_SUCCESS, GET_TESTIMONIALS_FAILED, GET_TESTIMONIALS_START, GET_TESTIMONIALS_SUCCESS, RETRIEVE_TESTIMONIAL_FAILED, RETRIEVE_TESTIMONIAL_START, RETRIEVE_TESTIMONIAL_SUCCESS, SET_TESTIMONIAL_UPDATE_FORM, UPDATE_TESTIMONIAL_FAILED, UPDATE_TESTIMONIAL_START, UPDATE_TESTIMONIAL_SUCCESS } from "store/actions/actions.types";

const initialState = {
  testimonials: [],
  testimonial: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
}


export default function testimonial(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case SET_TESTIMONIAL_UPDATE_FORM:
      return {
        ...state,
        isUpdate: true,
      }
    case ADD_TESTIMONIAL_START:
      return {
        ...state,
        isFormLoading: true,
        isSubmitSuccess: false,
      }
    case GET_TESTIMONIALS_START:
      return {
        ...state,
        isGetLoading: true,
      }
    case DELETE_TESTIMONIAL_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case UPDATE_TESTIMONIAL_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case RETRIEVE_TESTIMONIAL_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case ADD_TESTIMONIAL_FAILED:
    case GET_TESTIMONIALS_FAILED:
    case DELETE_TESTIMONIAL_FAILED:
    case RETRIEVE_TESTIMONIAL_FAILED:
    case UPDATE_TESTIMONIAL_FAILED:
      return {
        ...state,
        isFormLoading: false,
        isGetLoading: false,
        isDeleteLoading: false,
        isRetrieveLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
        isSubmitSuccess: false,
      }
    case ADD_TESTIMONIAL_SUCCESS: 
      return {
        ...state,
        testimonials: [payload, ...state.testimonials],
        isFormLoading: false,
      }
    case GET_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        testimonials: [...payload],
        isGetLoading: false,
      }
    case DELETE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: state.testimonials.filter(
          (testimonial) => testimonial.id !== payload
        ),
        isDeleteLoading: false,
      };
    case UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        testimonials: state.testimonials.map((testimonial) =>
          testimonial.id === payload.id ? { ...payload } : testimonial
        ),
        isUpdate: false,
        isUpdateLoading: false
      };
    case RETRIEVE_TESTIMONIAL_SUCCESS: {
      return {
        ...state,
        testimonial: payload,
        isRetrieveLoading: false
      };
    }
    default:
      return state;
  }
}