import React from "react";

import { NavLink } from "react-router-dom";
// icons
import { Icon } from "@iconify/react";
// import bxChevronLeft from '@iconify/icons-bx/bx-chevron-left';
// import bxChevronRight from '@iconify/icons-bx/bx-chevron-right';

// context
// import { ThemeContext } from "context/ThemeContext";

const DropdownItem = ({ linkPath, leftIcon, rightIcon, children }) => {
  // const { theme } = React.useContext(ThemeContext);
  return (
    <NavLink
      to={linkPath}
      className={`menu-item flex items-center rounded-md text-gray-800 hover:text-default-primaryShade duration-300 p-2`}
      activeClassName={`textdefault-primary`}
    >
      <Icon icon={leftIcon} className="icon-button" />
      {children}
      <Icon icon={rightIcon} className="icon-button" />
    </NavLink>
  );
};

export const DropdownItemTwo = ({ click, leftIcon, rightIcon, children }) => {
  // const { theme } = React.useContext(ThemeContext);
  return (
    <div
      onClick={click}
      className={`menu-item flex items-center rounded-md text-gray-800 hover:text-default-primaryShade duration-300 p-2`}
    >
      <Icon icon={leftIcon} className="icon-button" />
      {children}
      <Icon icon={rightIcon} className="icon-button" />
    </div>
  );
};



export default DropdownItem;
