import { createSelector } from "reselect";

const selectOrder = (state) => state.order;

export const selectOrders = createSelector(
  [selectOrder],
  (order) => order && order.orders
);

export const selectOrderInOrder = createSelector(
  [selectOrder],
  (order) => order && order.order
);

export const selectOrderID = createSelector(
  [selectOrder],
  (order) => order && order.order && order.order.id
);

export const selectOrderItemID = createSelector(
  [selectOrder],
  (order) => order && order.orderItem && order.orderItem.id
);

export const selectIsOrdersGetLoading = createSelector(
  [selectOrder],
  order => order && order.isGetLoading
)

export const selectIsOrderRetrieveLoading = createSelector(
  [selectOrder],
  order => order && order.isRetrieveLoading
)

export const selectIsSetToShippingLoading = createSelector(
  [selectOrder],
  order => order && order.isSetToShippingLoading
)

export const selectIsSetToReviewLoading = createSelector(
  [selectOrder],
  order => order && order.isSetToReviewLoading
)
