import API from "store/api";
import { toast } from "react-toastify";

import {
  GET_WISHLIST_ITEMS_START,
  GET_WISHLIST_ITEMS_SUCCESS,
  GET_WISHLIST_ITEMS_FAILED,
  ADD_TO_WISHLIST_START,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAILED,
  REMOVE_TO_WISHLIST_START,
  REMOVE_TO_WISHLIST_SUCCESS,
  REMOVE_TO_WISHLIST_FAILED,
  GET_WISHLIST_START,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAILED,
  CLEAR_WISHLIST,
  CLEAR_WISHLIST_ITEMS,
} from "./actions.types";
import { tokenConfig } from "./auth.action";
import { setAuthModal } from "./modal.action";

export const getWishlist = (accountWishlist) => (dispatch, getState) => {
  dispatch({ type: GET_WISHLIST_START });

  API.get(`/products/wishlist/${accountWishlist}`, tokenConfig(getState))
    .then((res) => {
      console.log(res.data);
      dispatch({ type: GET_WISHLIST_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_WISHLIST_FAILED });
      console.log(err);
    });
};

export const getWishlistItems = (accountWishlist) => (dispatch, getState) => {
  dispatch({ type: GET_WISHLIST_ITEMS_START });

  API.get(
    `/products/wishlist-item/?wishlist=${accountWishlist}`,
    tokenConfig(getState)
  )
    .then((res) => {
      console.log(res.data);
      dispatch({ type: GET_WISHLIST_ITEMS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_WISHLIST_ITEMS_FAILED });
      console.log(err);
    });
};

export const addToWishlist = (product) => async (dispatch, getState) => {
  dispatch({ type: ADD_TO_WISHLIST_START });
  const body = JSON.stringify({
    product_input: product,
  });

  await API.post(`/products/wishlist-item/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_TO_WISHLIST_SUCCESS, payload: res.data });
      toast.success("Product Added To Wishlist", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ADD_TO_WISHLIST_FAILED });
      const errorMsg = "You need to register/login an account first!";

      toast.info(errorMsg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch(setAuthModal(true));
    });
};

export const removeToWishlist = (productID) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_TO_WISHLIST_START });

  // const body = JSON.stringify({
  //   product_id: productID,
  // });

  await API.delete(
    `/products/wishlist-delete?product_id=${productID}`,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: REMOVE_TO_WISHLIST_SUCCESS, payload: productID });
      toast.success("Product Removed To Wishlist", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(res.data);
    })
    .catch((err) => {
      dispatch({ type: REMOVE_TO_WISHLIST_FAILED });
    });
};

export const clearWishlist = () => (dispatch) => {
  dispatch({ type: CLEAR_WISHLIST });
};

export const clearWishlistItems = () => (dispatch) => {
  dispatch({ type: CLEAR_WISHLIST_ITEMS });
};
