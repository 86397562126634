import { createSelector } from "reselect";

const selectCart = (state) => state.cart;

export const selectCartInCart = createSelector(
  [selectCart],
  (cart) => cart && cart.cart
);

export const selectCartIsAddLoading = createSelector(
  [selectCart],
  (cart) => cart && cart.isAddLoading
);

export const selectCartIsLoading = createSelector(
  [selectCart],
  (cart) => cart && cart.isLoading
);

export const selectCartItems = createSelector(
  [selectCartInCart],
  (cart) => cart && cart.cart_items
);

export const selectCartItemsCount = createSelector(
  [selectCartInCart],
  (cart) =>
    cart &&
    cart.cart_items.reduce(
      (previousScore, currentScore) => previousScore + currentScore.quantity,
      0
    )
);
