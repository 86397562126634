// types
import {
  SET_ADD_ADDRESS_MODAL,
  SET_ADD_PAYMENT_CARD_MODAL,
  SET_ADD_PHONE_NUMBER_MODAL,
  SET_AUTH_MODAL,
  SET_CATEGORY_MODAL,
  SET_CATEGORY_UPDATE_FORM,
  SET_PRODUCT_UPDATE_FORM,
  SET_PRODUCT_MODAL,
  SET_QUICK_VIEW_MODAL,
  SET_UPDATE_CATEGORY_MODAL,
  SET_UPDATE_PRODUCT_MODAL,
  SET_UPDATE_ACCOUNT_ADDRESS_MODAL,
  SET_ACCOUNT_ADDRESS_UPDATE_FORM,
  SET_FAQ_MODAL,
  SET_UPDATE_FAQ_MODAL,
  SET_FAQ_UPDATE_FORM,
  SET_TESTIMONIAL_MODAL,
  SET_UPDATE_TESTIMONIAL_MODAL,
  SET_TESTIMONIAL_UPDATE_FORM,
  VIEW_INQUIRY_MODAL,
  VIEW_ORDER_DETAILS_MODAL,
  SET_CREATE_REVIEW_MODAL,
  SET_CREATE_NEW_HEADER_MODAL,
  SET_UPDATE_HEADER_HERO_MODAL,
  SET_HEADER_HERO_UPDATE_FORM,
} from "store/actions/actions.types";
import { retrieveAddress } from "./address.action";
import { retrieveCategory } from "./category.action";
import { retrieveFAQ } from "./faqs.action";
import { retrieveHeaderHero } from "./header-hero.action";
import { retrieveInquiry } from "./inquiries.action";
import { retrieveOrder, retrieveOrderItem } from "./order.action";
import { retrieveProduct } from "./product.action";
import { setSidebarClient } from "./sidebar.actions";
import { retrieveTestimonial } from "./testimonial.action";

export const setAuthModal = () => (dispatch) => {
  dispatch({
    type: SET_AUTH_MODAL,
  });
  dispatch(setSidebarClient(false));
};

export const setQuickViewModal = (slug) => (dispatch) => {
  if (slug) {
    dispatch(retrieveProduct(slug));
  }
  dispatch({
    type: SET_QUICK_VIEW_MODAL,
  });
};

export const setAddAddressModal = () => (dispatch) => {
  dispatch({
    type: SET_ADD_ADDRESS_MODAL,
  });
};

export const setAddPhoneNumberModal = () => (dispatch) => {
  dispatch({
    type: SET_ADD_PHONE_NUMBER_MODAL,
  });
};

export const setAddPaymentCardModal = () => (dispatch) => {
  dispatch({
    type: SET_ADD_PAYMENT_CARD_MODAL,
  });
};

export const setCategoryModal = () => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_MODAL,
  });
};

export const setUpdateCategoryModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveCategory(id));
  }
  dispatch({
    type: SET_UPDATE_CATEGORY_MODAL,
  });
  dispatch({
    type: SET_CATEGORY_UPDATE_FORM,
  });
};

export const setUpdateProductModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveProduct(id));
  }

  dispatch({
    type: SET_UPDATE_PRODUCT_MODAL,
  });
  dispatch({
    type: SET_PRODUCT_UPDATE_FORM,
  });
};

export const setUpdateAccountAddressModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveAddress(id));
  }
  dispatch({
    type: SET_UPDATE_ACCOUNT_ADDRESS_MODAL,
  });
  dispatch({
    type: SET_ACCOUNT_ADDRESS_UPDATE_FORM,
  });
};

export const setProductModal = () => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_MODAL,
  });
};

export const setAddFAQModal = () => (dispatch) => {
  dispatch({
    type: SET_FAQ_MODAL,
  });
};

export const setUpdateFAQModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveFAQ(id));
  }
  dispatch({
    type: SET_UPDATE_FAQ_MODAL,
  });
  dispatch({
    type: SET_FAQ_UPDATE_FORM,
  });
};

export const setAddTestimonialModal = () => (dispatch) => {
  dispatch({
    type: SET_TESTIMONIAL_MODAL,
  });
};

export const setUpdateTestimonialModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveTestimonial(id));
  }
  dispatch({
    type: SET_TESTIMONIAL_UPDATE_FORM,
  });
  dispatch({
    type: SET_UPDATE_TESTIMONIAL_MODAL,
  });
};

export const viewInquiryModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveInquiry(id));
  }
  dispatch({
    type: VIEW_INQUIRY_MODAL,
  });
};

export const viewOrderDetailsModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveOrder(id));
  }
  dispatch({
    type: VIEW_ORDER_DETAILS_MODAL,
  });
};

export const setCreateReviewModal = (productSlug, orderID, orderItemID) => (
  dispatch
) => {
  if (productSlug) {
    dispatch(retrieveProduct(productSlug));
    dispatch(retrieveOrder(orderID));
    dispatch(retrieveOrderItem(orderItemID));
  }
  dispatch({
    type: SET_CREATE_REVIEW_MODAL,
  });
};

export const setCreateNewHeaderHeroModal = () => (dispatch) => {
  dispatch({
    type: SET_CREATE_NEW_HEADER_MODAL,
  });
};

export const setUpdateHeaderHeroModal = (id) => (dispatch) => {
  if (id) {
    dispatch(retrieveHeaderHero(id));
  }
  dispatch({
    type: SET_HEADER_HERO_UPDATE_FORM,
  });
  dispatch({
    type: SET_UPDATE_HEADER_HERO_MODAL,
  });
};
