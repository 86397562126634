import React from "react";
import { v4 as uuidv4 } from "uuid";

// context
// import { ThemeContext } from "context/ThemeContext";

const FloatingFormInput = ({
  handleChange,
  formType,
  label,
  inputRef,
  ...props
}) => {
  // const { theme } = React.useContext(ThemeContext);
  const floatingInputID = uuidv4();
  return (
    <div className="relative mt-6 w-full">
      <input
        placeholder=" "
        // id={`${formType}-${label}`}
        id={floatingInputID}
        className={`floating block w-full appearance-none focus:outline-none bg-transparent border-2 rounded p-2 focus-within:border-default-primary focus:border-default-primary
        ${props.disabled ? "cursor-not-allowed bg-blue-100" : ""}
        `}
        onChange={handleChange}
        ref={inputRef}
        {...props}
      />
      <label
        // htmlFor={`${formType}-${label}`}
        htmlFor={floatingInputID}
        className="absolute top-0 left-0 mt-2 ml-2 text-gray-400 bg-gray-1100 font-medium duration-300 origin-0 -z-1"
      >
        {label}
      </label>
      {/* <div className="text-red-600">{children}</div> */}
    </div>
  );
};

export const CustomInputField = ({ label, inputRef, isError, ...props }) => {
  // const { theme } = React.useContext(ThemeContext);
  const floatingInputID = uuidv4();
  return (
    <div className="relative mt-6 w-full">
      <input
        placeholder=" "
        id={floatingInputID}
        className={`floating block w-full appearance-none focus:outline-none bg-transparent border-2 rounded p-2 focus-within:border-default-primary focus:border-default-primary
        ${props.disabled ? "cursor-not-allowed bg-blue-100" : ""}
        ${isError ? "border-red-400" : ""}
        `}
        ref={inputRef}
        {...props}
      />
      <label
        htmlFor={floatingInputID}
        className={`absolute top-0 left-0 mt-2 ml-2 ${
          isError ? "label-error" : ""
        } text-gray-400 bg-gray-1100 font-medium duration-300 origin-0 -z-1`}
      >
        {label}
      </label>
    </div>
  );
};

export const ErrorMessageInField = ({ children }) => {
  return <div className="text-red-400 text-sm my-2">{children}</div>;
};

export default FloatingFormInput;
