import { createSelector } from 'reselect';

const selectInquiry = state => state.inquiry;

export const selectInquiryInInquiry = createSelector(
  [selectInquiry],
  inquiry => inquiry.inquiry
);

export const selectInquiries = createSelector(
  [selectInquiry],
  inquiry => inquiry.inquiries
);

export const selectIsInquiryRetrieveLoading = createSelector(
  [selectInquiry],
  inquiry => inquiry.isRetrieveLoading
)

export const selectIsInquiryGetLoading = createSelector(
  [selectInquiry],
  inquiry => inquiry.isGetLoading
)

export const selectIsInquiryFormLoading = createSelector(
  [selectInquiry],
  inquiry => inquiry.isFormLoading
)
