import {
  ADD_CATEGORY_FAILED,
  ADD_CATEGORY_START,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILED,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_SUCCESS,
  RETRIEVE_CATEGORY_START,
  RETRIEVE_CATEGORY_FAILED,
  RETRIEVE_CATEGORY_SUCCESS,
  SET_CATEGORY_UPDATE_FORM,
} from "store/actions/actions.types";

const initialState = {
  categories: [],
  category: null,
  isFormLoading: false,
  isDeleteLoading: false,
  isGetLoading: false,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
};

export default function categories(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CATEGORY_UPDATE_FORM:
      return {
        ...state,
        isUpdate: true
      }
    case ADD_CATEGORY_START:
      return {
        ...state,
        isFormLoading: true,
        isSubmitSuccess: false,
      };
    case RETRIEVE_CATEGORY_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case GET_CATEGORIES_START:
      return {
        ...state,
        isGetLoading: true,
      };
    case DELETE_CATEGORY_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case UPDATE_CATEGORY_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case ADD_CATEGORY_FAILED:
    case GET_CATEGORIES_FAILED:
    case DELETE_CATEGORY_FAILED:
    case UPDATE_CATEGORY_FAILED:
    case RETRIEVE_CATEGORY_FAILED:
      return {
        ...state,
        isFormLoading: false,
        isGetLoading: false,
        isDeleteLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
        isSubmitSuccess: false,
        isRetrieveLoading: false,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, payload],
        isFormLoading: false,
        isSubmitSuccess: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: [...payload],
        isGetLoading: false,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category.id !== payload
        ),
        isDeleteLoading: false,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === payload.id ? { ...payload } : category
        ),
        isUpdate: false,
        isUpdateLoading: false
      };
    case RETRIEVE_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: payload,
        isRetrieveLoading: false
      };
    }
    default:
      return state;
  }
}
