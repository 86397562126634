import { GET_INSTAGRAM_FEED_FAILED, GET_INSTAGRAM_FEED_START, GET_INSTAGRAM_FEED_SUCCESS } from "store/actions/actions.types";

const initialState = {
  feed: null,
  isGetLoading: false,
}


export default function instagram(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case GET_INSTAGRAM_FEED_START:
      return {
        ...state,
        isGetLoading: true,
      }
    case GET_INSTAGRAM_FEED_FAILED:
      return {
        ...state,
        isGetLoading: false,
      }
    case GET_INSTAGRAM_FEED_SUCCESS:
      return {
        ...state,
        feed: payload,
        isGetLoading: false,
      }
    default:
      return state;
  }
}