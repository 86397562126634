// api
import API from "store/api";
import { toast } from "react-toastify";
import {
  LOAD_ACCOUNT_FAILED,
  LOAD_ACCOUNT_START,
  LOAD_ACCOUNT_SUCCESS,
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCESSFUL,
  REGISTRATION_FAILED,
  REGISTRATION_SUCCESSFUL,
  LOGOUT_ACCOUNT,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
} from "./actions.types";
import { setAuthModal } from "./modal.action";
import { clearCart, clearCartItems } from "./cart.action";
import { clearWishlist, clearWishlistItems } from "./wishlist.action";
import { returnMessages } from "./alerts.action";

// register account
export const registerUser = ({
  email,
  username,
  first_name,
  last_name,
  password,
  created_at
}) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let body = {};
  if(created_at){
    body = JSON.stringify({
      email,
      first_name,
      last_name,
      username,
      password,
    });

  } else {
  body = JSON.stringify({
    email,
    first_name,
    last_name,
    username,
    password,
    created_at
  });
}
  
  console.log(body);
  console.log(first_name);

  API.post("/accounts/register/", body, config)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: REGISTRATION_SUCCESSFUL,
        payload: res.data,
      });
    })
    .then((res) => {
      dispatch(setAuthModal(false));
    })
    .catch((err) => {
      dispatch(returnMessages(err.response.data, err.response.status))
      dispatch({
        type: REGISTRATION_FAILED,
      });
    });
};

export const login = (email, password) => (dispatch) => {
  dispatch({ type: LOGIN_START });

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ email, password });

  API.post("/accounts/login/", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESSFUL,
        payload: res.data,
      });
    })
    .then((res) => {
      dispatch(setAuthModal(false));
    })
    .catch((err) => {
      dispatch(returnMessages(err.response.data, err.response.status))
      dispatch({
        type: LOGIN_FAILED,
      });
    });
};

export const loadAccount = () => (dispatch, getState) => {
  dispatch({ type: LOAD_ACCOUNT_START });

  API.get("/accounts/account", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOAD_ACCOUNT_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: LOAD_ACCOUNT_FAILED,
      });
    });
};

export const tokenConfig = (getState) => {
  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "",
    },
  };

  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const logoutAccount = () => (dispatch) => {
  dispatch({
    type: LOGOUT_ACCOUNT,
  });
  dispatch(clearCart());
  dispatch(clearCartItems());
  dispatch(clearWishlist());
  dispatch(clearWishlistItems());
};

export const updateProfile = (id, first_name, last_name, password) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_PROFILE_START });

  let profileData = new FormData();
  profileData.set("first_name", first_name);
  profileData.set("last_name", last_name);
  profileData.set("password", password);

  await API.patch(`accounts/${id}/`, profileData, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: res.data });
    })
    .then((res) => {
      toast.success("Updating Profile Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_PROFILE_FAILED });
    });
};
