import { RETRIEVE_POLICY_FAILED, RETRIEVE_POLICY_START, RETRIEVE_POLICY_SUCCESS,  UPDATE_COOKIE_POLICY_FAILED,  UPDATE_COOKIE_POLICY_START,  UPDATE_COOKIE_POLICY_SUCCESS,  UPDATE_PRIVACY_POLICY_FAILED,  UPDATE_PRIVACY_POLICY_START, UPDATE_PRIVACY_POLICY_SUCCESS, UPDATE_TERMS_FAILED, UPDATE_TERMS_START, UPDATE_TERMS_SUCCESS } from "store/actions/actions.types";

const initialState = {
  policy: null,
  isRetrieveLoading: false,
  isUpdatePrivacyLoading: false,
  isUpdateTermsLoading: false,
  isUpdateCookieLoading: false,
}


export default function policy(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    case RETRIEVE_POLICY_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    case UPDATE_PRIVACY_POLICY_START:
      return {
        ...state,
        isUpdatePrivacyLoading: true,
      };
    case UPDATE_COOKIE_POLICY_START:
      return {
        ...state,
        isUpdateCookieLoading: true,
      };
    case UPDATE_TERMS_START:
      return {
        ...state,
        isUpdateTermsLoading: true,
      };
    case RETRIEVE_POLICY_FAILED:
    case UPDATE_PRIVACY_POLICY_FAILED:
    case UPDATE_TERMS_FAILED:
    case UPDATE_COOKIE_POLICY_FAILED:
      return {
        ...state,
        isRetrieveLoading: false,
        isUpdatePrivacyLoading: false,
        isFourUpdateLoading: false,
        isUpdateTermsLoading: false,
      }
    case RETRIEVE_POLICY_SUCCESS: {
      return {
        ...state,
        policy: payload,
        isRetrieveLoading: false
      };
    }
    case UPDATE_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        policy: payload,
        isUpdatePrivacyLoading: false
      };
    case UPDATE_TERMS_SUCCESS:
      return {
        ...state,
        policy: payload,
        isUpdateTermsLoading: false
      };
    case UPDATE_COOKIE_POLICY_SUCCESS:
      return {
        ...state,
        policy: payload,
        isUpdateCookieLoading: false
      };
    default:
      return state;
  }
}