import React from "react";

// redux
import { connect } from "react-redux";
import { DropdownLoader } from "../spinner/Spinner";
import DropdownItem from "components/common/navbar/DropdownItem";

// reselect
import { createStructuredSelector } from "reselect";
import {
  selectCategories,
  selectIsCategoryGetLoading,
} from "store/selectors/categories.selector";

function NavbarMenuDropdown({ categories, isGetLoading }) {
  return (
    <div
      style={{ top: "1.5rem", left: "-1rem" }}
      className="shop-dropdown absolute w-64 bg-white shadow-new p-2 rounded-md"
    >
      {isGetLoading ? (
        <DropdownLoader />
      ) : (
        <>
          <DropdownItem linkPath="/shop">All</DropdownItem>
          {categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((item) => {
              if (
                item.name.toLowerCase().includes("hot") ||
                item.name.toLowerCase().includes("new")
              ) {
                return null;
              } else {
                return item;
              }
            })
            .map((category) => {
              const { id, name, slug } = category;
              return (
                <DropdownItem linkPath={`/shop/category/${slug}`} key={id}>
                  {name}
                </DropdownItem>
              );
            })}
        </>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  categories: selectCategories,
  isGetLoading: selectIsCategoryGetLoading,
});

export default connect(mapStateToProps)(NavbarMenuDropdown);
