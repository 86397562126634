import {
  ADD_PRODUCT_TAG_FAILED,
  ADD_PRODUCT_TAG_START,
  ADD_PRODUCT_TAG_SUCCESS,
  DELETE_PRODUCT_TAG_FAILED,
  DELETE_PRODUCT_TAG_START,
  DELETE_PRODUCT_TAG_SUCCESS,
  GET_PRODUCT_TAGS_FAILED,
  GET_PRODUCT_TAGS_START,
  GET_PRODUCT_TAGS_SUCCESS,
} from "store/actions/actions.types";

const initialState = {
  product_tags: [],
  product_tag: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false
};

export default function product_tag(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCT_TAGS_START:
        return {
          ...state,
          isGetLoading: true,
        };
    case ADD_PRODUCT_TAG_START:
      return {
        ...state,
        isFormLoading: true,
      };
    case DELETE_PRODUCT_TAG_START:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case ADD_PRODUCT_TAG_FAILED:
    case GET_PRODUCT_TAGS_FAILED:
    case DELETE_PRODUCT_TAG_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isFormLoading: false,
        isDeleteLoading: false,
      };
    case ADD_PRODUCT_TAG_SUCCESS:
      return {
        ...state,
        product_tags: [...state.product_tags, payload],
        isFormLoading: false,
      };
    case GET_PRODUCT_TAGS_SUCCESS:
      return {
        ...state,
        product_tags: [...payload],
        isGetLoading: false,
      };
    case DELETE_PRODUCT_TAG_SUCCESS:
      return {
        ...state,
        product_tags: state.product_tags.filter(
          (tag) => tag.id !== payload
        ),
        isDeleteLoading: false,
      };
    default:
      return state;
  }
}
