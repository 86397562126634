import { createSelector } from 'reselect';

const selectAuth = state => state.auth;

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  auth => auth && auth.isAuthenticated
);

export const selectAccount = createSelector(
  [selectAuth],
  auth => auth && auth.account
)

export const selectAccountID = createSelector(
  [selectAuth],
  auth => auth && auth.account.id
)


export const selectAuthLoading = createSelector(
  [selectAuth],
  auth => auth && auth.isLoading
)

export const selectAccountType = createSelector(
  [selectAccount],
  account => account && account.account_type
)