import { RETRIEVE_CONTACT_FAILED, RETRIEVE_CONTACT_START, RETRIEVE_CONTACT_SUCCESS, UPDATE_CONTACT_FAILED, UPDATE_CONTACT_START, UPDATE_CONTACT_SUCCESS } from "store/actions/actions.types";

const initialState = {
  contacts: [],
  contact: null,
  isGetLoading: false,
  isFormLoading: false,
  isDeleteLoading: false,
  isRetrieveLoading: false,
  isUpdateLoading: false,
  isUpdate: false,
  isSubmitSuccess: false,
}


export default function contact(state=initialState, action) {
  const { type, payload } = action;

  switch(type) {
    // case SET_FAQ_UPDATE_FORM:
    //   return {
    //     ...state,
    //     isUpdate: true,
    //   }
    // case ADD_FAQ_START:
    //   return {
    //     ...state,
    //     isFormLoading: true,
    //     isSubmitSuccess: false,
    //   }
    // case GET_ABOUTS_START:
    //   return {
    //     ...state,
    //     isGetLoading: true,
    //   }
    // case DELETE_FAQ_START:
    //   return {
    //     ...state,
    //     isDeleteLoading: true,
    //   };
    case UPDATE_CONTACT_START:
      return {
        ...state,
        isUpdateLoading: true,
      };
    case RETRIEVE_CONTACT_START:
      return {
        ...state,
        isRetrieveLoading: true,
      };
    // case ADD_FAQ_FAILED:
    // case DELETE_FAQ_FAILED:
    // case GET_ABOUTS_FAILED:
    case RETRIEVE_CONTACT_FAILED:
    case UPDATE_CONTACT_FAILED:
      return {
        ...state,
        // isFormLoading: false,
        // isGetLoading: false,
        // isDeleteLoading: false,
        isRetrieveLoading: false,
        isUpdateLoading: false,
        isUpdate: false,
        // isSubmitSuccess: false,
      }
    // case ADD_FAQ_SUCCESS: 
    //   return {
    //     ...state,
    //     faqs: [payload, ...state.faqs],
    //     isFormLoading: false,
    //   }
    // case GET_ABOUTS_SUCCESS:
    //   return {
    //     ...state,
    //     abouts: [...payload],
    //     isGetLoading: false,
    //   }
    // case DELETE_FAQ_SUCCESS:
    //   return {
    //     ...state,
    //     faqs: state.faqs.filter(
    //       (faq) => faq.id !== payload
    //     ),
    //     isDeleteLoading: false,
    //   };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contact: payload,
        isUpdate: false,
        isUpdateLoading: false
      };
    case RETRIEVE_CONTACT_SUCCESS: {
      return {
        ...state,
        contact: payload,
        isRetrieveLoading: false
      };
    }
    default:
      return state;
  }
}