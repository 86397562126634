import API from "store/api";
import { toast } from "react-toastify";
import { tokenConfig } from "./auth.action";
import { ADD_HEADER_HERO_START, ADD_HEADER_HERO_SUCCESS, ADD_HEADER_HERO_FAILED, GET_HEADER_HEROES_START, GET_HEADER_HEROES_SUCCESS, GET_HEADER_HEROES_FAILED, DELETE_HEADER_HERO_START, DELETE_HEADER_HERO_SUCCESS, DELETE_HEADER_HERO_FAILED, RETRIEVE_HEADER_HERO_START, RETRIEVE_HEADER_HERO_SUCCESS, RETRIEVE_HEADER_HERO_FAILED, UPDATE_HEADER_HERO_START, UPDATE_HEADER_HERO_SUCCESS, UPDATE_HEADER_HERO_FAILED } from "./actions.types";
import { setCreateNewHeaderHeroModal, setUpdateHeaderHeroModal } from "./modal.action";

// add header hero
export const addHeaderHero = (heading, description, image, buttonName, buttonLink) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_HEADER_HERO_START });

  let headerHeroData = new FormData();
  headerHeroData.set("heading", heading);
  headerHeroData.set("description", description);
  headerHeroData.set("image", image);
  headerHeroData.set("button_name", buttonName);
  headerHeroData.set("button_link", buttonLink);


  API.post("/site/header-hero/", headerHeroData, tokenConfig(getState))
  .then((res) => {
    dispatch({ type: ADD_HEADER_HERO_SUCCESS, payload: res.data });
  })
  .then((res) => {
      toast.success("Header Hero Added. Thank you!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      dispatch(setCreateNewHeaderHeroModal(false));
    })
    .catch((err) => {
      dispatch({ type: ADD_HEADER_HERO_FAILED });
      toast.error("Sending Header Hero Failed. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(err.response.data);
    });
};

// get header heroes
export const getHeaderHeroes = () => async (dispatch, getState) => {
  dispatch({ type: GET_HEADER_HEROES_START });

  await API.get("/site/header-hero/", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_HEADER_HEROES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_HEADER_HEROES_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
}

// retrieve header hero
export const retrieveHeaderHero = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_HEADER_HERO_START });

  await API.get(`site/header-hero/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_HEADER_HERO_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: RETRIEVE_HEADER_HERO_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// delete header hero
export const deleteHeaderHero = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_HEADER_HERO_START });

  API.delete(`/site/header-hero/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_HEADER_HERO_SUCCESS, payload: id });
    })
    .then((res) => {
      toast.success("Deleting Header Hero Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_HEADER_HERO_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(err.response.data);
    });
};


export const updateHeaderHero = (heading, description, image, buttonName, buttonLink, id, imageChanged) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_HEADER_HERO_START });

  let headerHeroData = new FormData();
  headerHeroData.set("heading", heading);
  headerHeroData.set("description", description);
  if(imageChanged) {
    headerHeroData.set("image", image);
  }
  headerHeroData.set("button_name", buttonName);
  headerHeroData.set("button_link", buttonLink);

  
  await API.patch(
    `site/header-hero/${id}/`,
    headerHeroData,
    tokenConfig(getState)
  )
    .then((res) => {
      dispatch({ type: UPDATE_HEADER_HERO_SUCCESS, payload: res.data });
    })
    .then((res) => {
      dispatch(setUpdateHeaderHeroModal(false));
    })
    .then((res) => {
      toast.success("Updating Category Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: UPDATE_HEADER_HERO_FAILED });
      const errorMsg = "".concat(
        "Updating Header Hero Failed.\n",
        err.response.data.detail
      );
      toast.error(errorMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};
