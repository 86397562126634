// types
import { TAB_HISTORY_SUCCESS, TAB_PROCESSING_SUCCESS, TAB_REVIEW_SUCCESS, TAB_SHIPPING_SUCCESS } from "store/actions/actions.types";

const INITIAL_STATE = {
  isShippingTabActive: true,
  isProcessingTabActive: false,
  isReviewTabActive: false,
  isHistoryTabActive: false,
};

export default function tab(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TAB_SHIPPING_SUCCESS:
      return {
        ...state,
        isShippingTabActive: true,
        isProcessingTabActive: false,
        isReviewTabActive: false,
        isHistoryTabActive: false,
      };
    case TAB_PROCESSING_SUCCESS:
      return {
        ...state,
        isShippingTabActive: false,
        isProcessingTabActive: true,
        isReviewTabActive: false,
        isHistoryTabActive: false,
      };
    case TAB_REVIEW_SUCCESS:
      return {
        ...state,
        isShippingTabActive: false,
        isProcessingTabActive: false,
        isReviewTabActive: true,
        isHistoryTabActive: false,
      };
    case TAB_HISTORY_SUCCESS:
      return {
        ...state,
        isShippingTabActive: false,
        isProcessingTabActive: false,
        isReviewTabActive: false,
        isHistoryTabActive: true,
      };
    default:
      return state;
  }
};