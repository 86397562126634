import { createSelector } from 'reselect';

const selectHeaderHero = state => state.headerHero;

export const selectHeaderHeroIsFormLoading = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.isFormLoading
);

export const selectHeaderHeroInHeaderHero = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.headerHero
);

export const selectHeaderHeroIsUpdateLoading = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.isUpdateLoading
);

export const selectIsHeaderHeroUpdate = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.isUpdate
);

export const selectHeaderHeroes = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.headerHeroes
);

export const selectIsHeaderHeroGetLoading = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.isGetLoading
)

export const selectIsHeaderHeroRetrieveLoading = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.isRetrieveLoading
)


// export const selectIsCategoryFormLoading = createSelector(
//   [selectCategory],
//   categories => categories.isFormLoading
// )

// export const selectIsCategoryUpdateLoading = createSelector(
//   [selectCategory],
//   categories => categories.isUpdateLoading
// )


export const selectIsHeaderHeroSubmitSuccess = createSelector(
  [selectHeaderHero],
  headerHero => headerHero && headerHero.isSubmitSuccess
)
