import Button from "components/common/buttons/Button";
import React from "react";
import { connect } from "react-redux";
import { setCreateReviewModal } from "store/actions/modal.action";

const OrderStatus = ({
  status,
  setCreateReviewModal,
  productSlug,
  isRated,
  orderID,
  orderItemID,
  isAllRated,
}) => {
  const getStatusColor = (status) => {
    if (status === "processing") {
      return "text-blue-400";
    } else if (status === "shipping") {
      return "text-orange-400";
    } else if (status === "to_review") {
      return "text-pink-400";
    } else if (status === "delivered") {
      return "text-default-primary";
    }
  };
  const getStatus = (status) => {
    if (status === "processing") {
      return "Processing";
    } else if (status === "shipping") {
      return "Shipping";
    } else if (status === "to_review") {
      return "To Review";
    } else if (status === "delivered") {
      return "Delivered";
    }
  };

  return (
    <div className="flex flex-col sm:flex-row md:flex-col md:space-x-0 md:justify-start md:items-start lg:flex-row w-full sm:space-x-2 sm:justify-between sm:items-center">
      {status === "to_review" || status === "delivered" ? null : (
        <div className="mr-4">
          <div className="text-xs text-gray-400 capitalize -mb-1 font-medium">
            status
          </div>

          <div
            className={`text-base ${getStatusColor(
              status
            )} capitalize font-medium`}
          >
            {getStatus(status)}
          </div>
        </div>
      )}
      {isRated === false ? (
       <div className="mt-2">
          <Button
            color="bg-default-primary hover:bg-default-primaryShade text-white hover:text-white"
            inline
            outlineBorder
            handleClick={() =>
              setCreateReviewModal(productSlug, orderID, orderItemID)
            }
            outlineColor="border-green-400 hover:border-green-400"
          >
            <div className="font-light text-sm px-6">Rate</div>
          </Button>
       </div>
      ) : (
        <>
          {status && status === "delivered" ? null : (
            <div className="">
              <div className="text-xs text-gray-400 capitalize -mb-1 font-medium">
                {status === "to_review" || status === "delivered" ? "Date Received" : "delivery expected by" }
              </div>
              <div className="text-base text-gray-800 font-medium">January 21, 2021</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(null, { setCreateReviewModal })(OrderStatus);
