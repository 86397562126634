import React from "react";
import { Link } from "react-router-dom";

// context
// import { ThemeContext } from "context/ThemeContext";

export const SectionTitle = ({ textOrientation, title, subtitleLink, subtitleLinkPath }) => {  
  // const { theme } = React.useContext(ThemeContext);
  return (
    <div className="text-center">
      <div className="text-3xl font-medium my-3 text-center uppercase">
        {title}
      </div>
      {subtitleLink ? (
        <Link
          to={subtitleLinkPath}
          className={`font-medium text-xl text-default-primary hover:text-default-primaryShade duration-300 flex space-x-4 items-center justify-center`}
        >
          {subtitleLink}
          <svg
            className="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      ) : null}
    </div>
  );
};
