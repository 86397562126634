import React from "react";
import { connect } from "react-redux";
import { ModalLoader } from "components/common/spinner/Spinner";

// reselect
import { createStructuredSelector } from "reselect";
import { selectInquiryInInquiry, selectIsInquiryRetrieveLoading } from "store/selectors/inquiry.selector";

const ViewInquiry = ({ inquiry, isRetrieveLoading }) => {
  return isRetrieveLoading ? (
    <ModalLoader />
  ) : (
    <div>
      <div className=" text-gray-800">
        <span className="text-gray-900 font-medium">Name: </span>{" "}
        {inquiry ? inquiry.name : ""}
      </div>
      <div className=" text-gray-800">
        <span className="text-gray-900 font-medium">Email: </span>{" "}
        {inquiry ? inquiry.email : ""}
      </div>
      <div className=" text-gray-800">
        <span className="text-gray-900 font-medium">Date: </span>{" "}
        {inquiry ? inquiry.created_at : ""}
      </div>
      <hr className=""/>
      <div className="mt-4 text-gray-800">
        <span className="text-gray-900 font-medium">Message: </span>
        <p>{inquiry ? inquiry.message : ""}</p>
      </div>
      {/* reply to email button */}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  inquiry: selectInquiryInInquiry,
  isRetrieveLoading: selectIsInquiryRetrieveLoading,
});

export default connect(mapStateToProps, {})(ViewInquiry);
