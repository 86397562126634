export const countryOptions = [
  { id: "Austria", country: "Austria" },
  { id: "Belgium", country: "Belgium" },
  { id: "Bulgaria", country: "Bulgaria" },
  { id: "Croatia", country: "Croatia" },
  { id: "Cyprus", country: "Cyprus" },
  { id: "Czechia", country: "Czechia" },
  { id: "Denmark", country: "Denmark" },
  { id: "Estonia", country: "Estonia" },
  { id: "Finland", country: "Finland" },
  { id: "France", country: "France" },
  { id: "Germany", country: "Germany" },
  { id: "Greece", country: "Greece" },
  { id: "Hungary", country: "Hungary" },
  { id: "Ireland", country: "Ireland" },
  { id: "Italy", country: "Italy" },
  { id: "Latvia", country: "Latvia" },
  { id: "Lithuania", country: "Lithuania" },
  { id: "Luxembourg", country: "Luxembourg" },
  { id: "Malta", country: "Malta" },
  { id: "Netherlands", country: "Netherlands" },
  { id: "Poland", country: "Poland" },
  { id: "Portugal", country: "Portugal" },
  { id: "Romania", country: "Romania" },
  { id: "Slovenia", country: "Slovenia" },
  { id: "Spain", country: "Spain" },
  { id: "Sweden", country: "Sweden" },
  { id: "United Kingdom", country: "United Kingdom" },
];

export const emptyImage =
  "https://content.dropboxapi.com/apitl/1/AqZmYffo-4dWKzFEiA8Di52akQsYBt3CMyjuXovcCLyXfPAYSNEs9I69saI5Ghii5aqLvoe1RbuyzaGw6AlFV6CP2h7SbzkHTgSujI05qEQ8CIZV-uRVSa8S1DLtGpXYJ3CJVgkL7gq-p_OaO1f2v6byXTPDKYyBXfEhVW6sgw4R4fDceilLCqwIMdk346XC1P0gpMU51eg0qQYcNvZGTXBV2EZzC5hlzU_vfaPsVWMaSYe6gySfkRVZTNjTfzoKybGxgqv_ErbQb_HNc98M7NZfMKPCMX5ODcGtVF9WFtfdOfW4RP3XqYLFgcX5-c6NAJgGnNGZuVGaQeCU8caQlHPYCuQdACdyHWGeCUfGt61LWw";
