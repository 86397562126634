import React from "react";

// context
// import { ThemeContext } from "context/ThemeContext";

const Tags = ({ name }) => {
  // const { theme } = React.useContext(ThemeContext);
  return (
    <div
      className={`border border-default-primary rounded-md px-2 inline-block m-1 cursor-pointer hover:bg-default-primary hover:text-white transition duration-300 ease-in-out`}
    >
      {name}
    </div>
  );
};


export const TagAction = ({ name, onRemove }) => {
  return (
    <div className="border border-gray-400 inline-flex items-center rounded-md mr-2 mb-2">
      <div className="capitalize px-2 text-sm  py-1">{name}</div>
      <div
        className="border-l border-gray-400 text-gray-400 px-2 hover:text-red-400 duration-300 cursor-pointer  py-1"
        title="Remove"
        onClick={onRemove}
      >
        <svg
          className="w-4 h-4 "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  );
};

export default Tags;
