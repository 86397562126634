import React, { useState } from "react";

// icon
import { Icon } from "@iconify/react";
import bxsCloudUpload from "@iconify/icons-bx/bxs-cloud-upload";
import bxImageAdd from "@iconify/icons-bx/bx-image-add";
// import bxTrashAlt from "@iconify/icons-bx/bx-trash-alt";
// import product3 from "assets/landing/new-release/product3.jpg";

export const SmallImageField = ({
  onChangePicture,
  picture,
  pictureName,
  imgData,
  setImgData,
  image,
  fieldId,
  isFeatured,
  ...props
}) => {
  return (
    <div className="px-4 pt-7">
      <input
        id={fieldId}
        type="file"
        className="hidden"
        onChange={(e) => onChangePicture(e, pictureName)}
        {...props}
      />
      <label htmlFor={fieldId}>
        {image ? (
          <>
            <img
              alt="preview"
              className={`${isFeatured ? 'w-full h-64 mt-8' : 'w-16 h-12'} object-cover rounded-sm`}
              // src={picture ? picture : image}
              // src={picture ? product3 : null}
              src={image}
            />
            {console.log(picture ? "picture" : "image")}
            {console.log("featrue----", picture)}
          </>
        ) : (
          <>
            {isFeatured ? (
              <div className="mt-8 h-64 w-full border-2 border-dashed border-gray-400 cursor-pointer duration-300 hover:border-green-200 rounded-sm">
                <div className="font-medium text-gray-800 text-sm px-4 flex flex-col w-full h-full items-center justify-center text-center">
                  <Icon icon={bxImageAdd} className="w-12 h-12 text-gray-400" />
                  Click to select featured image
                </div>
              </div>
            ) : (
              <Icon
                icon={bxImageAdd}
                className="w-12 h-12 text-gray-400 hover:text-default-primary cursor-pointer duration-300"
              />
            )}
          </>
        )}
      </label>
    </div>
  );
};

const ImageField = ({ imgData, setImgData, imageID }) => {
  const [picture, setPicture] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPicture(reader.result);
      });

      reader.readAsDataURL(e.target.files[0]);

      setImgData({ ...imgData, image: e.target.files[0] });
    } else {
      setPicture(null);
    }
  };
  return (
    <div>
      <input
        id={imageID}
        type="file"
        className="hidden"
        onChange={onChangePicture}
      />
      <label htmlFor={imageID}>
        <div className="h-32 w-full border-2 border-dashed border-gray-400 cursor-pointer duration-300 hover:border-green-200 rounded-sm">
          <div className="font-medium text-gray-800 text-sm px-4 flex flex-col w-full h-full items-center justify-center text-center">
            <Icon icon={bxsCloudUpload} className="w-12 h-12 text-gray-400" />
            Click to select and replace your image
          </div>
        </div>
      </label>
      <div className="my-3">
        <img
          alt="preview"
          className="w-16 h-12 object-cover"
          src={picture ? picture : imgData.image}
        />
      </div>
    </div>
  );
};

export default ImageField;
