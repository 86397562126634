import React, { useEffect, useState } from "react";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";

// icons
import { Icon } from "@iconify/react";
// import bxlFacebook from "@iconify/icons-bx/bxl-facebook";
// import bxlGooglePlus from "@iconify/icons-bx/bxl-google-plus";
import eyeIcon from "@iconify/icons-fa-regular/eye";
import eyeSlash from "@iconify/icons-fa-regular/eye-slash";

// import FacebookLogin from "react-facebook-login";

// import GoogleLogin from "react-google-login";

// redux
import { connect } from "react-redux";
import { registerUser } from "store/actions/auth.action";
import ButtonSpinner from "components/common/spinner/Spinner";
import Error from "components/common/alerts/Error";

// context
// import { ThemeContext } from "context/ThemeContext";

// reselect
import { selectAuthLoading } from "store/selectors/auth.selector";
import { createStructuredSelector } from "reselect";
import { selectAlertInAlert } from "store/selectors/alerts.selector";
import { removeAlert } from "store/actions/alerts.action";

const RegisterForm = ({
  registerUser,
  setLogin,
  isLoading,
  errors,
  removeAlert,
}) => {
  // const { theme } = React.useContext(ThemeContext);
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);

  const { email, username, first_name, last_name, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [hasError, setHasError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    registerUser({
      email,
      first_name,
      last_name,
      username,
      password,
    });
    removeAlert();
  };
  useEffect(() => {
    if (errors) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [errors]);
  return (
    <>
      {hasError ? (
        <>
          {errors &&
            errors.map((error, i) => (
              <Error
                key={i}
                message={error.message}
                status={error.status}
                onClose={() => setHasError(false)}
              />
            ))}
        </>
      ) : null}
      <form onSubmit={handleSubmit} className="m-0 p-0">
        <FloatingFormInput
          label="Email"
          type="email"
          name="email"
          value={email}
          formType="customerLogin"
          handleChange={handleChange}
        />
        <FloatingFormInput
          label="Username"
          type="text"
          name="username"
          value={username}
          formType="customerLogin"
          handleChange={handleChange}
        />
        <FloatingFormInput
          label="First Name"
          type="text"
          name="first_name"
          value={first_name}
          formType="customerLogin"
          handleChange={handleChange}
        />
        <FloatingFormInput
          label="Last Name"
          type="text"
          name="last_name"
          value={last_name}
          formType="customerLogin"
          handleChange={handleChange}
        />
        <div className="relative mb-4">
          <FloatingFormInput
            label="Password"
            type={passwordShow ? "text" : "password"}
            name="password"
            value={password}
            formType="customerLogin"
            handleChange={handleChange}
          />
          <div
            onClick={() => setPasswordShow(!passwordShow)}
            className=" uppercase text-sm cursor-pointer absolute mt-3 mr-3 top-0 right-0"
          >
            <Icon
              icon={passwordShow ? eyeIcon : eyeSlash}
              className={`text-gray-400 hover:text-default-primary w-6 h-6`}
            />
          </div>
        </div>
        <Button
          type="submit"
          color={`bg-default-primary hover:bg-default-primaryShade`}
        >
          <div className="uppercase text-white font-medium flex items-center justify-center">
            Create account{" "}
            {isLoading && (
              <ButtonSpinner size="w-6 h-6 ml-1" color="text-white" />
            )}
          </div>{" "}
        </Button>
        <div className="text-gray-600 mt-4">
          Already have an account?{" "}
          <div
            className={`text-default-primary hover:text-default-primaryShade duration-300 inline-block cursor-pointer`}
            onClick={() => {
              setLogin(true);
            }}
          >
            Login here
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectAuthLoading,
  errors: selectAlertInAlert,
});

export default connect(mapStateToProps, { registerUser, removeAlert })(
  RegisterForm
);
