import React, { useState, useEffect } from "react";

// redux
import { connect } from "react-redux";
import {
  addTestomonial,
  updateTestimonial,
} from "store/actions/testimonial.action";

// components
import FloatingFormInput from "components/common/form-input/FloatingFormInput";
import Button from "components/common/buttons/Button";
import ButtonSpinner, { ModalLoader } from "components/common/spinner/Spinner";

// layout
import AdminFormContainer from "layouts/form-container/AdminFormContainer";

// editor
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

// text editor
import TextEditor from "components/common/text-editor/TextEditor";
import { createStructuredSelector } from "reselect";

// reselect
import { selectIsTestimonialFormLoading, selectIsTestimonialUpdateLoading, selectTestimonialInTestimonial, selectTestimonialIsUpdate, selectTestimonialIsSubmitSuccess, selectIsTestimonialRetrieveLoading } from 'store/selectors/testimonial.selector';

const TestimonialForm = ({
  title,
  isOnModal,
  isFormLoading,
  isUpdateLoading,
  isRetrieveLoading,
  isUpdate,
  isSubmitSuccess,
  testimonial,

  addTestomonial,
  updateTestimonial,
}) => {
  const [name, setName] = useState("");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (testimonial) {
      setName(testimonial ? testimonial.name : "");

      const blocksFromHTML = convertFromHTML(testimonial.message);

      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(() => EditorState.createWithContent(content));

      if (!isUpdate) {
        setName("")
      }
    }
  }, [testimonial, isUpdate, isSubmitSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isUpdate) {
      const editorValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      await updateTestimonial(name, editorValue, testimonial.id);
    } else {
      const editorValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      await addTestomonial(name, editorValue);
    }

    setName("");
    setEditorState(() => EditorState.createEmpty());
  };

  const form = (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="m-0 p-0">
        <FloatingFormInput
          label="Name"
          type="text"
          name="name"
          value={name}
          formType="AdminTestimonial"
          handleChange={(e) => setName(e.target.value)}
        />
        <TextEditor setEditorState={setEditorState} editorState={editorState}
        options={['inline', 'link', 'colorPicker', 'emoji', 'remove', 'history']}
        />
        <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
          <div className="uppercase text-white font-medium px-3 py-2 text-sm flex items-center">
            <span>
              {isUpdate ? "Update Testimonial" : "Create Testimonial"}
            </span>
            <span>
              {(isUpdateLoading || isFormLoading) && (
                <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
              )}
            </span>
          </div>{" "}
        </Button>
      </form>
    </>
  );
  return (
    <>
      {isOnModal ? (
        <> {isRetrieveLoading ? <ModalLoader /> : form} </>
      ) : (
        <AdminFormContainer title={title}>
          <div className="sticky top-1/5">{form}</div>
        </AdminFormContainer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isFormLoading: selectIsTestimonialFormLoading,
  isUpdateLoading: selectIsTestimonialUpdateLoading,
  testimonial: selectTestimonialInTestimonial,
  isUpdate: selectTestimonialIsUpdate,
  isSubmitSuccess: selectTestimonialIsSubmitSuccess,
  isRetrieveLoading: selectIsTestimonialRetrieveLoading,
});

export default connect(mapStateToProps, {
  addTestomonial,
  updateTestimonial,
})(TestimonialForm);
