import React from "react";

const Backdrop = ({ show, clicked }) =>
  show ? (
    <div
      className=" bg-black opacity-50 w-full h-full absolute top-0 left-0 z-40"
      onClick={clicked}
    ></div>
  ) : null;


export default Backdrop;
