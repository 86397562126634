import React from "react";

// icons
import bxsUserCircle from "@iconify/icons-bx/bxs-user-circle";

// route
import { useHistory, withRouter } from "react-router-dom";

// context
// import { ThemeContext } from "context/ThemeContext";
// redux
import { connect } from "react-redux";
import { logoutAccount } from "store/actions/auth.action";

// components
import NavItem from "./NavItem";
import DropdownItem from "components/common/navbar/DropdownItem";
import { DropdownItemTwo } from "./DropdownItem";

// reselect
import { createStructuredSelector } from "reselect";
import {
  selectAccount,
  selectIsAuthenticated,
} from "store/selectors/auth.selector";

const AdminNavbar = ({
  isAuthenticated,
  account,
  isSidebarClientVisible,
  setSidebarClient,
  children,
  logoutAccount,
}) => {
  // const { theme } = React.useContext(ThemeContext);
  const history = useHistory();

  const logoutClickedHandler = () => {
    history.push("/");
    logoutAccount();
  };

  return (
    <nav className="nav h-16 z-30 bg-white shadow-new sticky top-0 transition duration-300 flex items-center">
      {/* SECTION HIDDEN ON SM AND DOWN */}
      <div
        className={`hidden md:flex w-full mx-auto items-center justify-between 
        `}
      >
        <ul className="navbar-nav max-w-full h-full flex flex-1 items-center">
          {children}
        </ul>

        <NavItem
          name={
            isAuthenticated && account
              ? account.first_name || account.username
              : null
          }
          linkPath="#"
          dropdownName="profile-link"
          hasDropdown
          icon={bxsUserCircle}
        >
          <div style={{ zIndex: "9999" }}>
            <div
              style={{ top: "2rem", left: "-1rem" }}
              className="profile-dropdown absolute w-64 bg-white shadow-new p-2 rounded-md"
            >
              <DropdownItem linkPath="/dashboard">Account Info</DropdownItem>
              <DropdownItem linkPath="/profile">My Account</DropdownItem>
              {/* <DropdownItem linkPath="/orders">My Orders</DropdownItem> */}
              <DropdownItemTwo click={() => logoutClickedHandler()}>
                Logout
              </DropdownItemTwo>
            </div>
          </div>
        </NavItem>
      </div>

      {/* SECTION hidden on md and up */}
      <div className="md:hidden md:w-10/12 w-full md:mx-auto flex items-center justify-between px-3 ">
        <div onClick={() => setSidebarClient(!isSidebarClientVisible)}>
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </div>

        <div className="flex justify-between items-center h-16">
          <div className={`text-default-primary font-medium text-xl`}>
            Bubbles & Cream
          </div>
        </div>
      </div>
    </nav>
  );
};


const mapStateToProps = createStructuredSelector({
  account: selectAccount,
  isAuthenticated: selectIsAuthenticated,
});

export default connect(mapStateToProps, { logoutAccount })(
  withRouter(AdminNavbar)
);
