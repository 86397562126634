import React from "react";

export const ToggleRadioButton = ({
  label,
  keyId,
  handleToggleChange,
  ...props
}) => {
  return (
    <>
      <div className="flex items-center w-full">
        {/* <!-- Toggle Button --> */}
        <label htmlFor={keyId} className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              id={keyId}
              type="radio"
              onChange={handleToggleChange}
              className="hidden"
              {...props}
            />
            <div className="toggle__line w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
            <div className="toggle__dot absolute w-4 h-4 bg-white rounded-full shadow inset-y-0 left-0"></div>
          </div>
          <div className="ml-3 text-gray-700 font-medium text-xs">{label}</div>
        </label>
      </div>
    </>
  );
};

export const ToggleButton = ({
  label,
  keyId,
  handleToggleChange,
  toggleState,
  ...props
}) => {
  return (
    <div className="flex items-center">
      <div
        id={keyId}
        onClick={handleToggleChange}
        {...props}
        className={`toggle-button-switch ${toggleState ? "on" : "off"}`}
      ></div>
      <div className="ml-3 text-semiBase">{label}</div>
    </div>
  );
};


export default ToggleButton;
