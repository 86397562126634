import React, { useState, useEffect } from "react";

// components
import Button from "components/common/buttons/Button";
import ButtonSpinner from "components/common/spinner/Spinner";
import { ModalLoader } from "components/common/spinner/Spinner";
import DraggableImageUploadField from "components/common/form-input/DraggableImageUploadField";
import { CustomInputField } from "components/common/form-input/FloatingFormInput";
import { ErrorMessageInField } from "components/common/form-input/FloatingFormInput";

// layout
import AdminFormContainer from "layouts/form-container/AdminFormContainer";

// redux
import { connect } from "react-redux";
import {
  addProduct,
  updateProduct,
  setProductUpdateForm,
} from "store/actions/product.action";

// form
import { useForm } from "react-hook-form";

// text editor
import TextEditor from "components/common/text-editor/TextEditor";

// editor
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

// select
import Select from "react-select";
import makeAnimated from "react-select/animated";

// reselect
import {
  selectProductInProduct,
  selectProductUpdate,
  selectProductIsSubmitSuccess,
  selectIsProductFormLoading,
  selectIsProductUpdateLoading,
  selectIsProductRetrieveLoading,
} from "store/selectors/product.selector";
import { selectProductTags } from "store/selectors/product_tag.selector";
import { selectCategories } from "store/selectors/categories.selector";
import { createStructuredSelector } from "reselect";

const animatedComponents = makeAnimated();

const ProductForm = ({
  isOnModal,
  icon,
  title,
  product_tags,
  categories,
  addProduct,
  product,
  isUpdate,
  isSubmitSuccess,
  isUpdateLoading,
  isFormLoading,
  updateProduct,
  isRetrieveLoading,
}) => {
  const { register, handleSubmit, errors, setValue, reset } = useForm();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [formData, setFormData] = useState({
    category: null,
    images: [],
    product_tag: null,
  });

  const { category, product_tag, images, id } = formData;

  useEffect(() => {
    if (product) {
      setFormData({
        id: product.id,
        images: product.images,
        category: product.category,
        product_tag: product.product_tag,
      });
      setValue("name", product.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      const blocksFromHTML = convertFromHTML(product.description);

      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(() => EditorState.createWithContent(content));

      setValue("price", product.price, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("quantity", product.quantity, {
        shouldValidate: true,
        shouldDirty: true,
      });

      if (!isUpdate) {
        setFormData({
          images: [],
          category: null,
          product_tag: null,
        });
      }
    } else {
      setFormData({
        images: [],
        category: null,
        product_tag: null,
      });
      reset();
      setEditorState(() => EditorState.createEmpty());
    }
    // eslint-disable-next-line
  }, [product, isUpdate, isSubmitSuccess, setValue]);

  const onSubmit = async (data, e) => {
    if (isUpdate) {
      const editorValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      await updateProduct(
        data.name,
        editorValue,
        category,
        product_tag,
        data.price,
        data.quantity,
        images,
        id
      );
    } else {
      const editorValue = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      await addProduct(
        data.name,
        editorValue,
        category,
        product_tag,
        data.price,
        data.quantity,
        images
      );
    }

    setFormData({
      images: [],
      category: null,
      product_tag: null,
    });

    reset();

    setEditorState(() => EditorState.createEmpty());
    e.target.reset();
  };

  const selectStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: "#f7f7f7",
      color: "#e2e8f0",
      borderColor: "#e2e8f0",
      borderWidth: "2px",
      height: "2.8rem",
      boxShadow: isFocused ? "2px solid #68d391" : "2px solid #e2e8f0",
      "&:hover": {
        border: isFocused ? "2px solid #68d391" : "2px solid #e2e8f0",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#cbd5e0",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#c6f6d5" : "#f7f7f7",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: "#c6f6d5",
        },
      };
    },
  };

  // const addVariantClickHandler = () => {

  //   append({ name: "" });

  //   setIsVisible(true);
  // };

  const form = (
    <>
      {(isUpdateLoading || isFormLoading) && (
        <div className="my-2 text-sm text-red-400">
          Please wait while the product is being{" "}
          {isUpdateLoading ? "updated in" : "added to"} our database.
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="m-0 p-0 flex flex-col lg:flex-row w-full"
        encType="multipart/form-data"
      >
        <div className="w-full lg:w-5/12">
          <div className="flex">
            <div className="w-10/12">
              <DraggableImageUploadField
                image={images}
                formData={formData}
                setFormData={setFormData}
                isMulti={true}
                name="images"
              />
            </div>
            <div className="flex flex-col items-center justify-center space-y-2"></div>
          </div>
        </div>
        <div className="w-full lg:w-7/12">
          {/* STUB NAME */}
          <CustomInputField
            type="text"
            name="name"
            label="Name"
            inputRef={register({
              required: true,
              minLength: 3,
              maxLength: 100,
            })}
            aria-invalid={errors.name ? true : false}
            isError={errors.name}
          />
          <ErrorMessageInField>
            {errors.name && errors.name.type === "required" && (
              <p>Name is required.</p>
            )}
            {errors.name && errors.name.type === "minLength" && (
              <p>Name should be at-least 2 characters.</p>
            )}
            {errors.name && errors.name.type === "maxLength" && (
              <p>Name should be not more than 100 characters.</p>
            )}
          </ErrorMessageInField>

          {/* STUB DESCRIPTION */}
          <div className="mt-3 text-gray-500">Description</div>
          <TextEditor
            setEditorState={setEditorState}
            editorState={editorState}
            options={[
              "inline",
              "link",
              "colorPicker",
              "emoji",
              "remove",
              "history",
              "list",
            ]}
          />
          <div className="my-2 mb-5">
            <span className="text-xs text-gray-400 font-medium capitalize pl-2">
              Categories
            </span>
            <Select
              placeholder="Select Categories"
              options={categories}
              value={category}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              onChange={(value) =>
                setFormData({ ...formData, category: value })
              }
              styles={selectStyles}
              getOptionLabel={(category) => category.name}
              getOptionValue={(category) => category.id}
            />
          </div>
          <div className="my-2 mb-5">
            <span className="text-xs text-gray-400 font-medium capitalize pl-2">
              Product Tags
            </span>
            <Select
              placeholder="Select Product Tags"
              getOptionLabel={(tag) => tag.name}
              getOptionValue={(tag) => tag.id}
              options={product_tags}
              value={product_tag}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              onChange={(value) =>
                setFormData({ ...formData, product_tag: value })
              }
              styles={selectStyles}
            />
          </div>

          {/* STUB PRICE */}
          <CustomInputField
            type="text"
            name="price"
            label="Price"
            inputRef={register({
              required: true,
              min: 0,
            })}
            aria-invalid={errors.price ? true : false}
            isError={errors.price}
          />
          <ErrorMessageInField>
            {errors.price && errors.price.type === "required" && (
              <p>Price is required.</p>
            )}
          </ErrorMessageInField>
          {/* STUB QUANTITY */}
          <CustomInputField
            type="text"
            name="quantity"
            label="Quantity"
            inputRef={register({
              required: true,
              min: 0,
            })}
            aria-invalid={errors.quantity ? true : false}
            isError={errors.quantity}
          />
          <ErrorMessageInField>
            {errors.quantity && errors.quantity.type === "required" && (
              <p>Quantity is required.</p>
            )}
          </ErrorMessageInField>

          {/* STUB VARIANTS */}
          {/* <div className="my-4">
            <div className="flex items-center space-x-2 border-b py-2">
              <span className="font-medium text-gray-800">Variants</span>
              {isVisible ? null : (
                <div
                  onClick={() => addVariantClickHandler()}
                  className="bg-default-primary hover:bg-default-primaryShade duration-300 text-white px-3 cursor-pointer"
                >
                  Add
                </div>
              )}
            </div> */}
          {/* <div className="my-4">
              {isUpdate
                ? variants.map((variant) => {
                    const { id, name } = variant;

                    return (
                      <TagAction
                        key={id}
                        name={name}
                        onRemove={() => deleteProductVariant(id)}
                      />
                    );
                  })
                : null}
            </div> */}

          {/* {isVisible || variants ? null : (
              <div className="text-gray-700 my-4">None</div>
            )} */}

          {/* {fields.map((item, index) => {
              return (
                <VariantForm
                  key={item.id}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  index={index}
                  remove={remove}
                  register={register}
                />
              );
            })} */}

          {/* {isVisible && (
              <div className="flex space-x-2 my-4">
                <div
                  onClick={() => append({ name: "" })}
                  className={`bg-default-primary hover:bg-default-primaryShade duration-300 flex items-center justify-center px-1 text-white cursor-pointer`}
                >
                  Add another variant
                </div>

                <div>
                  <Button
                    handleClick={() => setIsVisible(false)}
                    type="button"
                    color="bg-gray-300 hover:bg-gray-400"
                    inline
                  >
                    <span className="px-1 capitalize text-white font-medium">
                      Cancel
                    </span>
                  </Button>
                </div>
              </div>
            )} */}
          {/* </div> */}

          <div className="my-3">
            <Button inline type="submit" color="bg-gray-800 hover:bg-gray-900">
              <div className="uppercase text-white font-medium px-3 py-2 text-sm flex items-center">
                <span>{isUpdate ? "Update Product" : "Create Product"}</span>
                <span>
                  {(isUpdateLoading || isFormLoading) && (
                    <ButtonSpinner size="w-5 h-5 ml-1" color="text-white" />
                  )}
                </span>
              </div>{" "}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
  return (
    <>
      {isOnModal ? (
        <> {isRetrieveLoading ? <ModalLoader /> : form} </>
      ) : (
        <AdminFormContainer icon={icon} title={title}>
          <div className="sticky top-1/5"></div>
          {form}
        </AdminFormContainer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  product_tags: selectProductTags,
  categories: selectCategories,
  product: selectProductInProduct,
  isUpdate: selectProductUpdate,
  isSubmitSuccess: selectProductIsSubmitSuccess,
  isFormLoading: selectIsProductFormLoading,
  isUpdateLoading: selectIsProductUpdateLoading,
  isRetrieveLoading: selectIsProductRetrieveLoading,
});

export default connect(mapStateToProps, {
  addProduct,
  updateProduct,
  setProductUpdateForm,
})(ProductForm);
