import { toast } from "react-toastify";
import API from "store/api";
import {
  ADD_PRODUCT_START,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT_START,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  RETRIEVE_PRODUCT_START,
  RETRIEVE_PRODUCT_SUCCESS,
  RETRIEVE_PRODUCT_FAILED,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  SET_PRODUCT_UPDATE_FORM,
  DELETE_PRODUCT_IMAGE,
  CLEAR_PRODUCTS,
  CLEAR_PRODUCT,
  GET_NEW_RELEASE_PRODUCTS_SUCCESS,
  GET_BEST_SELLER_PRODUCTS_SUCCESS,
  GET_TOP_RATED_PRODUCTS_SUCCESS,
  GET_RECOMMENDATION_PRODUCTS_SUCCESS,
  DELETE_PRODUCT_VARIANT_START,
  DELETE_PRODUCT_VARIANT_SUCCESS,
  DELETE_PRODUCT_VARIANT_FAILED,
} from "./actions.types";
import { tokenConfig } from "./auth.action";
import { setProductModal, setUpdateProductModal } from "./modal.action";

// import { tokenConfig } from "./auth.action";

export const getProducts = (
  category,
  page = 1,
  pageSize,
  filter,
  minPrice,
  maxPrice,
  sorting
) => async (dispatch, getState) => {
  if (page === 1) {
    dispatch(clearProducts());
  }

  dispatch({ type: GET_PRODUCTS_START });

  let url = `/products/products-list?page=${page}`;

  if (category) {
    url = `${url}&category=${category}`;
  }

  if (minPrice >= 0 && maxPrice) {
    url = `${url}&min_price=${minPrice}&max_price=${maxPrice}`;
  }
  // console.log(url);

  if (pageSize) {
    url = `${url}&page_size=${pageSize}`;
  }
  if (filter) {
    url = `${url}&filter=${filter}`;
  }
  console.log(sorting);
  if (sorting) {
    url = `${url}&sorting=${sorting}`;
  }

  console.log(url);
  let hasMore = false;
  let hasPriceFilter = false;
  let priceRange = null;

  await API.get(url, tokenConfig(getState))
    .then((res) => {
      const result = res.data;

      if (result.next) {
        hasMore = true;
      }

      if (minPrice >= 0 && maxPrice) {
        hasPriceFilter = true;
        priceRange = `£${minPrice} — £${maxPrice}`;
      }

      if (filter) {
        switch (filter) {
          case "new":
            dispatch({
              type: GET_NEW_RELEASE_PRODUCTS_SUCCESS,
              payload: result.results,
            });
            break;
          case "best_seller":
            dispatch({
              type: GET_BEST_SELLER_PRODUCTS_SUCCESS,
              payload: result.results,
            });
            break;
          case "top_rated":
            dispatch({
              type: GET_TOP_RATED_PRODUCTS_SUCCESS,
              payload: result.results,
            });
            break;
          case "recommended":
            dispatch({
              type: GET_RECOMMENDATION_PRODUCTS_SUCCESS,
              payload: result.results,
            });
            break;
          default:
            dispatch({
              type: GET_PRODUCTS_SUCCESS,
              payload: result.results,
              hasMore: hasMore,
              hasPriceFilter: hasPriceFilter,
              priceRange: priceRange,
            });
            break;
        }
      } else {
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: result.results,
          hasMore: hasMore,
          hasPriceFilter: hasPriceFilter,
          priceRange: priceRange,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_PRODUCTS_FAILED });
    });
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_PRODUCT_START });

  API.delete(`/products/products/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: id });
    })
    .then((res) => {
      toast.success("Deleting Product Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addProduct = (
  name,
  description,
  category,
  product_tag,
  price,
  quantity,
  images
) => async (dispatch, getState) => {
  dispatch({ type: ADD_PRODUCT_START });

  let productData = new FormData();
  productData.set("name", name);
  productData.set("description", description);

  for (let i in images) {
    productData.append(`image_${i}`, images[i], images[i].name);
  }

  // If many to many field, must use stringify first
  if (category) {
    productData.set(
      "categories",
      JSON.stringify(category.map((categ) => categ.id))
    );
  }
  if (product_tag) {
    productData.set(
      "product_tags",
      JSON.stringify(product_tag.map((tag) => tag.id))
    );
  }

  productData.set("price", price);
  productData.set("quantity", quantity);

  API.post("/products/products/", productData, tokenConfig(getState))
    .then((res) => {
      res.data.image = res.data.images.find((image) => image.is_featured);
      dispatch({ type: ADD_PRODUCT_SUCCESS, payload: res.data });
      dispatch(setProductModal(false));
    })
    .then((res) => {
      toast.success("Adding Product Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err);
    });

  dispatch(clearProduct());
};

export const retrieveProduct = (slug) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_PRODUCT_START });

  await API.get(`products/product/${slug}`)
    .then((res) => {
      dispatch({ type: RETRIEVE_PRODUCT_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: RETRIEVE_PRODUCT_FAILED });
    });
};

export const updateProduct = (
  name,
  description,
  category,
  product_tag,
  price,
  quantity,
  images,
  id
) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_PRODUCT_START });

  let productData = new FormData();
  productData.set("name", name);
  productData.set("description", description);

  const newImages = images.filter((image) =>
    Object.keys(image).includes("path")
  );

  for (let i in newImages) {
    productData.append(`image_${i}`, newImages[i], newImages[i].name);
  }

  productData.set(
    "categories",
    JSON.stringify(category.map((categ) => categ.id))
  );
  productData.set(
    "product_tags",
    JSON.stringify(product_tag.map((tag) => tag.id))
  );

  productData.set("price", price);
  productData.set("quantity", quantity);

  await API.patch(
    `products/products/${id}/`,
    productData,
    tokenConfig(getState)
  )
    .then((res) => {
      res.data.image = res.data.images.find((image) => image.is_featured);
      dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: res.data });
      dispatch(setUpdateProductModal(false));
    })
    .then((res) => {
      toast.success("Updating Product Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: UPDATE_PRODUCT_FAILED });
    });

  dispatch(clearProduct());
};

export const setProductUpdateForm = () => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_UPDATE_FORM,
  });
};

export const deleteProductImage = (id) => async (dispatch, getState) => {
  await API.delete(`/products/delete-image/${id}`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: DELETE_PRODUCT_IMAGE });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteProductVariant = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_PRODUCT_VARIANT_START });
  await API.delete(`/products/variants/${id}`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: DELETE_PRODUCT_VARIANT_SUCCESS, payload: id });
    })
    .catch((err) => {
      dispatch({ type: DELETE_PRODUCT_VARIANT_FAILED });
      console.log(err);
    });
};

export const clearProducts = (page) => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCTS });

  // return new Promise((resolve, reject) => {
  //   if (page === 1) {
  //     resolve();
  //   } else {
  //     reject("Error");
  //   }
  // });
};

export const clearProduct = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT });
};
