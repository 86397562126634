import axios from 'axios';


import { GET_INSTAGRAM_FEED_FAILED, GET_INSTAGRAM_FEED_START, GET_INSTAGRAM_FEED_SUCCESS } from "./actions.types";

// get inquiries
export const getInstgramFeed = () => async (dispatch) => {
  dispatch({ type: GET_INSTAGRAM_FEED_START });

  await axios.get("https://www.instagram.com/bubblesandcreamiom/?hl=en")
    .then((res) => {
      let feedData = []
      const instaObj = res.data
          .match(
            /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/
          )[1]
          .slice(0, -1)

      const convertedJSONObj = JSON.parse(instaObj)

      const mediaArr =
          convertedJSONObj.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges

      mediaArr.forEach(el => {
        const node = el.node
        feedData.push(node)
      })

      dispatch({ type: GET_INSTAGRAM_FEED_SUCCESS, payload: mediaArr });
    })

    .catch((err) => {
      dispatch({ type: GET_INSTAGRAM_FEED_FAILED });
    })
}
