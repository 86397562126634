import API from "store/api";
import { toast } from "react-toastify";
import { tokenConfig } from "./auth.action";
import { ADD_INQUIRY_FAILED, ADD_INQUIRY_START, ADD_INQUIRY_SUCCESS, GET_INQUIRIES_FAILED, GET_INQUIRIES_START, GET_INQUIRIES_SUCCESS, RETRIEVE_INQUIRY_FAILED, RETRIEVE_INQUIRY_START, RETRIEVE_INQUIRY_SUCCESS, DELETE_INQUIRY_START, DELETE_INQUIRY_SUCCESS, DELETE_INQUIRY_FAILED } from "./actions.types";

// add inquiry
export const addInquiry = (name, email, message) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_INQUIRY_START });

  let inquiryData = new FormData();
  inquiryData.set("name", name);
  inquiryData.set("email", email);
  inquiryData.set("message", message);


  API.post("/site/inquiries/", inquiryData, tokenConfig(getState))
  .then((res) => {
    dispatch({ type: ADD_INQUIRY_SUCCESS, payload: res.data });
  })
  .then((res) => {
      toast.success("Your inquiry has been sent. Thank you!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    })
    .catch((err) => {
      dispatch({ type: ADD_INQUIRY_FAILED });
      toast.error("Sending Inquiry Failed. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(err.response.data);
    });
};

// get inquiries
export const getInquiries = () => async (dispatch, getState) => {
  dispatch({ type: GET_INQUIRIES_START });

  await API.get("/site/inquiries", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_INQUIRIES_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: GET_INQUIRIES_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
}

// retrieve inquiry
export const retrieveInquiry = (id) => async (dispatch, getState) => {
  dispatch({ type: RETRIEVE_INQUIRY_START });

  await API.get(`site/inquiries/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RETRIEVE_INQUIRY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: RETRIEVE_INQUIRY_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// delete inquiry
export const deleteInquiry = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_INQUIRY_START });

  API.delete(`/site/inquiries/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_INQUIRY_SUCCESS, payload: id });
    })
    .then((res) => {
      toast.success("Deleting Inquiry Successful!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_INQUIRY_FAILED });
      toast.error(err.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(err.response.data);
    });
};
