
export const addToCart = (cart, cartItems, itemToAdd) => {

  const existingCartItem = cartItems.find(cartItem => cartItem.id === itemToAdd.id)
  
  if(existingCartItem) {
    
    const newCartItem = cartItems.map(item => 
      item.id === itemToAdd.id
      ? {...item, total_price: itemToAdd.total_price , quantity: itemToAdd.quantity}
      : item
      )


      const newTotalPrice = newCartItem.reduce((accumulated_price,item) => accumulated_price+parseFloat(item.total_price), 0) 
      

      console.log("newotal",newTotalPrice);

      return {...cart, total_price: newTotalPrice.toFixed(2), cart_items: newCartItem}

    } else {
      const newTotalPrice = cartItems.reduce((accumulated_price,item) => accumulated_price+parseFloat(item.total_price), 0)
      const completePrice = newTotalPrice + parseFloat(itemToAdd.total_price)
    return {...cart, total_price: completePrice.toFixed(2), cart_items: [...cartItems, itemToAdd]}
  }
}

export const deleteItemInCart = (cart, cart_items, IDOfItemToBeDeleted) => {
  const existingCartItem = cart_items.find(cartItem => cartItem.id === IDOfItemToBeDeleted)
  console.log("EXISTINGCARTITEM",existingCartItem);
  if(existingCartItem) {
    const priceOfItemToBeRemoved = cart_items.map((cartItem => cartItem.id === IDOfItemToBeDeleted ? cartItem.total_price : 0))
    const newTotalPrice = parseFloat(cart.total_price)-parseFloat(priceOfItemToBeRemoved)
    const newCartItem = cart_items.filter(item =>
      item.id !== IDOfItemToBeDeleted 
    )
    console.log("newTotalPrice", newTotalPrice);    
  
    return {...cart, total_price: newTotalPrice.toFixed(2), cart_items: newCartItem}
  }
}