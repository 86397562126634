import { combineReducers } from "redux";

// reducers
import formModal from "store/reducers/modal.reducer";
import tab from "store/reducers/tab.reducer";
import auth from "store/reducers/auth.reducer";
import categories from "store/reducers/category.reducer";
import product_tag from "store/reducers/product-tag.reducer";
import product from "store/reducers/product.reducer";
import account from "store/reducers/account.reducer";
import sidebar from "store/reducers/sidebar.reducer";
import alerts from "store/reducers/alerts.reducer";
import cart from "store/reducers/cart.reducer";
import address from "store/reducers/address.reducer";
import inquiry from "store/reducers/inquiries.reducer";
import faq from "store/reducers/faqs.reducer";
import about from "store/reducers/about.reducer";
import contact from "store/reducers/contact.reducer";
import testimonial from "store/reducers/testimonial.reducer";
import landing from "store/reducers/landing.reducer";
import headerHero from "store/reducers/header-hero.reducer";
import policy from "store/reducers/policy.reducer";
import instagram from "store/reducers/instagram.reducer";
import order from "store/reducers/order.reducer";
import review from "store/reducers/review.reducer";
import wishlist from "store/reducers/wishlist.reducer";

export default combineReducers({
  auth,
  formModal,
  tab,
  sidebar,
  alerts,
  account,
  landing,
  headerHero,
  faq,
  instagram,
  policy,
  testimonial,
  about,
  contact,
  categories,
  product_tag,
  product,
  address,
  cart,
  inquiry,
  order,
  review,
  wishlist,
});
