import { SET_ALERT, REMOVE_ALERT } from "store/actions/actions.types";

const initialState = {
  errors: [],
};

export default function alerts(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        errors: [...state.errors, payload],
      }
    case REMOVE_ALERT:
      return {
        ...state,
        errors: [],
      }
    default:
      return state;
  }
}
