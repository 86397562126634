import React from "react";
import { v4 as uuidv4 } from "uuid";

// context
// import { ThemeContext } from "context/ThemeContext";

const FloatingTextarea = ({ handleChange, formType, label, ...props }) => {
  // const { theme } = React.useContext(ThemeContext);
  const floatingTextareaID = uuidv4();
  return (
    <div className="relative my-6">
      <textarea
        placeholder=" "
        id={floatingTextareaID}
        className={`floating resize-y block w-full appearance-none focus:outline-none bg-transparent border-2 rounded p-2 focus-within:border-default-primary focus:border-default-primaryShade`}
        onChange={handleChange}
        {...props}
      ></textarea>
      <label
        htmlFor={floatingTextareaID}
        className="absolute top-0 left-0 mt-2 ml-2 text-gray-400 bg-gray-1100 font-medium duration-300 origin-0 -z-1"
      >
        {label}
      </label>
    </div>
  );
};

export const CustomTextareaField = ({ label, inputRef, isError, ...props }) => {
  // const { theme } = React.useContext(ThemeContext);
  const floatingInputID = uuidv4();
  return (
    <div className="relative mt-6 w-full">
      <textarea
        placeholder=" "
        id={floatingInputID}
        className={`floating block w-full appearance-none focus:outline-none bg-transparent border-2 rounded p-2 focus-within:border-default-primary focus:border-default-primary
        ${props.disabled ? "cursor-not-allowed bg-blue-100" : ""}
        ${isError ? "border-red-400" : ""}
        `}
        ref={inputRef}
        {...props}
      ></textarea>
      <label
        htmlFor={floatingInputID}
        className={`absolute top-0 left-0 mt-2 ml-2 ${isError ? 'label-error' : ''} text-gray-400 bg-gray-1100 font-medium duration-300 origin-0 -z-1`}
      >
        {label}
      </label>
    </div>
  );
};


export default FloatingTextarea;
