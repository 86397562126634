import React, { useState, useEffect } from "react";

// redux
import { connect } from "react-redux";
import {
  setAddAddressModal,
  setAuthModal,
  setQuickViewModal,
  setAddPaymentCardModal,
  setAddPhoneNumberModal,
  setUpdateAccountAddressModal,
  viewOrderDetailsModal,
  setCreateReviewModal,
} from "store/actions/modal.action";
import {
  setSidebarCart,
  setSidebarClient,
} from "store/actions/sidebar.actions";

// components
// import BigLogo from "components/BigLogo";

// components
// modals
import QuickView from "components/landing/product/QuickView";
import Modal from "components/common/modal/Modal";
// sidebars
import Sidebar from "components/common/sidebar/Sidebar";
import ClientSidebar from "components/common/sidebar/ClientSidebar";
import CartSidebar from "components/common/sidebar/CartSidebar";
// forms
import LoginForm from "components/forms/customer/LoginForm";
import RegisterForm from "components/forms/customer/RegisterForm";
import AddressForm from "components/forms/customer/AddressForm";
import PhoneNumberForm from "components/forms/customer/PhoneNumberForm";
import PaymentCardForm from "components/forms/customer/PaymentCardForm";
import ReviewForm from "components/forms/customer/ReviewForm";
import ViewOrders from "components/landing/order/ViewOrders";

// TODO Uncomment upon touching navbar two
import Navbar from "components/common/navbar/Navbar";
import NavItem from "components/common/navbar/NavItem";
import NavbarMenuDropdown from "components/common/navbar/NavbarMenuDropdown";

// toast
import { ToastContainer } from "react-toastify";
import { getProducts } from "store/actions/product.action";
import { getCategories } from "store/actions/category.action";
import Advertisement from "components/landing/sections/Advertisement";
import Footer from "components/landing/sections/Footer";

const DropdownBackdrop = ({ show, clicked }) =>
  show ? (
    <div
      className="w-full h-full absolute top-0 left-0"
      onClick={clicked}
    ></div>
  ) : null;

const CustomerLayout = ({
  isAddingAddressModalOpen,
  setAddAddressModal,
  isAddingPhoneNumberModalOpen,
  setAddPhoneNumberModal,
  isAddingPaymentCardModalOpen,
  setAddPaymentCardModal,
  setAuthModal,
  setQuickViewModal,
  setSidebarClient,
  setSidebarCart,
  isAuthModalVisible,
  isQuickViewModalVisible,
  isSidebarClientVisible,
  isSidebarCartVisible,
  setUpdateAccountAddressModal,
  isUpdateAccountAddressModalVisible,
  isViewOrderDetailsModalVisible,
  viewOrderDetailsModal,
  isCreateReviewModalVisible,
  setCreateReviewModal,
  children,
  bestSellers,
  newRelease,
  topRated,
  recommendations,
  getProducts,
  getCategories,
}) => {
  const [isLogin, setLogin] = useState(true);

  // TODO Uncomment upon touching navbar two
  const [navbarDropdownOpen, setNavbarDropdownOpen] = useState(false);

  useEffect(() => {
    if (
      newRelease.length === 0 &&
      bestSellers.length === 0 &&
      topRated.length === 0 &&
      recommendations.length === 0
    ) {
      getProducts(undefined, 1, 4, "new");
      getProducts(undefined, 1, 4, "best_seller");
      getProducts(undefined, 1, 4, "recommended");
      getProducts(undefined, 1, 4, "top_rated");
    }
    // eslint-disable-next-line
  }, [getProducts]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div className="relative">
      <ToastContainer />
      <DropdownBackdrop
        show={navbarDropdownOpen}
        clicked={() => setNavbarDropdownOpen()}
      />
      <div className="h-full w-full">
        {/* <div className="hidden md:block  md:w-10/12 lg:w-8/12 mx-auto">
          <BigLogo />
        </div> */}

        {/* STUB Navbar */}
        <Navbar
          isAuthModalVisible={isAuthModalVisible}
          setAuthModal={setAuthModal}
          isSidebarClientVisible={isSidebarClientVisible}
          setSidebarClient={() => setSidebarClient(!isSidebarClientVisible)}
          isSidebarCartVisible={isSidebarCartVisible}
          setSidebarCart={() => setSidebarCart(!isSidebarCartVisible)}
        >
          <NavItem name="Home" linkPath="/" />
          <NavItem
            name="Shop"
            linkPath="/shop"
            dropdownName="shop-link"
            hasDropdown
          >
            <div style={{ zIndex: "9999" }}>
              <NavbarMenuDropdown />
            </div>
          </NavItem>
          <NavItem name="Wishlist" linkPath="/wishlist" />
          <NavItem name="Our Story" linkPath="/about" />
          <NavItem name="Contact Us" linkPath="/contact" />
        </Navbar>

        {/* -z-1 */}
        <div className="children relative">
          {children}
          <Advertisement />
          <Footer />
        </div>
      </div>
      {/* SECTION SIDEBARS */}

      {/* STUB CLIENT SIDEBAR - the hamburger menu */}
      <Sidebar
        position="left"
        isVisible={isSidebarClientVisible}
        onClose={() => setSidebarClient(false)}
        visibilityStyles="md:hidden"
      >
        <ClientSidebar handleClick={() => setAuthModal(!isAuthModalVisible)} />
      </Sidebar>

      {/* STUB CART SIDEBAR */}
      <Sidebar
        position="right"
        widthStyles="sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-3/12 flex flex-col justify-between mt-0 "
        isVisible={isSidebarCartVisible}
        onClose={() => setSidebarCart(false)}
      >
        <CartSidebar onClose={() => setSidebarCart(false)} />
      </Sidebar>

      {/* SECTION MODALS */}

      {/* STUB AUTH MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isAuthModalVisible}
        title={
          isLogin
            ? "Login to your account"
            : "Get started with Bubbles and Cream"
        }
        onClose={() => setAuthModal(false)}
      >
        <div className="flex flex-col mt-3">
          {isLogin ? (
            <LoginForm isLogin={isLogin} setLogin={setLogin} />
          ) : (
            <RegisterForm isLogin={isLogin} setLogin={setLogin} />
          )}
        </div>
      </Modal>

      {/* STUB QUICK VIEW MODAL */}
      <Modal
        widthStyles="w-full sm:w-10/12 xl:w-8/12 hello"
        isVisible={isQuickViewModalVisible}
        title="Quick View"
        minHeight="min-h-semi"
        onClose={() => setQuickViewModal(false)}
      >
        <QuickView />
      </Modal>

      {/* STUB UPDATE ADDRESS MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isUpdateAccountAddressModalVisible}
        title="Update Address"
        onClose={() => setUpdateAccountAddressModal(false)}
      >
        <div className="flex flex-col mt-3">
          <AddressForm isOnModal />
        </div>
      </Modal>

      {/* STUB ADD ADDRESS MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isAddingAddressModalOpen}
        title="Add New Address"
        onClose={() => setAddAddressModal(false)}
      >
        <div className="flex flex-col mt-3">
          <AddressForm isOnModal />
        </div>
      </Modal>

      {/* STUB ADD PHONE NUMBER MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isAddingPhoneNumberModalOpen}
        title="Add New Phone Number"
        onClose={() => setAddPhoneNumberModal(false)}
      >
        <div className="flex flex-col mt-3">
          <PhoneNumberForm isOnModal />
        </div>
      </Modal>

      {/* STUB ADD PAYMENT MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isAddingPaymentCardModalOpen}
        title="Add Card"
        onClose={() => setAddPaymentCardModal(false)}
      >
        <div className="flex flex-col mt-3">
          <PaymentCardForm isOnModal />
        </div>
      </Modal>

      {/* STUB ORDER DETAILS MODAL */}
      <Modal
        widthStyles="w-full sm:max-w-lg"
        maxWidth="30%"
        isVisible={isViewOrderDetailsModalVisible}
        title="ORDER DETAILS"
        onClose={() => viewOrderDetailsModal(false)}
      >
        <div className="flex flex-col mt-3">
          <ViewOrders isOnModal />
        </div>
      </Modal>
      {/* STUB CREATE REVIEW MODAL */}
      <Modal
        widthStyles="w-full sm:w-7/12 xl:w-5/12"
        maxWidth="30%"
        isVisible={isCreateReviewModalVisible}
        title="ORDER REVIEW"
        onClose={() => setCreateReviewModal(false)}
      >
        <div className="flex flex-col mt-3">
          <ReviewForm isOnModal />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAddingAddressModalOpen: state.formModal.isAddingAddressModalOpen,
  isAddingPhoneNumberModalOpen: state.formModal.isAddingPhoneNumberModalOpen,
  isAddingPaymentCardModalOpen: state.formModal.isAddingPaymentCardModalOpen,

  isAuthModalVisible: state.formModal.isAuthModalVisible,
  isQuickViewModalVisible: state.formModal.isQuickViewModalVisible,

  isSidebarClientVisible: state.sidebar.isSidebarClientVisible,
  isSidebarCartVisible: state.sidebar.isSidebarCartVisible,

  isUpdateAccountAddressModalVisible:
    state.formModal.isUpdateAccountAddressModalVisible,

  isViewOrderDetailsModalVisible:
    state.formModal.isViewOrderDetailsModalVisible,

  isCreateReviewModalVisible: state.formModal.isCreateReviewModalVisible,

  newRelease: state.product.newRelease,
  bestSellers: state.product.bestSellers,
  topRated: state.product.topRated,
  recommendations: state.product.recommendations,
});

export default connect(mapStateToProps, {
  setAddPhoneNumberModal,
  setAddPaymentCardModal,
  setAddAddressModal,
  setUpdateAccountAddressModal,

  setAuthModal,
  setQuickViewModal,

  setSidebarClient,
  setSidebarCart,

  viewOrderDetailsModal,

  setCreateReviewModal,

  getProducts,
  getCategories,
})(CustomerLayout);
